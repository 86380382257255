import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionTypesEnum } from "./types.enum";
import { getFraudsByCompanyId, registerFraudHelper, updateFraudHelper } from "../../helpers/backend_helper";
import { addFraud, changeThreatsList, updateFraudFromSaga } from "./actions";
import { IThreats } from "../../interfaces/threats.interface";

function* getFraudsByCompanyByIdSaga({ payload }: any) {
  try {
    const frauds: IThreats[] = yield call(getFraudsByCompanyId, { companyId: payload })
    yield put(changeThreatsList(frauds))

  } catch {
    yield put(changeThreatsList([]));
  }
}

function* registerFraudSaga({ payload }: any) {
  try {
    const formData = new FormData()

    for (const [key, value] of Object.entries(payload)) {
      if (key === 'evidence') {
        for (let file of value as File[]) {
          if (file instanceof File) {
            formData.append('evidence', file);
          }
        }
      } else formData.append(key, value as any);
    }

    const newFraud: IThreats = yield call(registerFraudHelper, formData);
    yield put(addFraud(newFraud));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* updateFraudSaga({ payload }: any) {

  try {
    const updatedFraud: IThreats = yield call(updateFraudHelper, { currentState: payload.currentState, isActive: payload.isActive, _id: payload._id });
    yield put(updateFraudFromSaga(updatedFraud));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

export function* watchAll() {
  yield takeLatest(ActionTypesEnum.GET_FRAUDS_BY_COMPANY, getFraudsByCompanyByIdSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_FRAUD, registerFraudSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_FRAUD, updateFraudSaga);
}

function* threatSaga() {
  yield all([
    call(watchAll)
  ]);
}
export default threatSaga 