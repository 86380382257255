import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "reactstrap";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { useTranslation } from "react-i18next";

const AnalyticalReportFoundAgressor = ({ foundAggressors }) => {
  const { t } = useTranslation();

  const chartColors = getChartColorsArray('["--vz-secondary", "--vz-danger"]');

  const series = [
    {
      name: "Novos",
      type: "column",
      data: foundAggressors.newDomainsCount,
    },
    {
      name: "Total",
      type: "line",
      data: foundAggressors.uniqueDomainsCount,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: t("page.panel.chart.no-data"),
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: "24px",
        fontFamily: "Helvetica",
      },
    },
    stroke: {
      width: [0, 4],
    },
    title: {
      text: "QTD DE DOMÍNIOS ÚNICOS",
      style: {
        fontWeight: 400,
        fontSize: 10,
      },
    },
    dataLabels: {
      enabled: true,
    },
    labels: foundAggressors.period,
    xaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          const months = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ];
          val += "";
          const year = val?.split("-")[0];
          const month = val?.split("-")[1];
          const day = val?.split("-")[2];
          const monthValue = months[month - 1];
          const result = day
            ? `${day} ${monthValue}  ${year}`
            : `${monthValue}  ${year}`;
          return result;
        },
      },
    },
    yaxis: [
      {
        title: {
          style: {
            fontWeight: 400,
          },
        },
      },
    ],
    colors: chartColors,
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="card-header-multiple-actions">
          <h4 className="card-title">Agressores encontrados</h4>
        </CardHeader>

        <CardBody>
          <div className="chart-container">
            <div className="chart-container__content w-100">
              <ReactApexChart
                dir="ltr"
                className="apex-charts w-100"
                options={options}
                series={series}
                type="line"
                height={450}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AnalyticalReportFoundAgressor;
