import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({ path }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">
              {path && path.length > 0 ? path[path.length - 1].title : ""}
            </h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {path &&
                  path.map((page, index) => (
                    <li
                      key={index}
                      className={`breadcrumb-item ${
                        index === path.length - 1 ? "active" : ""
                      }`}
                    >
                      {index === path.length - 1 ? (
                        page.title
                      ) : (
                        <Link to={page.link}>{page.title}</Link>
                      )}
                    </li>
                  ))}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
