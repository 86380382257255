import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Card,
  CardBody,
  CardHeader,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
} from "reactstrap";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { useTranslation } from "react-i18next";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";

export const Line = ({
  dataColors,
  onSelectedChannelChange,
  onSelectedPeriodChange,
  selectedChannel,
  selectedPeriod,
  line,
  lineLoading,
  lineError,
  panelLoading,
}) => {
  const { t } = useTranslation();
  var chartLineColumnColors = getChartColorsArray(dataColors);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="card-header-multiple-actions">
          <h4 className="card-title">
            Agressores encontrados ao longo do tempo
          </h4>

          <div className="card-header-actions">
            <div className="d-flex gap-1 me-4">
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(0)}
                className={
                  selectedPeriod === 0
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                Todos
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(334)}
                className={
                  selectedPeriod === 334
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                1A
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(152)}
                className={
                  selectedPeriod === 152
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                6M
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(28)}
                className={
                  selectedPeriod === 28
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                28D
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(14)}
                className={
                  selectedPeriod === 14
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                14D
              </button>
            </div>

            <div className="flex-shrink-0">
              <UncontrolledDropdown className="card-header-dropdown">
                <DropdownToggle
                  tag="a"
                  className="text-reset dropdown-btn"
                  role="button"
                >
                  <span className="fw-semibold text-uppercase fs-12">
                    Tipo de canal:{" "}
                  </span>
                  <span className="text-muted">
                    {selectedChannel.charAt(0).toUpperCase() +
                      selectedChannel.slice(1)}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    onClick={() => onSelectedChannelChange("search")}
                    className={selectedChannel === "search" ? "active" : ""}
                  >
                    Search
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onSelectedChannelChange("shopping")}
                    className={selectedChannel === "shopping" ? "active" : ""}
                  >
                    Shopping
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </CardHeader>
        <CardBody
          className="d-flex align-items-center justify-content-center"
          style={{ height: "500px" }}
        >
          {lineLoading || panelLoading ? (
            <BouncingDotsLoader size={"24px"} />
          ) : (
            <ReactApexChart
              dir="ltr"
              className="apex-charts w-100 h-100"
              options={{
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                noData: {
                  text: t("page.panel.chart.no-data"),
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: "#000000",
                    fontSize: "24px",
                    fontFamily: "Helvetica",
                  },
                },
                stroke: {
                  width: [0, 4],
                },
                title: {
                  text: "QTD DE DOMÍNIOS ÚNICOS",
                  style: {
                    fontWeight: 400,
                    fontSize: 10,
                  },
                },
                dataLabels: {
                  enabled: true,
                },
                labels: line.period,
                xaxis: {
                  labels: {
                    show: true,
                    formatter: function (val) {
                      const months = [
                        "Jan",
                        "Fev",
                        "Mar",
                        "Abr",
                        "Mai",
                        "Jun",
                        "Jul",
                        "Ago",
                        "Set",
                        "Out",
                        "Nov",
                        "Dez",
                      ];
                      val += "";
                      const year = val?.split("-")[0];
                      const month = val?.split("-")[1];
                      const day = val?.split("-")[2];
                      const monthValue = months[month - 1];
                      const result = day
                        ? `${day} ${monthValue}  ${year}`
                        : `${monthValue}  ${year}`;
                      return result;
                    },
                  },
                },
                yaxis: [
                  {
                    title: {
                      style: {
                        fontWeight: 400,
                      },
                    },
                  },
                ],
                colors: chartLineColumnColors,
              }}
              series={[
                {
                  name: "Novos",
                  type: "column",
                  data: line.newDomainsCount,
                },
                {
                  name: "Total",
                  type: "line",
                  data: line.uniqueDomainsCount,
                },
              ]}
              type="line"
              height={400}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
