import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Edit profile
export const getUserProfile = () => api.get(url.GET_USER_PROFILE);

// Register Method
export const postRegister = (data) => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = (data) => api.create(url.POST_LOGIN, data);

// postForgetPwd
export const postForgetPwd = (data) =>
  api.create(url.POST_PASSWORD_FORGET, data);

// postResetPwd
export const postResetPassword = (data) =>
  api.update(url.POST_PASSWORD_RESET, data);

// getCampaign
export const getUserCampaign = (data) => api.get(url.GET_USER_CAMPAIGN, data);

// getUserCompanies
export const getUserCompanies = () => api.get(url.GET_USER_COMPANIES);

// getTotalAgressorsFoundKPI
export const getTotalAgressorsFound = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_FOUND, data);

// getNewAgressorsKPI
export const getTotalAgressorsNew = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_NEW, data);

// getNotifiedAgressorsKPI
export const getTotalAgressorsNotified = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_NOTIFIED, data);

// getAgressorsInMediationKPI
export const getTotalAgressorsMediation = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_MEDIATION, data);

// getAgressorsEliminatedKPI
export const getTotalAgressorsEliminated = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_ELIMINATED, data);

// getAgressorsReappearedKPI
export const getTotalAgressorsReappeared = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_REAPPEARED, data);

// getOffenderByRangeDate
export const getOffenderRangeDate = (data) =>
  api.get(url.GET_OFFENDER_RANGE_DATE, data);

// getTotalOccurrences
export const getOffenderOcurrences = (data) =>
  api.get(url.GET_OCURRENCES, data);

// getBrandScoreByCampaign
export const getBrandScoreByCampaign = (data) =>
  api.get(url.GET_BRANDSCORE + data);

// getTotalReportByCampaign
export const getTotalReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_TOTAL_REPORT, data);

// getNewReportByCampaign
export const getNewReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_NEW_REPORT, data);

// getReappearedReportByCampaign
export const getReappearedReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_REAPPEARED_REPORT, data);

// getNewReportByCampaign
export const getNotifiedReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_NOTIFIED_REPORT, data);

// getMediationReportByCampaign
export const getMediationReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_MEDIATION_REPORT, data);

// getMediationReportByCampaign
export const getEliminatedReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_ELIMINATED_REPORT, data);

// getEventById
export const getEventById = (eventId, data) => api.get(url.GET_EVENT(eventId), data);

// updateEventMediation
export const updateEventMediation = (eventId, data) =>
  api.update(url.PATCH_EVENT_MEDIATION(eventId), data);

// uploadEventEvidence
export const uploadEventEvidence = (eventId, data) =>
  api.createEvidence(url.POST_EVENT_EVIDENCE_UPLOAD(eventId), data);

//getEventAds
export const getEventAds = (data) => api.get(url.GET_EVENT_ADS, data);

//deleteEventEvidence
export const deleteEventEvidence = (eventId, config) =>
  api.delete(url.DELETE_EVENT_EVIDENCE(eventId, config));

//getDailyScores
export const getDailyScores = (data) => api.get(url.GET_DAILY_SCORES, data);

//getDailyTotalAggression
export const getDailyTotalAggression = (data) =>
  api.get(url.GET_DAILY_TOTAL_AGGRESSION, data);

//getAnalyticalKpis
export const getAnalyticalKpis = (data) =>
  api.get(url.GET_ANALYTICAL_KPIS, data);

//getOffendersGroupByDomain
export const getOffendersGroupByDomain = (data) =>
  api.get(url.GET_OFFENDERS_GROUP_BY_DOMAIN, data);

//getOccurrencesTrend
export const getOccurrencesTrend = (data) =>
  api.get(url.GET_OCCURRENCES_TREND, data);

//getBrandScores
export const getBrandScores = (data) => api.get(url.GET_BRAND_SCORES, data);

//getFrauds
export const getFrauds = () => api.get(url.FRAUDS)

//getFrauds by company
export const getFraudsByCompanyId = (data) => api.get(url.FRAUDS,data)

// register fraud
export const registerFraudHelper = (data) => api.createEvidence(url.FRAUDS,data)

// update fraud
export const updateFraudHelper = (data) => api.update(url.FRAUDS,data)