import styled from "styled-components"
import { dsVariables } from "../../variables"
import Icon from "../Icon"
import { SvgIconComponent } from "@mui/icons-material"

interface IProps {
    state?: "default" | "disabled"
    value?: any
    onChange: any
    iconLeft?: SvgIconComponent
    iconRight?: SvgIconComponent
    placeholder?: string
    required?: boolean
    width?: number
}

export default function Input({ state, iconLeft, iconRight, placeholder, value = '', onChange, required = false, width }: IProps) {
    return (
        <Div width={width} iconLeft={iconLeft} className={`position-relative d-flex align-items-center`}>
            <input required={required} onChange={onChange} className="border-0 w-100 " value={value} type="text" placeholder={placeholder} />
            {iconLeft && <div className="position-absolute start-0 ms-2 pe-none"><Icon width={16} height={16} IconComponent={iconLeft} color={dsVariables.colors.Gray[600]} /></div>}
            {iconRight && <div className="position-absolute end-0"> <Icon width={16} height={16} IconComponent={iconRight} color={dsVariables.colors.Gray[600]} /></div>}
        </Div>
    )
}

const Div = styled.div<{ iconLeft?: SvgIconComponent, width?: number }>`
    height: ${dsVariables.spacing[36]};   
    gap: ${dsVariables.spacing[8]};
    align-self: stretch;
    border-radius: ${dsVariables.cornerRadius[4]};
    border: 1px solid ${dsVariables.colors.Gray[400]};
    ${props => props.width && `
        width: ${props.width}px !important;
    `}
    
    input{
        min-width: 190px;
        padding: ${dsVariables.spacing[4]} ${props => props.iconLeft ? dsVariables.spacing[32] : dsVariables.spacing[8]};
    }
`