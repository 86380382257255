import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  RESET_LOGIN_FLAG,
  LOGIN_ERROR
} from "./actionTypes";

const initialState = {
  loginLoading: false,
  loginError: false,
  loginErrorMessage: null
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loginLoading: true,
        loginError: false,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loginLoading: false,
        loginError: false,
        loginErrorMessage: null
      };
      break;
      case LOGIN_ERROR:
        state = {
          ...state,
          loginLoading: true,
          loginError: true,
          loginErrorMessage: action.payload

        };
        break;
    case LOGOUT_USER:
      state = {
         ...state
        };
      break;
    case RESET_LOGIN_FLAG:
      state = {
        ...state,
        loginLoading: false,
        loginError: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
