import React from "react";
import { Table } from "reactstrap";

const PrivacyPolicy = () => {
  return (
    <div
      className="terms-policy-card"
      style={{
        height: "calc(100vh - 250px)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p>
        Um dos fundamentos da Lei Geral de Proteção de Dados (LGPD) é o respeito
        à privacidade. A privacidade dos nossos titulares ("você", "titular") é
        muito importante para a BRANDDI e, por esta razão, não medimos esforços
        para proteger os dados pessoais que tratamos. Desse modo, a presente
        Política de Privacidade de nossa empresa disponível, inclusive, mas não
        se limitando, por meio do nosso site, tem o propósito de explicar de
        forma simples, clara e objetiva que tipo de dados serão coletados, bem
        como o momento e como serão utilizados.
      </p>
      <p>
        Quando nos referimos aos dados pessoais, consideramos qualquer dado
        relacionado à pessoa natural identificada ou identificável, inclusive
        números identificativos, dados de localização ou identificadores
        eletrônicos, quando estes estiverem relacionados a uma pessoa natural.
        Em resumo, qualquer informação pessoal que possa identificar o seu
        titular. Isso se aplica desde informações como nome, endereço, e-mail,
        telefone, entre outros. Os dados sensíveis são dados pessoais sobre
        origem racial ou étnica, convicção religiosa, opinião política, filiação
        a sindicato ou à organização de caráter religioso, filosófico ou
        político, dados referentes à saúde ou à vida sexual, dado genético ou
        biométrico, quando vinculado a uma pessoa natural.
      </p>

      <h6>1. DAS INFORMAÇÕES GERAIS</h6>
      <h6>1.1. Dos termos e definições</h6>
      <p>
        i. <strong>Autoridade Nacional de Proteção de Dados (ANPD)</strong>:
        órgão da administração pública federal, integrante da Presidência da
        República a qual compete, sem limitação, zelar pela proteção dos dados
        pessoais, fiscalizar e aplicar sanções em caso de tratamento de dados
        realizado em descumprimento à legislação, nos termos da Lei Geral de
        Proteção de Dados (LGPD).
      </p>
      <p>
        ii. <strong>Cookies</strong>: são pequenas unidades de dados armazenados
        pelo seu navegador no disco rígido do seu computador. Os cookies são
        necessários para o uso da Plataforma.
      </p>
      <p>
        iii. <strong>Controlador</strong>: pessoa natural ou jurídica, de
        direito público ou privado, a quem compete as decisões referentes ao
        tratamento de dados pessoais.
      </p>
      <p>
        iv. <strong>Operador</strong>: pessoa natural ou jurídica, de direito
        público ou privado, que realiza o tratamento de dados pessoais em nome
        do controlador.
      </p>
      <p>
        v. <strong>Dado pessoal</strong>: Informação relacionada à pessoa
        natural identificada ou identificável.
      </p>
      <p>
        vi. <strong>Dado pessoal sensível</strong>: dado pessoal sobre origem
        racial ou étnica, convicção religiosa, opinião política, filiação a
        sindicato ou a organização de caráter religioso, filosófico ou político,
        dado referente à saúde ou à vida sexual, dado genético ou biométrico,
        quando vinculado a uma pessoa natural.
      </p>
      <p>
        vii. <strong>Lei Geral de Proteção de Dados (LGPD)</strong>: é a lei que
        dispõe sobre o tratamento de dados pessoais, inclusive nos meios
        digitais, por pessoa natural ou por pessoa jurídica de direito público
        ou privado, independentemente do meio, do país de sua sede ou do país
        onde estejam localizados os dados, desde que (i) a operação de
        tratamento seja realizada no território nacional; (ii) a atividade de
        tratamento que tenha por objetivo a oferta ou o fornecimento de bens ou
        serviços ou o tratamento de dados de indivíduos localizados no
        território nacional; ou (iii) os dados pessoais objeto do tratamento
        tenham sido coletados no território nacional.
      </p>
      <p>
        viii. <strong>Política de Segurança da Informação (PSI)</strong>:
        documento no qual a BRANDDI assume o compromisso com a proteção das
        informações de sua propriedade e/ou sob sua guarda, devendo ser cumprida
        por todos os seus colaboradores e operadores. Seu propósito é
        estabelecer as diretrizes a serem seguidas pela empresa e os operadores
        no que diz respeito à adoção de procedimentos e mecanismos relacionados
        à segurança da informação.
      </p>
      <p>
        ix. <strong>Propriedade intelectual</strong>: compreende os direitos
        relativos à propriedade industrial e aos direitos autorais,
        entendendo-se sob esta denominação os direitos de autor e os que lhes
        são conexos.
      </p>
      <p>
        x. <strong>Tratamento</strong>: toda operação realizada com dados
        pessoais, como as que se referem à coleta, produção, recepção,
        classificação, utilização, acesso, reprodução, transmissão,
        distribuição, processamento, arquivamento, armazenamento, eliminação,
        avaliação ou controle da informação, modificação, comunicação,
        transferência, difusão ou extração.
      </p>
      <p>
        xi. <strong>Titular</strong>: pessoa natural, também “titular”, “você”,
        “ele” ou “Titulares” que apenas acessa o site, bem como aquele que
        preenche o formulário de cadastro e concorda com a Política de
        Privacidade.
      </p>

      <h6>1.2. Dos dados coletados e a sua finalidade</h6>
      <p>
        1.2.1. Os dados podem ser coletados em diferentes contextos e a depender
        de como você se relaciona com a BRANDDI.
      </p>
      <p>
        Abaixo, descrevemos as principais situações em que coletamos dados
        pessoais e dados sensíveis:
      </p>

      <ul>
        <li>Cadastro em nosso site para a sua plena utilização.</li>
        <li>Envio de e-mail ou contato conosco por outro canal.</li>
        <li>
          Envio de dados pessoais para o cumprimento de contrato ou de
          procedimento preliminar a este.
        </li>
      </ul>
      <p>
        Os dados tratados pela BRANDDI podem incluir, mas não se limitando, nome
        completo e e-mail.
      </p>

      <p>
        Eventuais dados de terceiros utilizados para a efetivação dos serviços
        prestados pela BRANDMONITOR, tal como envio de notificações e
        comunicações, serão obtidos de bancos de dados públicos, de órgãos da
        administração pública direta ou indireta, órgãos de serviço de proteção
        ao crédito, entre outros.
      </p>

      <p>
        Também coletamos dados de forma automática por meio de cookies. Cookies
        são pequenos arquivos que são instalados e coletam informações sobre seu
        navegador ou dispositivo. Eles nos permitem saber como e quando o site é
        visitado, bem como quantas pessoas os acessam. Eles podem ser úteis
        para, por exemplo, fazer o site encaixar na sua tela, entender melhor as
        suas preferências e lhe oferecer serviços e produtos de forma
        personalizada.
      </p>

      <h6>Nós utilizamos os cookies para algumas finalidades:</h6>
      <ul>
        <li>
          <strong>Cookies essenciais:</strong> São os cookies estritamente
          necessários para fornecer nossos serviços e para que o nosso site
          funcione corretamente, garantindo a segurança da navegação, o
          dimensionamento do conteúdo e o cumprimento de obrigações legais pela
          BRANDDI.
        </li>
        <li>
          <strong>Cookies estatísticos (analytics):</strong> Fornecem
          informações sobre seu comportamento de navegação e como o site está
          sendo usado. Os dados coletados são agregados e nosso objetivo é
          entender melhor o nosso público, para que possamos oferecer conteúdo,
          serviços e produtos mais interessantes para quem acessa nosso site.
        </li>
      </ul>

      <p>
        Se você quiser saber, de forma geral, quais cookies estão instalados no
        seu dispositivo, ou se deseja excluí-los ou restringi-los, use a
        configuração do seu navegador.
      </p>
      <h6>1.2. Dos dados coletados e a sua finalidade</h6>
      <p>
        Os dados podem ser coletados em diferentes contextos e a depender de
        como você se relaciona com a BRANDDI...
      </p>

      <h6>1.2.1.1. Dados Pessoais Tratados pela BRANDDI</h6>
      <ul>
        <li>
          <strong>Tipo de dado:</strong> Dados de qualificação do titular.
        </li>
        <li>
          <strong>Finalidade do uso:</strong> Identificar o Titular; Entrar em
          contato com o Titular para esclarecer eventuais dúvidas tidas por ele
          e explicar nosso ramo e modo de atuação, caso solicitado pelo Titular;
          Cumprir as obrigações decorrentes do acesso ao nosso site; Informar
          sobre novidades, conteúdos, notícias e demais eventos relevantes para
          a manutenção do relacionamento com o Titular; Execução dos nossos
          serviços; Responder às solicitações e pedidos de informações do
          Titular; e Manter os dados em sua base de dados;
        </li>
        <li>
          <strong>Base Legal:</strong> Para a execução de contrato ou de
          procedimentos preliminares relacionados ao contrato do qual seja parte
          o titular, a pedido do titular dos dados; Para atender aos interesses
          legítimos do controlador; Para exercício regular de um direito da
          BRANDDI em procedimento administrativo ou judicial; e Consentimento do
          Titular.
        </li>
      </ul>

      <h6>1.2.1.2. Declaração do Titular ao Tratar Dados com a BRANDDI</h6>
      <p>
        Ao tratar dados com a nossa empresa, o Titular declara: (a) que a
        BRANDDI poderá efetuar a operação dos seus dados pessoais que foram
        coletados no momento do acesso e/ou preenchimento do formulário/contrato
        com seus dados e a forma como a empresa e os operadores cooperam durante
        a operação de tratamento desses dados; (b) ser maior de 18 (dezoito)
        anos e que fez a leitura completa e atenta das regras destes termos,
        estando plenamente ciente do seu teor e conferindo sua manifestação
        livre, informada e inequívoca pela qual concorda com o tratamento de
        seus dados pessoais para uma das finalidades estabelecidas nestes
        Termos; (c) se for representante legal de uma empresa ou de menor ou
        adolescente, possuir os poderes necessários e específicos para aceitar
        estes Termos de Privacidade.
      </p>
      <h6>1.2.1.3. Responsabilidade sobre a Exatidão dos Dados</h6>
      <p>
        O Titular ou responsável legal é o único responsável pela exatidão e
        veracidade dos dados que informar no ato de compartilhamento de dados
        com a BRANDDI.
      </p>

      <h6>1.2.1.4. Verificação da Veracidade dos Dados</h6>
      <p>
        Caso identificada falsidade de informações cadastradas, a BRANDDI
        solicitará ao Titular ou responsável legal esclarecimentos se assim for
        necessário.
      </p>

      <h6>1.2.1.5. Uso Indevido e Violação de Direitos</h6>
      <p>
        Caso a BRANDDI identifique o mau uso do site ou de outro documento ou
        ainda a violação de direitos da propriedade intelectual de sua
        propriedade ou de terceiros, será solicitado ao Titular ou responsável
        legal esclarecimentos, podendo adotar as medidas jurídicas e
        administrativas cabíveis.
      </p>

      <h6>1.2.1.6. Mudança de Finalidade no Tratamento de Dados</h6>
      <p>
        Na hipótese de mudanças da finalidade para o tratamento de dados
        pessoais não compatíveis com a base legal original, a BRANDDI informará
        previamente o Titular ou responsável legal sobre as mudanças de
        finalidade através do meio de contato disponibilizado. Caso o tratamento
        dos dados seja realizado com fundamento na base legal “com
        consentimento”, o titular ou responsável legal poderá revogá-lo caso
        discorde das alterações. No mesmo ato, a BRANDDI informará as
        consequências caso o Titular ou responsável legal não concorde com a
        nova finalidade de tratamento de seus dados pessoais.
      </p>
      <h6>1.2.1.7. Compartilhamento dos Dados Pessoais</h6>
      <p>
        O Titular ou responsável legal se declara ciente de que os dados
        coletados e as atividades registradas poderão ser compartilhados:
      </p>
      <ul>
        <li>
          (a) Com autoridades judiciais, administrativas ou governamentais
          competentes, sempre que houver determinação legal, requerimento,
          requisição ou ordem judicial.
        </li>
        <li>
          (b) De forma automática em caso de operações societárias como fusão,
          aquisição e incorporação.
        </li>
        <li>
          (c) Para operadores que efetuarão o tratamento de dados pessoais do
          Titular.
        </li>
        <li>
          (d) Com terceiros que fazem parte da cadeia de empresas ligadas ao
          negócio empresarial da BRANDDI, inclusive, mas não se limitando,
          prestadores de serviços e fornecedores.
        </li>
      </ul>

      <h6>1.2.1.8. Propriedade dos Dados</h6>
      <p>
        O Titular ou responsável legal se declara ciente de que a base de dados
        formada por meio da coleta de dados é de propriedade e responsabilidade
        da BRANDDI.
      </p>

      <h6>1.2.1.9. Restrições Comerciais</h6>
      <p>
        A BRANDDI não irá comercializar, alugar, ceder, emprestar os dados
        pessoais dos Titulares.
      </p>

      <h6>1.2.1.10. Acesso e Confidencialidade dos Dados</h6>
      <p>
        A BRANDDI garante que, salvo disposição legal, somente os empregados,
        prestadores de serviços devidamente autorizados e empresas relacionadas
        ao seu negócio empresarial terão acesso aos dados pessoais coletados e
        sempre respeitando os princípios de proporcionalidade, necessidade e
        relevância, além do compromisso de confidencialidade e preservação da
        privacidade nos termos desta Política de Privacidade.
      </p>
      <h6>2. DA FORMA DE ARMAZENAMENTO E PRAZO</h6>
      <h6>2.1. Armazenamento e Prazo de Retenção de Dados</h6>
      <p>
        Os dados coletados e os registros de atividades serão armazenados em
        ambiente seguro e controlado pelo prazo mínimo estipulado conforme o
        exemplo abaixo:
      </p>
      <Table hover bordered>
        <thead className="thead-light">
          <tr>
            <th>DADOS PESSOAIS</th>
            <th>PRAZO DE ARMAZENAMENTO</th>
            <th>FUNDAMENTO LEGAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dados pessoais</td>
            <td>Até 05 anos após o término da relação com o Titular</td>
            <td>Art. 173 e 174, do CTN e legislações esparsas</td>
          </tr>
          <tr>
            <td>Dados cadastrais</td>
            <td>Até 02 anos após o término da relação com o Titular</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </Table>
      <h6>2.2. Eliminação Antecipada de Dados</h6>
      <p>
        Caso haja solicitação do Titular, os dados poderão ser eliminados antes
        do prazo fixado no item 2.1, observadas as possibilidades legais.
      </p>
      <ul>
        <li>
          2.2.1. A BRANDDI fará todos os esforços para que a solicitação do
          Titular ou representante legal seja cumprida com a maior brevidade
          possível. No entanto, o titular ou representante legal se declara
          ciente de que existem fatores que fogem ao controle da empresa e que
          poderão impactar no prazo para eliminação do dado ou até impedir o
          pronto atendimento da solicitação.
        </li>
        <li>
          2.2.2. O Titular ou representante legal se declara ciente de que sua
          solicitação poderá ser recusada pela BRANDDI caso, por exemplo, seja
          impossível de verificar sua identidade.
        </li>
      </ul>

      <h6>2.3. Manutenção Prolongada de Dados</h6>
      <p>
        O Titular ou representante legal se declara ciente de que a BRANDDI se
        reserva no direito de manter os dados descritos acima por prazo superior
        para (i) cumprimento de obrigação legal ou regulatória, (ii) estudo por
        órgão de pesquisa previsto no seu objetivo social ou estatutário,
        garantida, sempre que possível, a anonimização dos dados pessoais; (iii)
        transferência a terceiro, desde que respeitados os requisitos de
        tratamento de dados dispostos na LGPD; (iv) uso dos dados pessoais para
        prevenção à fraude (art. 11, II, “a”, da LGPD); (v) uso dos dados
        pessoais para proteção ao crédito (art. 7º, X, LGPD) e (vi) atender
        outros interesses legítimos, em conformidade com o artigo 10 da LGPD.
        Findo o prazo e a necessidade legal, a BRANDDI excluirá, por meio de
        método de descarte seguro, os dados descritos acima ou os manterá para
        seu uso exclusivo, vedado seu acesso por terceiro, e desde que
        anonimizados os dados.
      </p>
      <h6>2.4. Localização e Transferência dos Dados</h6>
      <p>
        Os dados coletados serão armazenados em estruturas fornecidas por
        prestadores de serviços contratados pela BRANDDI ou internamente. Nesse
        caso, o Titular se declara ou representante legal ciente de que poderá
        ocorrer o tratamento de seus dados pessoais para terceiros, parceiros de
        negócio, prestadores de serviços, subcontratados, autoridade, ainda que
        para país estrangeiro, desde que obedecido o disposto na presente
        Política de Privacidade, na legislação aplicável ou determinação
        judicial.
      </p>
      <h6>2.5. Proteção Internacional de Dados</h6>
      <p>
        Caso a operação com dados pessoais seja realizada por um prestador de
        serviços localizado em outro país, a BRANDDI indicará nos instrumentos
        contratuais as diretrizes necessárias para proteger os dados pessoais.
      </p>

      <h6>2.6. Compartilhamento com Prestadores de Serviço</h6>
      <p>
        O Titular se declara ciente de que a BRANDDI poderá transmitir seus
        dados pessoais a outras empresas prestadoras de serviços.
      </p>

      <h6>2.7. Acesso Interno aos Dados</h6>
      <p>
        Internamente, os dados pessoais somente serão acessados por
        profissionais devidamente autorizados pela BRANDDI, respeitando os
        princípios da finalidade, adequação e necessidade, entre outros
        previstos na legislação aplicável, além do compromisso de
        confidencialidade e preservação da privacidade nos termos desta Política
        de Privacidade.
      </p>
      <h6>3. DOS DIREITOS DO TITULAR E O CANAL DE COMUNICAÇÃO</h6>
      <h6>3.1. Direitos do Titular</h6>
      <p>
        O Titular tem direito a obter da BRANDDI, em relação aos dados pessoais
        por nós tratados, a qualquer momento e mediante requisição gratuita:
      </p>
      <ul>
        <li>(a) confirmação da existência de tratamento;</li>
        <li>(b) acesso aos dados;</li>
        <li>(c) correção de dados incompletos, inexatos ou desatualizados;</li>
        <li>
          (d) anonimização, bloqueio ou eliminação de dados desnecessários,
          excessivos ou tratados em desconformidade com o disposto na LGPD;
        </li>
        <li>
          (e) portabilidade dos dados a outro fornecedor de serviço ou produto,
          mediante requisição expressa, observados os segredos comercial e
          industrial;
        </li>
        <li>
          (f) eliminação dos dados pessoais tratados com o seu consentimento,
          exceto nas hipóteses previstas no item 2.3;
        </li>
        <li>
          (g) informação das entidades públicas e privadas com as quais a
          BRANDDI realizou uso compartilhado de dados;
        </li>
        <li>
          (h) informação sobre a possibilidade de não fornecer consentimento e
          sobre as consequências da negativa;
        </li>
        <li>
          (j) revogação do consentimento, nos termos do art. 8º, § 5º, da LGPD.
        </li>
      </ul>

      <h6>3.2. Canal de Comunicação</h6>
      <p>
        O Encarregado da BRANDDI, LEGAL COMPLY CONSULTORIA EMPRESARIAL E
        DESENVOLVIMENTO LTDA, pessoa jurídica de Direito Privado, inscrita no
        CNPJ/MF sob o nº. 52.687.420/0001-91, estará disponível para receber e
        atender as requisições dos titulares dos dados pessoais, bem como para
        esclarecer suas dúvidas, por meio do seguinte canal:
      </p>
      <p>
        <i>E-mail: contato@legalcomply.com.br</i>
      </p>

      <h6>3.3. Comunicação Eletrônica</h6>
      <p>
        O Titular reconhece que na comunicação de atos, divulgação e/ou
        prestação de serviços, transmissão de documentos ou para mantê-lo sempre
        informado sobre qualquer assunto será utilizado, preferencialmente, o
        meio eletrônico, exceto nos casos em que for impossível a utilização
        desse meio.
      </p>
      <ul>
        <li>
          3.3.1. Os prazos para que se cumpra ou se deixe de cumprir determinado
          ato, constante na mensagem eletrônica encaminhada pela BRANDDI serão
          contados a partir do comprovado recebimento.
        </li>
        <li>
          3.3.2. Considera-se recebida a mensagem eletrônica quando o Titular
          receber a notificação, encaminhada pela BRANDDI, que a mensagem foi
          entregue.
        </li>
        <li>
          3.3.3. O Titular deverá habilitar a função para o encaminhamento
          automático da confirmação de recebimento de mensagem eletrônica.
        </li>
      </ul>

      <p>
        3.4. O Titular deverá incluir o e-mail da BRANDDI na lista de remetentes
        confiáveis.
      </p>

      <h6>3.5. Retenção de Dados para Proteção de Direitos</h6>
      <p>
        Para fins de auditoria, segurança, controle de fraudes e preservação de
        direitos, a BRANDDI poderá manter o histórico de registro dos dados do
        Titular por prazo maior nas hipóteses que a lei ou norma regulatória
        assim estabelecer ou para preservação de direitos, nos termos do item
        2.3.
      </p>
      <h6>4. DA SEGURANÇA</h6>
      <h6>4.1. Compromisso com a Segurança</h6>
      <p>
        A segurança de suas informações pessoais é importante para a BRANDDI. A
        empresa adota os padrões e boas práticas de mercado para proteção dos
        dados pessoais desde o momento da coleta até a sua eliminação, mas não
        se limitando a essas operações.
      </p>

      <h6>4.2. Medidas de Proteção</h6>
      <p>
        A BRANDDI emprega as medidas razoáveis e apropriadas para proteger os
        dados pessoais contra perda, uso indevido e acesso não autorizado,
        divulgação, alteração e destruição, levando em consideração os riscos
        envolvidos no processamento e a natureza dos dados pessoais. A BRANDDI
        implementou medidas técnicas e organizacionais apropriadas e projetadas
        para assegurar a proteção de dados.
      </p>

      <h6>4.3. Responsabilidade e Notificação de Segurança</h6>
      <p>
        O Titular ou representante legal se declara ciente de que nenhum método
        de transmissão ou método de armazenamento é 100% seguro. A BRANDDI se
        compromete a realizar os melhores esforços para proteger os dados
        pessoais do Titular; no entanto, a empresa não pode garantir a segurança
        absoluta. Caso informações pessoais sejam comprometidas com uma violação
        da segurança, a BRANDDI notificará, no prazo razoável, o(s) Titular(es)
        afetados e a ANPD, conforme determina a LGPD.
      </p>

      <h6>4.4. Contato sobre Segurança</h6>
      <p>
        Se o Titular ou representante legal tiver alguma dúvida sobre a
        segurança de seus dados pessoais ou a ferramenta utilizada pela BRANDDI,
        deve entrar em contato por meio dos canais indicados no item 3.2.
      </p>

      <h6>5. DA ATUAÇÃO PERANTE A AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS</h6>
      <h6>5.1. Colaboração com a ANPD</h6>
      <p>
        A BRANDDI atuará em conjunto com a Autoridade Nacional de Proteção de
        Dados para zelar pela proteção de dados pessoais nos limites da
        legislação vigente.
      </p>

      <h6>5.2. Revisão de Diretrizes</h6>
      <p>
        O Titular ou representante legal se declara ciente de que a BRANDDI
        revisará suas diretrizes e procedimentos sempre que a ANPD exigir.
      </p>

      <h6>5.3. Comunicação com a ANPD</h6>
      <p>
        Todas as solicitações e/ou questionamentos da ANPD serão respondidas
        pelo Encarregado.
      </p>

      <h6>5.4. Suporte em Procedimentos</h6>
      <p>
        Sempre que a ANPD solicitar a instauração de procedimento para averiguar
        qualquer situação envolvendo dados pessoais, como, mas não se limitando,
        ao descumprimento da LGPD, o Encarregado prestará todo o suporte
        necessário.
      </p>

      <h6>5.5. Contato Exclusivo com a ANPD</h6>
      <p>Caberá somente ao Encarregado manter contato com a ANPD.</p>
      <h6>6. DISPOSIÇÕES GERAIS</h6>
      <h6>6.1. Conformidade com a Legislação</h6>
      <p>
        Nenhuma tecnologia utilizada pela BRANDDI infringe qualquer legislação
        vigente e esta Política de Privacidade tem o objetivo de proteger os
        dados pessoais e garantir a privacidade do Titular.
      </p>
      <h6>6.2. Proibição de Tratamentos Prejudiciais</h6>
      <p>
        A BRANDDI não utilizará método de tratamento automatizado de dados
        pessoais que afetem os interesses do Titular e que vão de encontro com
        esta Política de Privacidade.
      </p>
      <h6>6.3. Alterações na Política</h6>
      <p>
        O Titular ou representante legal se declara ciente de que a BRANDDI
        possui o direito de alterar o teor desta Política de Privacidade a
        qualquer momento, conforme a finalidade ou necessidade, tal qual para
        adequação e conformidade à disposição de lei ou norma que tenha força
        jurídica equivalente, com o compromisso de notificar o Titular caso haja
        necessidade de nova coleta de consentimento.
      </p>
      <h6>6.3.1. Notificação de Atualizações</h6>
      <p>
        Ocorrendo atualizações neste documento e que demandem nova coleta de
        consentimento, caso a BRANDDI utilize essa base legal para o tratamento,
        o Titular será notificado por meio dos contatos que forneceu no
        cadastro.
      </p>
      <h6>6.4. Exigências para Operadores</h6>
      <p>
        A BRANDDI exige dos operadores com os quais compartilha os dados
        pessoais de seus Titulares que realizem o processamento de quaisquer
        dados que coleta, que implementem uma Política de Segurança da
        Informação (PSI) que deverá conter as diretrizes que especificar como,
        por exemplo, os meios tecnológicos para assegurar a proteção dos dados.
        Se os dados forem classificados pela BRANDDI como “críticos”, o(s)
        operador(es) poderá(ão) ser auditado(s) para verificação do cumprimento
        das diretrizes estabelecidas na Política de Segurança da Informação.
        Caso não seja possível auditar o(s) operador(es), a BRANDDI lhe(s)
        exigirá a emissão de declaração que comprove o cumprimento das
        exigências previstas na PSI.
      </p>
      <h6>6.5. Continuidade das Disposições</h6>
      <p>
        Caso a Autoridade Nacional de Proteção de Dados ou uma decisão judicial
        repute que qualquer uma das disposições desta Política de Privacidade
        seja inadequada ou contrária à legislação vigente, as demais condições
        permanecerão em pleno vigor e efeito.
      </p>

      <h6>7. DA LEI APLICÁVEL E JURISDIÇÃO</h6>
      <h6>7.1. Foro de Jurisdição</h6>
      <p>
        A presente Política de Privacidade será regida e interpretada segundo a
        legislação brasileira, sendo eleito o Foro da Comarca de domicílio do
        Titular para dirimir qualquer litígio ou controvérsia envolvendo este
        documento, salvo ressalva específica de competência pessoal, territorial
        ou funcional pela legislação aplicável.
      </p>
      <p>
        <strong>Atualização:</strong> 20 de março de 2024.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
