import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const DAYS_14 = 14;
const startDate = moment().subtract(DAYS_14, "days").startOf("day").toDate();
const endDate = moment()
  .subtract(1, "days")
  .endOf("day")
  .tz("America/Sao_Paulo")
  .toDate();

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isPanel, setIsPanel] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Panel");

  const selectProfileState = (state) => state.Profile;
  const { selectedCampaign, selectedCompany } =
    useSelector(selectProfileState);
    
  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Panel") {
      setIsPanel(false);
    }
  }, [history, iscurrentState, isPanel]);

  const reportSearchParams = new URLSearchParams();
  reportSearchParams.set('company', selectedCompany.label);
  reportSearchParams.set('campaignId', selectedCampaign.value);
  reportSearchParams.set('startDate', startDate);
  reportSearchParams.set('endDate', endDate);
  reportSearchParams.set('days', DAYS_14);
  reportSearchParams.set('agressor', '');
  reportSearchParams.set('channel', '');
  reportSearchParams.set('actualPhase', '');
  reportSearchParams.set('notify', '');
  reportSearchParams.set('keyword', '');
  reportSearchParams.set('page', '');
  reportSearchParams.set('position', '');
  reportSearchParams.set('paginationIndex', '0');
  reportSearchParams.set('tab', 'identificados')

  const reportsLink = `/reports/?${reportSearchParams.toString()}`;

  const analyticalReportSearchParams = new URLSearchParams();
  analyticalReportSearchParams.set('company', selectedCompany.label);
  analyticalReportSearchParams.set('campaignId', selectedCampaign.value);
  analyticalReportSearchParams.set('startDate', startDate);
  analyticalReportSearchParams.set('endDate', endDate);
  analyticalReportSearchParams.set('days', DAYS_14);

  const analyticalReportLink = `/analytical-report/?${analyticalReportSearchParams.toString()}`;

  const manageThreatsSearchParams = new URLSearchParams();
  manageThreatsSearchParams.set('startDate', startDate);
  manageThreatsSearchParams.set('endDate', new Date());
  
  const manageThreatsLink = `/manage-threats/?${manageThreatsSearchParams.toString()}`

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "brandBidding",
      label: "menu.brand-bidding",
      link: "/#",
      stateVariables: isPanel,
      click: function (e) {
        e.preventDefault();
        setIsPanel(!isPanel);
        setIscurrentState("Panel");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "panel",
          label: "menu.brand-bidding.submenu.panel",
          icon: "bx bxs-dashboard",
          link: "/panel",
          parentId: "brandBidding",
        },
        {
          id: "reports",
          label: "menu.brand-bidding.submenu.reports",
          icon: "bx bx-receipt",
          link: reportsLink,
          parentId: "brandBidding",
        },
        {
          id: "analytical-report",
          label: "menu.brand-bidding.submenu.analytical-report",
          icon: "bx bx-chart",
          link: analyticalReportLink,
          parentId: "brandBidding",
        },
      ],
    },
    {
      id: "fraud",
      label: "menu_fraud.fraud",
      link: "/#",
      stateVariables: isPanel,
      click: function (e) {
        e.preventDefault();
        setIsPanel(!isPanel);
        setIscurrentState("Panel");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "panel",
          label: "menu_fraud.fraud.submenu.panel",
          icon: "bx bxs-dashboard",
          link: "/frauds-panel",
          parentId: "fraud",
        },
        {
          id: "threats",
          label: "menu_fraud.fraud.submenu.threats",
          icon: "la la-theater-masks",
          link: manageThreatsLink,
          parentId: "fraud",
        },
      ],
    },
  ];
  return <>{menuItems}</>
};
export default Navdata;
