import React from "react";
import withRouter from "../../Components/Common/withRouter";
import { Container, Card, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Import Images
import logoLight from "../../assets/images/transition_branddi_dark_transparent.svg";

const ParticlesAuth = ({ children }) => {
  return (
    <div className="auth-page-wrapper min-vh-100">
      <div
        className="auth-one-bg-position auth-one-bg min-vh-100"
        id="auth-particles"
      >
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="bg-overlay"></div>
          <div className="flex-grow-1">
            <div className="auth-page-content overflow-hidden">
              <Container>
                <Row>
                  <Col lg={12}>
                    <Card className="overflow-hidden card-bg-fill border-0 card-border-effect-none mb-0">
                      <Row className="g-0">
                        <Col lg={6}>
                          <div className="p-lg-5 p-4 auth-one-bg h-100">
                            <div className="position-relative h-100 d-flex flex-column">
                              <div className="mb-4">
                                <Link to="/dashboard" className="d-block">
                                  <img src={logoLight} alt="" height="60" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {children}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>

            <footer className="footer position-relative">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center">
                      <p className="mb-0">
                        &copy; {new Date().getFullYear()} BRANDMONITOR SERVICOS
                        ONLINE LTDA
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ParticlesAuth);
