import styled from "styled-components"
import Tag, { TTagType } from "../../Tag"
import { SvgIconComponent } from "@mui/icons-material"
import Icon from "../../Icon"
import Tooltip, { TTooltipPosition } from "../../Tooltip/Tooltip"
import OptionsCard from "./OptionsCard"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSearchParams } from "react-router-dom";
import { dsVariables } from "../../../variables"
import Label from "../../Text/Label"

export interface IIcon {
    Icon: SvgIconComponent
    color?: string
    onClick: any
    tooltipText?: string
    tooltipPosition?: TTooltipPosition;
}

export interface ICellProps {
    label?: string
    Icons?: IIcon[]
    showCheckbox?: boolean
    buttonLink?: boolean
    showTag?: boolean
    showBall?: boolean
    showAvatar?: boolean
    isTag?: boolean
    tagType?: TTagType
    onChangeCheckbox?:any
    checked?:boolean
}

export default function Cell({ Icons, label, showAvatar, showBall, buttonLink, showCheckbox, showTag, isTag = false, onChangeCheckbox, checked=false, tagType }: ICellProps) {

    const [showOptionsCard, setShowOptionsCard] = useState(false);

    const location = useLocation()
    const searchParams = useSearchParams()[0]
    const tabIsThree = searchParams.get('tab') == '4'

    useEffect(() => {
        setShowOptionsCard(false)
    }, [location]);

    const isMoreOptionsCell = (icon: IIcon) => icon.tooltipText == 'Mais opções'

    function handleIconClick(icon: IIcon) {
        if(tabIsThree && isMoreOptionsCell(icon)) return

        return isMoreOptionsCell(icon)
            ? setShowOptionsCard(!showOptionsCard)
            : icon.onClick()
    }

    return (
        <Td>
            {showCheckbox && <input checked={checked} type="checkbox" onChange={e => onChangeCheckbox(e.target.checked)} />}
            {
                Icons &&
                <div className="d-flex gap-2 justify-content-center">
                    {Icons.map((icon, index) =>
                        icon.tooltipText ?
                            <div className="position-relative" key={index} >
                                <Tooltip text={tabIsThree && isMoreOptionsCell(icon) ? 'Desabilitado nesta etapa' :icon.tooltipText} >
                                    <Icon width={24} height={24} IconComponent={icon.Icon} color={tabIsThree && isMoreOptionsCell(icon) ? dsVariables.colors.Gray[700] : icon.color} onClick={() => handleIconClick(icon)} />
                                </Tooltip>
                                {(showOptionsCard && isMoreOptionsCell(icon)) && <OptionsCard onClick={icon.onClick} />}
                            </div>
                            :
                            <Icon key={index} IconComponent={icon.Icon} color={icon.color} onClick={icon.onClick} />
                    )}
                </div>
            }
            {label ?
                isTag
                    ? <Tag type={tagType} label={label} />
                    : <div className="overflow-hidden"><Label>{label}</Label></div>
                : null
            }
        </Td>
    )
}

const Td = styled.td`
    height: Fixed (44px)px;
    padding: 8px 12px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid var(--Colors-Gray-200, #E6E6E6);
    vertical-align:middle;
    white-space: nowrap;
    max-width: 200px;
`