export const SET_REPORT_CAMPAIGNID = "SET_REPORT_CAMPAIGNID";

export const SET_POSITIONS_FILTER = "SET_POSITIONS_FILTER";

export const SET_REPORT_START_DATE = "SET_REPORT_START_DATE";
export const SET_REPORT_END_DATE = "SET_REPORT_END_DATE";

export const GET_CAMPAIGN_TOTAL_REPORT = "GET_CAMPAIGN_TOTAL_REPORT";
export const SET_CAMPAIGN_TOTAL_REPORT_LOADING =
  "SET_CAMPAIGN_TOTAL_REPORT_LOADING";
export const SET_TOTAL_CAMPAIGN_REPORT = "SET_TOTAL_CAMPAIGN_REPORT";
export const SET_CAMPAIGN_REPORT_ERROR = "SET_CAMPAIGN_REPORT_ERROR";

export const GET_CAMPAIGN_NEW_REPORT = "GET_CAMPAIGN_NEW_REPORT";
export const SET_CAMPAIGN_NEW_REPORT_LOADING =
  "SET_CAMPAIGN_NEW_REPORT_LOADING";
export const SET_CAMPAIGN_NEW_REPORT = "SET_TOTAL_CAMPAIGN_NEW_REPORT";
export const SET_CAMPAIGN_NEW_REPORT_ERROR = "SET_CAMPAIGN_NEW_REPORT_ERROR";

export const GET_CAMPAIGN_REAPPEARED_REPORT = "GET_CAMPAIGN_REAPPEARED_REPORT";
export const SET_CAMPAIGN_REAPPEARED_REPORT_LOADING =
  "SET_CAMPAIGN_REAPPEARED_REPORT_LOADING";
export const SET_CAMPAIGN_REAPPEARED_REPORT =
  "SET_TOTAL_CAMPAIGN_REAPPEARED_REPORT";
export const SET_CAMPAIGN_REAPPEARED_REPORT_ERROR =
  "SET_CAMPAIGN_REAPPEARED_REPORT_ERROR";

export const GET_CAMPAIGN_NOTIFIED_REPORT = "GET_CAMPAIGN_NOTIFIED_REPORT";
export const SET_CAMPAIGN_NOTIFIED_REPORT_LOADING =
  "SET_CAMPAIGN_NOTIFIED_REPORT_LOADING";
export const SET_CAMPAIGN_NOTIFIED_REPORT =
  "SET_TOTAL_CAMPAIGN_NOTIFIED_REPORT";
export const SET_CAMPAIGN_NOTIFIED_REPORT_ERROR =
  "SET_CAMPAIGN_NOTIFIED_REPORT_ERROR";

export const GET_CAMPAIGN_MEDIATION_REPORT = "GET_CAMPAIGN_MEDIATION_REPORT";
export const SET_CAMPAIGN_MEDIATION_REPORT_LOADING =
  "SET_CAMPAIGN_MEDIATION_REPORT_LOADING";
export const SET_CAMPAIGN_MEDIATION_REPORT =
  "SET_TOTAL_CAMPAIGN_MEDIATION_REPORT";
export const SET_CAMPAIGN_MEDIATION_REPORT_ERROR =
  "SET_CAMPAIGN_MEDIATION_REPORT_ERROR";

export const GET_CAMPAIGN_ELIMINATED_REPORT = "GET_CAMPAIGN_ELIMINATED_REPORT";
export const SET_CAMPAIGN_ELIMINATED_REPORT_LOADING =
  "SET_CAMPAIGN_ELIMINATED_REPORT_LOADING";
export const SET_CAMPAIGN_ELIMINATED_REPORT =
  "SET_TOTAL_CAMPAIGN_ELIMINATED_REPORT";
export const SET_CAMPAIGN_ELIMINATED_REPORT_ERROR =
  "SET_CAMPAIGN_ELIMINATED_REPORT_ERROR";

export const SET_TAB = "SET_TAB";
export const SET_RANGE_DATE = "SET_RANGE_DATE";
