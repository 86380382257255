import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TermsOfUse from "../../../../Components/Common/TermsOfUse";

const Starter = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Terms of Use" pageTitle="Terms of Use" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">
                    TERMOS DE USO DA PLATAFORMA BRANDDI
                  </CardTitle>
                  <TermsOfUse />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Starter;
