import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Table,
  Button,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import TicketDetailsModalEvidence from "./TicketDetailsModalEvidence";
import {
  setAggressorColorByScore,
  setStatusColor,
} from "../../../../utils/setColors";
import classnames from "classnames";
import { formatDateWithMoment } from "../../../../utils/dateFormat";
import { useDispatch, useSelector } from "react-redux";
import { deleteEventEvidence } from "../../../../store/actions";
import {
  mapActing,
  mapCategory,
  mapPhase,
} from "../../../../utils/mapsTranslate";

const TicketDescription = (props) => {
  const [modalEvidence, setModalEvidence] = useState(false);
  const toggleModalEvidence = () => setModalEvidence(!modalEvidence);
  const eventData = props.eventData;
  const [evidence, setEvidence] = useState(
    eventData.evidence?.attachments?.url
  );
  const dispatch = useDispatch();
  const handleActionModal = () => {
    props.handleToggle();
    props.handleAds();
  };

  const handleDeleteEvidence = () => {
    if (evidence) dispatch(deleteEventEvidence(eventData._id));
    if (props.deleteEventEvidenceError !== null) setEvidence(null);
  };

  return (
    <React.Fragment>
      <Col xxl={4}>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Detalhes</h5>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless table-details align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium w-50">Ticket</td>
                    <td className="w-50">#{eventData._id || "-"}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Agressor</td>
                    <td className="d-flex align-items-center">
                      <a
                        href={`http://www.${eventData.domain}`}
                        className="text-decoration-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {eventData.domain || "-"}
                      </a>
                      <i className="bx bx-link-external ms-1"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Agressividade</td>
                    <td className="w-50">
                      <span>
                        <i
                          className={classnames(
                            setAggressorColorByScore(
                              eventData.aggressivenessIndex?.toFixed(2)
                            ),
                            "mdi mdi-circle align-middle me-2 "
                          )}
                        ></i>
                        {eventData.aggressivenessIndex?.toFixed(2) || "-"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Campanha</td>
                    <td className="w-50">{eventData.campaign?.name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Canais</td>
                    <td className="hstack text-wrap gap-1">
                      {eventData.channel?.length > 0
                        ? eventData.channel.map((item, index) => (
                            <span
                              key={index}
                              className="badge bg-primary-subtle text-primary fw-normal"
                            >
                              {item}
                            </span>
                          ))
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium align-top">Página e Pos.</td>
                    <td className="d-flex flex-wrap gap-1">
                      {eventData.firstPage.map((item, index) => (
                        <span
                          key={index}
                          className="badge bg-primary-subtle text-primary fw-normal"
                        >
                          Pag. 1 Pos.{item}
                        </span>
                      ))}
                      {eventData.secondPage.map((item, index) => (
                        <span
                          key={index}
                          className="badge bg-primary-subtle text-primary fw-normal"
                        >
                          Pag. 2 Pos.{item}
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Violação de Marca</td>
                    <td className="w-50">
                      {eventData.tm ? "No texto do anúncio" : "Não encontrado"}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Palavras-chave</td>
                    <td className="d-flex flex-wrap gap-1">
                      {eventData.campaign?.keywords?.length > 0
                        ? eventData.campaign.keywords.map((item, index) => (
                            <span
                              key={index}
                              className="badge bg-primary-subtle text-primary fw-normal"
                            >
                              {item}
                            </span>
                          ))
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Fase Atual</td>
                    <td className="hstack text-wrap gap-1">
                      {eventData.status ? (
                        <Badge
                          color={setStatusColor(mapPhase[eventData.status])}
                        >
                          {mapPhase[eventData.status]}
                        </Badge>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Primeira ocorrência</td>
                    <td className="w-50">
                      {formatDateWithMoment(eventData.createdAt)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Última atividade</td>
                    <td className="w-50">
                      {formatDateWithMoment(eventData.updatedAt)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium w-50">Última ocorrência</td>
                    <td className="w-50">
                      {formatDateWithMoment(eventData.lastOccursAt)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        {eventData.category && (
          <Card>
            <CardHeader>
              <h6 className="card-title fw-semibold mb-0">
                Dados da Aprovação
              </h6>
            </CardHeader>
            <CardBody>
              <div className="table-responsive table-card">
                <Table className="table-borderless table-details align-middle mb-0">
                  <tbody>
                    <tr>
                      <td className="fw-medium w-50">Categoria</td>
                      <td className="w-50">
                        {mapCategory[eventData.category] || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-medium w-50">Atuação</td>
                      <td className="w-50">
                        {mapActing[eventData.acting] || "-"}
                      </td>
                    </tr>
                    {evidence && (
                      <tr>
                        <td className="fw-medium w-50">Boa-fé</td>
                        <td
                          className="text-decoration-underline cursor-pointer w-50"
                          onClick={() => toggleModalEvidence()}
                        >
                          ver print
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="fw-medium w-50">Data Aprovação</td>
                      <td className="w-50">
                        {formatDateWithMoment(eventData.approvedAt)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        )}

        <Card>
          <CardHeader>
            <h6 className="card-title fw-semibold mb-0">Evidências</h6>
          </CardHeader>
          <CardBody>
            {evidence && (
              <div className="d-flex align-items-center border border-dashed p-2 rounded">
                <div className="flex-shrink-0 avatar-sm">
                  <div className="avatar-title bg-light rounded">
                    <i className="bx bxs-file-image fs-20 text-secondary"></i>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6
                    className="mb-1 cursor-pointer text-primary"
                    onClick={() => toggleModalEvidence()}
                  >
                    Prova Negativação.jpg
                  </h6>
                </div>
                <div className="hstack gap-3 fs-16">
                  <a href={evidence} className="text-muted" download>
                    <i className="ri-download-2-line"></i>
                  </a>
                  <button
                    onClick={() => handleDeleteEvidence()}
                    className="btn btn-link text-muted border-0 bg-transparent"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </div>
              </div>
            )}
            <div className="d-flex  align-items-center border border-dashed p-2 rounded mt-2">
              <div className="flex-shrink-0 avatar-sm">
                <div className="avatar-title bg-light rounded">
                  <i className="bx bx-file-find fs-20 text-secondary"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6
                  className="mb-1 cursor-pointer text-primary"
                  onClick={handleActionModal}
                >
                  Anúncios encontrados
                </h6>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <TicketDetailsModalEvidence
        isOpen={modalEvidence}
        toggle={toggleModalEvidence}
        evidence={evidence}
      />
    </React.Fragment>
  );
};

export default TicketDescription;
