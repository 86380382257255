import axios from "axios";
import moment from "moment-timezone";
import { api } from "../config";
import { localStorageKeys } from "../config/localStorageKeys";
import { store } from "../store";
import { resetProfileFlag, setAccessForbidden } from "../store/actions";

axios.defaults.baseURL = api.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

const MAX_RETRIES = 4; // Aumentar o número de tentativas para 4
const BASE_DELAY = 200;

// Função para adicionar atraso com backoff exponencial
const exponentialBackoff = (retryCount) => {
  return BASE_DELAY * Math.pow(2, retryCount);
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

axios.interceptors.response.use(
  async function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    const config = error.config;
    if (!config || !config.retryCount) {
      config.retryCount = 0;
    }

    if (config.retryCount < MAX_RETRIES) {
      config.retryCount += 1;
      const delay = exponentialBackoff(config.retryCount);
      await sleep(delay); // Aguardar um tempo com backoff exponencial antes de tentar novamente
      return axios(config);
    }

    let message;
    switch (error.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        store.dispatch(resetProfileFlag());
        break;
      case 403:
        message = "Forbidden";
        store.dispatch(setAccessForbidden());
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message =
          { message: error.message, data: error.response?.data } || { errodoTreco: error };
    }
    return Promise.reject(message);
  }
);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem(localStorageKeys.AUTH_USER);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.params) {
    if (config.params.startDate) {
      config.params.startDate = moment(config.params.startDate)
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DDTHH:mm:ssZ");
    }
    if (config.params.endDate) {
      config.params.endDate = moment(config.params.endDate)
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DDTHH:mm:ssZ");
    }

    // Detect and convert position[]
    if (config.params.position) {
      // Convert position[] to a string separated by commas
      if (Array.isArray(config.params.position)) {
        config.params.position = config.params.position.join(',');
      }
    }

  }

  return config;
});

class APIClient {
  get = (url, params) => {
    return axios.get(url, { params })
      .then(response => {
        // If response data is null or undefined, return an empty array
        return response || [];
      })
      .catch(error => {
        // Log the error and return an empty array to prevent frontend from breaking
        console.error('APIClient get error:', error);
        return [];
      });
  };

  create = (url, data) => {
    return axios.post(url, data);
  };

  createEvidence = (url, data) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };

  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient };
