import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_BRANDSCORE,
  GET_LINE_AGGRESSORS,
  GET_TOP_AGGRESSORS,
  GET_TOTAL_AGGRESSOR_ELIMINATED,
  GET_TOTAL_AGGRESSOR_FOUND,
  GET_TOTAL_AGGRESSOR_MEDIATION,
  GET_TOTAL_AGGRESSOR_NEW,
  GET_TOTAL_AGGRESSOR_NOTIFIED,
  GET_TOTAL_AGGRESSOR_REAPPEARED,
  GET_TOTAL_ELIMINATED,
} from "./actionTypes";
import {
  getBrandScoreByCampaign,
  getOffenderOcurrences,
  getOffenderRangeDate,
  getTotalAgressorsEliminated,
  getTotalAgressorsFound,
  getTotalAgressorsMediation,
  getTotalAgressorsNew,
  getTotalAgressorsNotified,
  getTotalAgressorsReappeared,
} from "../../../helpers/backend_helper";
import {
  lineApiError,
  setBrandScore,
  setBrandScoreError,
  setBrandScoreLoading,
  setCounterSuccess,
  setLineAgressorsSuccess,
  setLineLoading,
  setPanelCampaignId,
  setTopAggressorsError,
  setTopAggressorsLoading,
  setTopAggressorsSuccess,
  setTotalEliminated,
  setTotalEliminatedError,
  setTotalEliminatedLoading,
  setWidgetLoading,
  widgetApiError,
} from "./actions";

function* getAgressorsFound({ payload: { campaignId, startDate, endDate } }) {
  try {
    yield put(setWidgetLoading("foundAgressors"));
    const response = yield call(getTotalAgressorsFound, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setCounterSuccess("foundAgressors", response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(widgetApiError("foundAgressors"));
  }
}

function* getAgressorsNew({ payload: { campaignId, startDate, endDate } }) {
  try {
    yield put(setWidgetLoading("newAgressors"));
    const response = yield call(getTotalAgressorsNew, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setCounterSuccess("newAgressors", response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(widgetApiError("newAgressors"));
  }
}

function* getAgressorsNotified({
  payload: { campaignId, startDate, endDate },
}) {
  try {
    yield put(setWidgetLoading("notifiedAgressors"));
    const response = yield call(getTotalAgressorsNotified, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setCounterSuccess("notifiedAgressors", response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(widgetApiError("notifiedAgressors"));
  }
}

function* getAgressorsMediation({
  payload: { campaignId, startDate, endDate },
}) {
  try {
    yield put(setWidgetLoading("inMediationAgressors"));
    const response = yield call(getTotalAgressorsMediation, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setCounterSuccess("inMediationAgressors", response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(widgetApiError("inMediationAgressors"));
  }
}

function* getAgressorsEliminated({
  payload: { campaignId, startDate, endDate },
}) {
  try {
    yield put(setWidgetLoading("eliminatedAgressors"));
    const response = yield call(getTotalAgressorsEliminated, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setCounterSuccess("eliminatedAgressors", response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(widgetApiError("eliminatedAgressors"));
  }
}

function* getAgressorsReappeared({
  payload: { campaignId, startDate, endDate },
}) {
  try {
    yield put(setWidgetLoading("reappearedAgressors"));
    const response = yield call(getTotalAgressorsReappeared, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setCounterSuccess("reappearedAgressors", response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(widgetApiError("reappearedAgressors"));
  }
}

function* getLineAgressors({
  payload: {
    campaignId,
    startDate,
    endDate,
    type,
    domain,
    pageFound,
    position,
    words,
  },
}) {
  try {
    yield put(setLineLoading());
    let payload = {
      campaignId,
      startDate,
      endDate,
    };
    if (type) {
      payload.type = type;
    }
    if (domain) {
      payload.domain = domain;
    }

    if (pageFound) {
      payload.pageFound = pageFound;
    }

    if (position) {
      payload.position = position;
    }

    if (words) {
      payload.words = words;
    }
    const response = yield call(getOffenderRangeDate, payload);
    const period = response[0].uniqueDomainsCount.map(
      (domain) => domain.period
    );
    const uniqueDomainsCount = response[0].uniqueDomainsCount.map(
      (domain) => domain.count
    );
    const newDomainsCount = response[0].newDomainsCount.map(
      (domain) => domain.count
    );
    const line = { period, uniqueDomainsCount, newDomainsCount };
    yield put(setLineAgressorsSuccess(line));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(lineApiError());
  }
}

function* getTopAggressors({
  payload: { campaignId, startDate, endDate, limit, page, type },
}) {
  try {
    yield put(setTopAggressorsLoading());
    const response = yield call(getOffenderOcurrences, {
      campaignId,
      startDate,
      endDate,
      limit,
      page,
      type,
    });
    yield put(setTopAggressorsSuccess(response));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(setTopAggressorsError());
  }
}

function* getBrandScore({ payload: { campaignId } }) {
  try {
    yield put(setBrandScoreLoading());
    const response = yield call(getBrandScoreByCampaign, campaignId);
    yield put(setBrandScore(response.brandScore));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(setBrandScoreError());
  }
}

function* getTotalEliminated({ payload: { campaignId, startDate, endDate } }) {
  try {
    yield put(setTotalEliminatedLoading());
    const response = yield call(getTotalAgressorsEliminated, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(setTotalEliminated(response.total));
    yield put(setPanelCampaignId(campaignId));
  } catch {
    yield put(setTotalEliminatedError());
  }
}

function* panelSaga() {
  yield takeLatest(GET_TOTAL_AGGRESSOR_FOUND, getAgressorsFound);
  yield takeLatest(GET_TOTAL_AGGRESSOR_NEW, getAgressorsNew);
  yield takeLatest(GET_TOTAL_AGGRESSOR_NOTIFIED, getAgressorsNotified);
  yield takeLatest(GET_TOTAL_AGGRESSOR_MEDIATION, getAgressorsMediation);
  yield takeLatest(GET_TOTAL_AGGRESSOR_ELIMINATED, getAgressorsEliminated);
  yield takeLatest(GET_TOTAL_AGGRESSOR_REAPPEARED, getAgressorsReappeared);
  yield takeLatest(GET_LINE_AGGRESSORS, getLineAgressors);
  yield takeLatest(GET_TOP_AGGRESSORS, getTopAggressors);
  yield takeLatest(GET_BRANDSCORE, getBrandScore);
  yield takeLatest(GET_TOTAL_ELIMINATED, getTotalEliminated);
}

export default panelSaga;
