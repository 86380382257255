import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  RESET_REGISTER_FLAG,
} from "./actionTypes";

const initialState = {
  registerError: null,
  registerErrorMessage: null,
  registerLoading: false,
  registerSuccess: false,
  isPasswordCreated: false,
};

const Register = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        registerLoading: true,
        registerError: null,
      };
      break;
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        registerError: null,
        registerLoading: false,
        registerSuccess: true,
        isPasswordCreated: true,
      };
      break;
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        registerError: true,
        registerErrorMessage: action.payload,
        registerLoading: false,
        registerSuccess: false,
        isPasswordCreated: false,
      };
      break;
    case RESET_REGISTER_FLAG:
      state = {
        ...state,
        registerSuccess: false,
        registerError: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Register;
