import React from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import iconEmail from "../../assets/images/icons/mail.png";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { userForgetPassword } from "../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import withRouter from "../../Components/Common/withRouter";

const ForgetPassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(
        t("page.authentication.password-reset.rule.email-required")
      ),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    },
  });

  const selectForgetPasswordState = (state) => state.ForgetPassword;

  const { forgetPasswordLoading, forgetPasswordMessageSent } = useSelector(
    selectForgetPasswordState
  );

  return (
    <React.Fragment>
      <ParticlesAuth>
        <Col lg={6}>
          <div className="p-lg-5 p-4">
            <h5 className="text-primary">
              {t("page.authentication.password-reset.title")}
            </h5>
            <p className="text-muted mb-4">
              {t("page.authentication.password-reset.subtitle")}
            </p>

            <div className="mt-3 mb-3 text-center">
              <img src={iconEmail} alt="" height="100" />
            </div>
            {!forgetPasswordMessageSent && (
              <div
                className="alert border-0 alert-warning text-center mb-2"
                role="alert"
              >
                {t("page.authentication.password-reset.message-info")}
              </div>
            )}

            {forgetPasswordMessageSent && (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {t("page.authentication.password-reset.message-success")}
              </Alert>
            )}

            <div className="p-2">
              <Form onSubmit={validation.handleSubmit}>
                <div className="mb-4">
                  <Label className="form-label">
                    {t("page.authentication.password-reset.label.email")}
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder={t(
                      "page.authentication.password-reset.placeholder.email"
                    )}
                    name="email"
                    value={validation.values.email}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.email && validation.touched.email
                        ? true
                        : false
                    }
                  />
                  {validation.errors.email && validation.touched.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="text-center mt-4">
                  <Button
                    color="secondary"
                    className="w-100 d-flex justify-content-center align-items-center"
                    type="submit"
                  >
                    {forgetPasswordLoading && (
                      <Spinner size="sm" className="me-2" />
                    )}
                    {t("page.authentication.password-reset.btn.send-link")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ForgetPassword);
