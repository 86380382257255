import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESFUL,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_FLAG
} from "./actionTypes"

export const resetPassword = (user, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { user, history },
  }
}

export const resetPasswordSuccessful = () => {
  return {
    type: RESET_PASSWORD_SUCCESFUL,
  }
}

export const resetPasswordFailed = () => {
  return {
    type: RESET_PASSWORD_FAILED,
  }
}

export const resetPasswordFlag = () => {
  return {
    type: RESET_PASSWORD_FLAG,
  }
}

