import React from "react";

const TermsOfUse = () => {
  return (
    <div
      className="terms-policy-card"
      style={{
        height: "calc(100vh - 250px)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p>
        O presente Termo de Uso (“Termo”) regulamenta a disponibilização, a
        utilização e o acesso pelo usuário (doravante simplesmente denominado
        “Usuário” ou “Você”, incluídas as variações “Seu(s)” e “Sua(s)”) à
        plataforma “Branddi” (“Produto” ou “Plataforma”) através da página na
        web{" "}
        <a href="http://platform.branddi.com" style={{ color: "blue" }}>
          https://platform.branddi.com/
        </a>{" "}
        (“Site”), de titularidade da BRANDMONITOR SERVIÇOS ONLINE LTDA., pessoa
        jurídica de Direito Privado, inscrita no CNPJ/MF sob o nº.
        48.086.255/0001-45, com sede na Avenida Yojiro Takaoka, nº. 4.384, sala
        701, Alphaville, Santana de Parnaíba/SP, CEP 06541-038 (“Brandmonitor”,
        “Nós” e “Nosso(a)”).
      </p>

      <p>
        Obrigado por se conectar com a BRANDDI! Sempre que vamos a um novo
        lugar, criamos expectativas e queremos nos sentir especiais e receber um
        bom tratamento. Nós da BRANDDI sabemos disso e uma das nossas
        prioridades é oferecer a melhor experiência para todas as pessoas que
        usam a nossa plataforma.
      </p>
      <p>
        Ao utilizar e/ou acessar o Site, Você está concordando com este Termo.
        Por isso, é importante que Você o leia com muita atenção.
      </p>
      <section>
        <h6>1. CONDIÇÕES GERAIS PARA USO DO SITE</h6>
        <p>
          1.1. A plataforma Branddi atua como um canal de comunicação crucial
          entre os clientes da BrandMonitor e sua equipe de especialistas em
          proteção de marca. Com uma interface intuitiva e robusta, a plataforma
          permite que os clientes acompanhem de perto e gerenciem ativamente as
          tratativas relacionadas à segurança de suas marcas. Na Branddi, é
          possível monitorar claramente o status de cada ticket, que são gerados
          por nossa equipe especializada para responder a ameaças específicas ou
          usos indevidos das marcas. Os clientes utilizam a Branddi não apenas
          para visualizar essas informações, mas também para revisar e
          formalizar as ações propostas pela equipe da BrandMonitor. Esse
          processo confere aos clientes controle total e decisivo sobre a gestão
          das questões de proteção de marca, garantindo que todas as medidas
          sejam tratadas de maneira eficaz e conforme as suas diretrizes.
        </p>
        <h6>1.1.2 Funcionalidade disponíveis para os Usuários da Branddi:</h6>
        <p>
          <ul>
            <li>
              (a) Identificação de Novos Concorrentes: Visualizar novos
              concorrentes que estão realizando Brand Bidding com seus termos de
              marca.
            </li>
            <li>
              (b) Acompanhamento de Tratativas: Acompanhar todo o trabalho
              realizado pela BrandMonitor, e evolução das tratativas incluindo o
              status das mediações e notificações enviadas.
            </li>
            <li>
              (c) Relatórios de Performance: Acessar relatórios detalhados de
              performance da proteção de marca. Estes relatórios incluem
              indicativos que demonstram quais concorrentes são mais ou menos
              agressivos nos leilões do Google e outros indicadores relacionados
              à proteção contra concorrência desleal.
            </li>
            <li>
              (d) Gerenciamento da Proteção de Marca: Categorizar anunciantes e
              aprovar ações extrajudiciais/administrativas propostas pela
              BrandMonitor. Os clientes também podem sinalizar quais anunciantes
              são parceiros ou estão na safelist, ajustando assim as ações de
              proteção conforme necessário.
            </li>
          </ul>
        </p>

        <p>
          1.2. O acesso à plataforma somente será disponibilizado para os
          Usuários cadastrados.
        </p>
      </section>
      <section>
        <h6>2. DISPOSIÇÕES GERAIS DE CADASTRO</h6>
        <p>
          2.1. O acesso à plataforma exigirá a realização de um cadastro prévio
          do Usuário.
        </p>
        <p>
          2.2. O Usuário, ao prosseguir com o cadastro, declara e garante
          expressamente ser plenamente capaz, podendo exercer e usufruir
          livremente da Plataforma e dos Serviços.
        </p>
        <p>
          2.3. Em caso de menores de 18 (dezoito) anos, caberá aos seus pais,
          tutores ou representantes legais, conforme aplicável, a plena
          responsabilidade por suas atividades na Plataforma e a observância da
          integralidade destes Termos.
        </p>
        <p>
          2.4. Para o cadastro, o Usuário fornecerá algumas informações pessoais
          que serão tratadas em conformidade com nossa Política de Privacidade,
          sendo de sua exclusiva responsabilidade manter referidos dados
          atualizados, se responsabilizando por qualquer prejuízo decorrente da
          falsidade ou desatualização das suas informações pessoais. Você também
          se compromete a não compartilhar as informações pessoais com
          terceiros, sendo responsável pelo uso dessas informações por terceiros
          com os quais as informações tenham sido compartilhadas.
        </p>
        <p>
          2.5. Qualquer outro tipo de acesso, que não seja os elencados acima, é
          ilegítimo e não está condizente com esses Termos ou quaisquer tipos de
          Serviços oferecidos pela BrandMonitor.
        </p>
        <p>
          2.6. São considerados acessos ilegítimos aqueles que utilizam robôs,
          bots, automações ou outras tecnologias similares, além de acessos não
          autorizados via API.
        </p>
        <p>
          2.7. É proibida qualquer cessão, venda, aluguel ou qualquer outra
          forma de transferência ou alienação, onerosa ou não, do cadastro a
          qualquer terceiro. A BrandMonitor poderá bloquear, suspender ou
          cancelar o acesso do usuário à Plataforma caso haja cessão ou mera
          suspeita de cessão da sua conta a terceiros ou utilização indevida da
          Plataforma.
        </p>
      </section>
      <section>
        <h6>3. ACESSO E USO DO SITE</h6>
        <p>
          3.1. O Usuário poderá acessar a plataforma por meio de dispositivos
          conectados (tais como, mas não se restringindo a, computadores,
          smartphones, tablets, etc.) que permitam a visualização da sua
          interface.
        </p>
        <p>
          3.2. A Plataforma e os Serviços são oferecidos na forma em que se
          encontram, de modo que a BrandMonitor pode, a qualquer tempo e
          unilateralmente, deixar de fornecê-los, alterar as características,
          bem como criar restrições para o uso ou acesso.
        </p>
        <p>
          3.3. O Usuário se responsabiliza integralmente por todo e qualquer uso
          que faça das informações disponibilizadas na Plataforma e nos
          Serviços.
        </p>
        <p>
          3.4. A BrandMonitor poderá remover determinados conteúdos na
          Plataforma, incluindo informações e dados pessoais, de forma proativa
          ou em virtude de determinações judiciais e/ou administrativas.
        </p>
        <p>
          3.5. A BrandMonitor não produz, edita, modifica, altera ou mantém
          qualquer tipo de ingerência sobre o conteúdo reproduzido de diários
          oficiais e sistemas de órgãos, de modo que a Plataforma age como um
          mero localizador e indexador de conteúdo.
        </p>
        <p>
          3.6. A BrandMonitor não presta, em hipótese alguma, qualquer tipo de
          assessoria, consultoria jurídica e/ou qualquer outra atividade
          privativa de profissional advogado elencada no Art. 1º da Lei nº
          8.906/1994 (Estatuto da Advocacia e da Ordem dos Advogados do Brasil),
          de modo que toda a informação de cunho jurídico disponível na
          Plataforma não deve ser entendida, de forma alguma e em qualquer
          circunstância, como consultoria e/ou prestação de serviços jurídicos.
        </p>
      </section>
      <section>
        <h6>4. PROPRIEDADE INTELECTUAL</h6>
        <p>
          4.1. Sujeito a estes Termos de Uso, a BrandMonitor concede ao Usuário
          uma licença pessoal, limitada, não exclusiva, não transferível e não
          sublicenciável para acessar a Plataforma e usufruir dos Serviços. A
          autorização aqui concedida não permite a exploração comercial da
          Plataforma.
        </p>
        <p>
          4.2. Todos os direitos de propriedade intelectual relativos à
          Plataforma Branddi, bem como todas as suas funcionalidades, são de
          propriedade exclusiva da BrandMonitor, inclusive no que diz respeito
          aos seus textos, imagens, marcas, layouts, software, códigos, bases de
          dados e demais conteúdos produzidos direta ou indiretamente pela
          BrandMonitor.
        </p>
        <p>
          4.3. O conteúdo da Branddi é protegido pela legislação de direitos de
          propriedade intelectual, incluindo direitos autorais, direitos de
          propriedade industrial, dentre outros direitos. É proibido usar,
          copiar, reproduzir, modificar, traduzir, publicar, transmitir,
          distribuir, executar, fazer o upload, exibir, licenciar, vender,
          explorar, fazer scrapping e/ou engenharia reversa do conteúdo da
          Branddi, para qualquer finalidade, sem o consentimento prévio e
          expresso da BrandMonitor.
        </p>
        <p>
          4.4. A BrandMonitor reserva-se o direito, a seu exclusivo critério e a
          qualquer tempo, de alterar ou remover a Plataforma, total ou
          parcialmente, sem qualquer comunicação prévia ao Usuário e sem que lhe
          seja devida qualquer indenização, mas, em nenhum caso, os direitos do
          Usuário serão afetados.
        </p>
        <p>
          4.5. Qualquer desenvolvimento, arranjo, melhoria, atualização ou nova
          versão realizados na Plataforma Branddi, nas suas funcionalidades e/ou
          no Conteúdo da Branddi, ainda que por sugestão ou solicitação do
          Usuário, serão considerados de titularidade da BrandMonitor, podendo
          esta empregá-los livremente e, sem limitação, adaptá-los,
          aperfeiçoá-los, transformá-los, reproduzi-los, distribuí-los,
          comercializá-los, levá-los a registro, cedê-los e licenciá-los, a
          qualquer título e a seu exclusivo critério.
        </p>
      </section>
      <section>
        <h6>5. DIRETRIZES E LIMITAÇÕES DE RESPONSABILIDADE</h6>
        <p>
          5.1. A BrandMonitor não será responsabilizada: (i) por danos e
          prejuízos causados pelo acesso, interceptação, eliminação, alteração,
          modificação ou manipulação, por terceiros não autorizados, dos
          arquivos e comunicações transmitidos através da Plataforma e dos
          Serviços, tampouco decorrentes do uso dos Serviços ou da incapacidade
          dos Usuários em acessar a Plataforma; (ii) por danos ou prejuízos que
          eventualmente resultem do uso inadequado dos Serviços; (iii) por
          quaisquer danos ou prejuízos decorrentes de fraudes ou ações ilegais
          realizadas pelo Usuário ou terceiros na Plataforma.
        </p>
        <p>
          5.2. O Usuário: (i) é responsável pela utilização dos Serviços e das
          informações disponibilizadas na Plataforma, por qualquer conteúdo
          fornecido e por todas as consequências oriundas da sua utilização,
          incluindo o uso do seu conteúdo por outros Usuários e terceiros; (ii)
          indenizará a BrandMonitor, demais empresas do grupo econômico,
          diretores, administradores, colaboradores, representantes e empregados
          por quaisquer perdas, prejuízos, responsabilizações, reclamações ou
          demandas decorrentes da sua utilização da Plataforma e dos Serviços.
        </p>
        <p>
          5.3. É vedado ao Usuário realizar atividades que envolvam: a) alterar
          a configuração do seu dispositivo de acesso para utilização da
          Plataforma e dos Serviços; b) hackear ou interferir na operação da
          BrandMonitor e da Branddi; c) criar múltiplos usuários, pertencentes à
          mesma pessoa física ou jurídica, inclusive com a finalidade de
          solicitar pedidos de desidentificação de conteúdo; d) prejudicar a
          infraestrutura da Branddi ao realizar o acesso aos Serviços; e)
          comercializar ou compartilhar o acesso à Branddi; f) introduzir
          qualquer vírus, worm, spyware ou qualquer outro código computacional,
          arquivo ou programa que possa estar destinado a danificar a operação,
          hardware, software ou qualquer outro aspecto dos Serviços da
          BrandMonitor; g) criar spiders ou scrapers e utilizá-los na Plataforma
          Branddi; h) usar robôs e/ou outros meios automatizados de qualquer
          tipo para acessar as ferramentas e/ou serviços da Branddi; i) utilizar
          proxies ou quaisquer outras ferramentas similares para possibilitar
          acessos múltiplos à Plataforma de uma só vez, de maneira a prejudicar
          o provimento dos serviços da Branddi; j) realizar quaisquer
          procedimentos de engenharia reversa da Plataforma Branddi; l) utilizar
          a Plataforma para a obtenção de vantagem ilícita ou quaisquer outras
          condutas similares; m) utilizar a Plataforma para descumprimento de
          qualquer lei, norma e/ou regulamento.
        </p>
        <p>
          5.4. Além das demais hipóteses previstas nestes Termos de Uso, a
          BrandMonitor poderá suspender ou rescindir unilateralmente os Serviços
          e bloquear o acesso à Plataforma, sem aviso prévio, caso o Usuário não
          cumpra as obrigações pactuadas nestes Termos de Uso ou a legislação
          aplicável. Nessa hipótese, a BrandMonitor reserva-se o direito de
          recusar ou restringir o uso atual ou futuro da Plataforma e dos
          Serviços pelo Usuário.
        </p>
      </section>
      <section>
        <h6>6. SUSPENSÃO, BLOQUEIO E EXCLUSÃO</h6>
        <p>
          6.1. A fim de otimizar a disponibilização dos Serviços, a BrandMonitor
          poderá, inclusive por questões de segurança, restringir, limitar e
          realizar a suspensão ou bloqueios, totais ou parciais, de
          disponibilização e acesso à Plataforma.
        </p>
        <p>
          6.2. A BrandMonitor se reserva o direito de alterar quaisquer aspectos
          da Plataforma, descontinuar a disponibilidade ou distribuição dos
          Serviços, podendo suspender ou cancelar os mesmos, a seu exclusivo
          critério e a qualquer tempo, independente de aviso prévio.
        </p>
      </section>
      <section>
        <h6>7. DISPOSIÇÕES FINAIS</h6>
        <p>
          7.1. Estes Termos de Uso constituem a versão válida e eficaz dos seus
          termos e condições, regendo todas as relações entre o Usuário e a
          BrandMonitor, exceto nos casos em que o Usuário utilize serviços que
          possuam termos ou regras próprias, observados os direitos adquiridos,
          os atos jurídicos perfeitos e as coisas julgadas.
        </p>
        <p>
          7.2. A versão dos Termos de Uso vigente será sempre a mais recente.
          Para identificar a data da versão vigente, o Usuário deve verificar a
          seção “Modificado em”, no topo deste documento.
        </p>
        <p>
          7.3. A BrandMonitor se reserva o direito de atualizar e modificar
          periodicamente quaisquer de seus documentos jurídicos, incluindo estes
          Termos de Uso, sem aviso prévio ao Usuário.
        </p>
        <p>
          7.4. Qualquer novo recurso que aprimore a Plataforma e os Serviços
          atuais da BrandMonitor, como a disponibilização de novas ferramentas e
          recursos, estará sujeito a estes Termos.
        </p>
        <p>
          7.5. As cláusulas destes Termos deverão permanecer em vigor, mesmo
          após o término da relação existente entre o Usuário e a BrandMonitor
          por qualquer motivo, de modo a continuar a produzir efeitos enquanto
          houver relações jurídicas subsequentes entre o Usuário e a
          BrandMonitor. Caso qualquer disposição destes Termos seja considerada
          inválida ou inexequível, por qualquer motivo, o mesmo não ocorre em
          relação às disposições restantes.
        </p>
        <p>
          7.6. Existindo dúvidas sobre estes Termos de Uso ou sobre o que ele
          engloba, o Usuário poderá entrar em contato com a BrandMonitor através
          dos seus canais oficiais de comunicação.
        </p>
        <p>
          7.7. Estes Termos de Uso são regidos pelas leis da República
          Federativa do Brasil. Fica eleito, desde já, o foro da Comarca de SÃO
          PAULO, para dirimir eventuais controvérsias oriundas destes Termos de
          Uso, renunciando expressamente a qualquer outro, por mais privilegiado
          que seja.
        </p>
      </section>
    </div>
  );
};

export default TermsOfUse;
