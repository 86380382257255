export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const GET_USER_CAMPAIGNS = "GET_USER_CAMPAIGNS";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const SET_USER_CAMPAIGNS = "SET_USER_CAMPAIGNS";
export const SET_SELECTED_CAMPAIGN = "SET_SELECTED_CAMPAIGN";
export const SET_LOADING = "SET_LOADING";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const SET_ACCESS_FORBIDDEN = "SET_ACCESS_FORBIDDEN";
export const RESET_ACCESS_FORBIDDEN = "RESET_ACCESS_FORBIDDEN";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_EVENT = "SELECT_DOMAIN";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const UPDATE_2FA_SECRET = 'UPDATE_2FA_SECRET';
