import {
  FETCH_ANALYTICAL_KPIS_FAILURE,
  FETCH_ANALYTICAL_KPIS_REQUEST,
  FETCH_ANALYTICAL_KPIS_SUCCESS,
  FETCH_BRAND_SCORES_FAILURE,
  FETCH_BRAND_SCORES_REQUEST,
  FETCH_BRAND_SCORES_SUCCESS,
  FETCH_DAILY_SCORES_FAILURE,
  FETCH_DAILY_SCORES_REQUEST,
  FETCH_DAILY_SCORES_SUCCESS,
  FETCH_DAILY_TOTAL_AGRESSORS_FAILURE,
  FETCH_DAILY_TOTAL_AGRESSORS_REQUEST,
  FETCH_DAILY_TOTAL_AGRESSORS_SUCCESS,
  FETCH_OCCURRENCES_TREND_FAILURE,
  FETCH_OCCURRENCES_TREND_REQUEST,
  FETCH_OCCURRENCES_TREND_SUCCESS,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_FAILURE,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_SUCCESS,
  SET_ANALYTICAL_END_DATE,
  SET_ANALYTICAL_START_DATE,
} from "./actionsTypes";

const initialState = {
  dailyScoreData: {
    loading: false,
    error: null,
    data: [],
  },
  dailyTotalAggression: {
    loading: false,
    error: null,
    data: [],
  },
  brandScoresData: {
    loading: false,
    error: null,
    data: [],
  },
  analyticalKpisData: {
    loading: false,
    error: null,
    data: [],
  },
  offenderGroupByDomain: {
    loading: false,
    error: null,
    data: [],
  },
  occurrencesTrend: {
    loading: false,
    error: null,
    data: [],
  },
  startDate: null,
  endDate: null,
};

const AnalyticalReport = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICAL_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_ANALYTICAL_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case FETCH_DAILY_SCORES_REQUEST:
      return {
        ...state,
        dailyScoreData: {
          ...state.dailyScoreData,
          loading: true,
        },
      };
    case FETCH_DAILY_SCORES_SUCCESS:
      return {
        ...state,
        dailyScoreData: {
          ...state.dailyScoreData,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_DAILY_SCORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_DAILY_TOTAL_AGRESSORS_REQUEST:
      return {
        ...state,
        dailyTotalAggression: {
          ...state.dailyTotalAggression,
          loading: true,
        },
      };
    case FETCH_DAILY_TOTAL_AGRESSORS_SUCCESS:
      return {
        ...state,
        dailyTotalAggression: {
          ...state.dailyTotalAggression,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_DAILY_TOTAL_AGRESSORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ANALYTICAL_KPIS_REQUEST:
      return {
        ...state,
        analyticalKpisData: {
          ...state.analyticalKpis,
          loading: true,
        },
      };
    case FETCH_ANALYTICAL_KPIS_SUCCESS:
      return {
        ...state,
        analyticalKpisData: {
          ...state.analyticalKpis,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_ANALYTICAL_KPIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST:
      return {
        ...state,
        offenderGroupByDomain: {
          ...state.offenderGroupByDomain,
          loading: true,
        },
      };
    case FETCH_OFFENDERS_GROUP_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        offenderGroupByDomain: {
          ...state.offenderGroupByDomain,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_OFFENDERS_GROUP_BY_DOMAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_OCCURRENCES_TREND_REQUEST:
      return {
        ...state,
        occurrencesTrend: {
          ...state.occurrencesTrend,
          loading: true,
        },
      };
    case FETCH_OCCURRENCES_TREND_SUCCESS:
      return {
        ...state,
        occurrencesTrend: {
          ...state.occurrencesTrend,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_OCCURRENCES_TREND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_BRAND_SCORES_REQUEST:
      return {
        ...state,
        brandScoresData: {
          ...state.brandScoresData,
          loading: true,
        },
      };
    case FETCH_BRAND_SCORES_SUCCESS:
      return {
        ...state,
        brandScoresData: {
          ...state.brandScoresData,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_BRAND_SCORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AnalyticalReport;
