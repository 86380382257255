import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import Body from "../../../../DesignSystem/components/Text/Body";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import ButtonPrimary from "../../../../DesignSystem/components/Button/ButtonPrimary";
import { CalendarMonth } from "@mui/icons-material";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { changeAdsFormAdvertiserId, changeAdsFormAdvertiserName, changeAdsFormEvidenceFiles, changeAdsFormEvidenceLink, changeAdsFormId, changeAdsFormInstagramProfile, changeAdsFormIsActive, changeAdsFormPlatform, changeAdsFormSearchDate, changeAdsFormStartDate, changeAdsFormUrl } from "../../../../store/threats/actions";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import InputDate from "../../../../DesignSystem/components/Input/InputDate";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";

interface IProps {
    setTypeForm: any
}

export default function AdsForm({ setTypeForm }: IProps) {

    const dispatch = useDispatch()

    const { adsForm } = useSelector((store: IStore) => store.Threats.forms)

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex gap-3">
                <div className="d-flex flex-column gap-2" >
                    <Label>Tipo de ameaça*</Label>
                    <Select width={221} selected={ThreatTypeTextEnum.FAKE_AD} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                </div>
                <div className="d-flex flex-column gap-2 ">
                    <Label>URL do anúncio*</Label>
                    <Input width={458} required placeholder="Insira a URL" value={adsForm.url} onChange={(e: any) => dispatch(changeAdsFormUrl(e.target.value))} />
                </div>
            </div>

            <div className="mb-3 d-flex gap-3">
                <div className="d-flex flex-column gap-2 " >
                    <Label>Plataforma*</Label>
                    <Select width={221} required onChange={(e: any) => dispatch(changeAdsFormPlatform(e.target.value))} options={Object.values(ThreatPlatformEnum)} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label>Status*</Label>
                    <Select width={221} required onChange={(e: any) => dispatch(changeAdsFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                </div>
                <div className="d-flex flex-column gap-2" >
                    <Label>Veiculação iniciada em*</Label>
                    <div className="d-flex align-items-stretch  position-relative">
                        <InputDate width={221} value={adsForm.searchDate} onChange={date => dispatch(changeAdsFormSearchDate(date[0]))} />
                        <div className="position-absolute pe-none end-0 top-0">
                            <ButtonPrimary onClick={() => null} iconLeft={CalendarMonth} iconLeftColor="white" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-3 d-flex gap-3">
                <div className="d-flex flex-column gap-2 " >
                    <Label>ID do anúncio</Label>
                    <Input width={221} required placeholder="Insira o ID" value={adsForm.id} onChange={(e: any) => dispatch(changeAdsFormId(e.target.value))} />
                </div>
            </div>

            <Body style="medium">Informações do anunciante</Body>
            <div className="mb-3 d-flex gap-3 mt-3">
                <div className="d-flex flex-column gap-2 " >
                    <Label>Anunciante</Label>
                    <Input width={221} required placeholder="Insira o nome" value={adsForm.advertiserName} onChange={(e: any) => dispatch(changeAdsFormAdvertiserName(e.target.value))} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label>ID perfil anunciante</Label>
                    <Input width={221} required placeholder="Ex: 423342680239491" value={adsForm.advertiserId} onChange={(e: any) => dispatch(changeAdsFormAdvertiserId(e.target.value))} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label>Perfil instagram</Label>
                    <Input width={221} required placeholder="Ex: @wadewilson" value={adsForm.instagramProfile} onChange={(e: any) => dispatch(changeAdsFormInstagramProfile(e.target.value))} />
                </div>
            </div>
            <InputEvidence
                files={adsForm.evidence}
                onChangeLink={(e: any) => dispatch(changeAdsFormEvidenceLink(e.target.value))}
                linkValue={adsForm.evidenceLink}
                onDropFile={(e: any) => dispatch(changeAdsFormEvidenceFiles([...adsForm.evidence!, e[0]]))}
                onRemoveFile={(file: File) => dispatch(changeAdsFormEvidenceFiles(adsForm.evidence!.filter(el => el != file)))}
            />
        </form>
    )
}