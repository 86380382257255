// @ts-ignore
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { changeSiteFormEvidenceFiles, changeSiteFormEvidenceLink, changeSiteFormIsActive,  changeSiteFormUrl } from "../../../../store/threats/actions";
import { useRef } from "react";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";

interface IProps {
    setTypeForm: any
}

export default function SiteForm({ setTypeForm }: IProps) {

    const dispatch = useDispatch()

    const { siteForm } = useSelector((store: IStore) => store.Threats.forms)

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex flex-column gap-4">
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label>Tipo de ameaça*</Label>
                        <Select width={221} selected={ThreatTypeTextEnum.FAKE_SITE} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                        <Label>URL da ameaça*</Label>
                        <Input width={458} required placeholder="Insira a URL" value={siteForm.url} onChange={(e: any) => dispatch(changeSiteFormUrl(e.target.value))} />
                    </div>
                </div>
                <div className="d-flex gap-4">
                    <div className="d-flex flex-column gap-2 flex-grow-1" >
                        <Label>Status*</Label>
                        <Select width={221} required onChange={(e: any) => dispatch(changeSiteFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                    </div>
                    {/* <div className="d-flex flex-column gap-2" >
                        <Label>Data da busca</Label>
                        <div className="d-flex align-items-stretch  position-relative">
                            <Flatpickr
                                className="date-picker date-picker-left border cursor-pointer w-100"
                                options={{
                                    dateFormat: "d/m/Y",
                                    // @ts-ignore
                                    value: [siteForm.searchDate],
                                    defaultDate: siteForm.searchDate,
                                    maxDate: MAX_DATE,
                                }}
                                onChange={(date: Date[]) => dispatch(changeSiteFormSearchDate(date[0]))}
                                ref={flatpickr}
                            />
                            <div className="position-absolute pe-none end-0 top-0">
                                <ButtonPrimary onClick={() => null} iconLeft={CalendarMonth} iconLeftColor="white" />
                            </div>
                        </div>
                    </div> */}
                </div>
                <InputEvidence
                    files={siteForm.evidence}
                    onChangeLink={(e: any) => dispatch(changeSiteFormEvidenceLink(e.target.value))}
                    linkValue={siteForm.evidenceLink}
                    onDropFile={(e: any) => dispatch(changeSiteFormEvidenceFiles([...siteForm.evidence!, e[0]]))}
                    onRemoveFile={(file: File) => dispatch(changeSiteFormEvidenceFiles(siteForm.evidence!.filter(el => el != file)))}
                />
            </div>
        </form>
    )
}