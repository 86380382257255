import styled from "styled-components"
import { dsVariables } from "../../variables"

interface IProps {
    label: string
    options: any[]
    onChange: any
    value: string
}

export default function SelectFilterSection({ label, options, onChange , value}: IProps) {
    return (
        <div className="d-flex flex-column gap-2">
            <label className="mb-0">{label}</label>
            <Select onChange={onChange} name="" id="">
                {options.map((opt, index) => <option selected={opt == value} key={index} value={opt} >{opt}</option>)}
            </Select>
        </div>
    )
}

const Select = styled.select`
    width: 203px;
    height: ${dsVariables.spacing[36]};
    padding: ${dsVariables.spacing[8]} ${dsVariables.spacing[12]};
    gap: ${dsVariables.spacing[8]};
    border-radius: ${dsVariables.cornerRadius[4]};
    border: 1px solid ${dsVariables.colors.Gray[400]}
`