import {
  takeEvery,
  fork,
  put,
  all,
  call,
  take,
  takeLatest,
} from "redux-saga/effects";
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

import { postRegister } from "../../../helpers/backend_helper";
import { localStorageKeys } from "../../../config/localStorageKeys";
import { setUserLoggedIn, setUserProfile } from "../profile/actions";
import { loginSuccess } from "../login/actions";

function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(postRegister, user);
    localStorage.setItem(localStorageKeys.AUTH_USER, response.access_token);

    yield put(setUserProfile());
    yield take(setUserLoggedIn);

    yield put(registerUserSuccessful());
    yield put(loginSuccess());

    history("/panel");
  } catch (error) {
    yield put(registerUserFailed(error.data.message || error.message));
  }
}

export function* registerSaga() {
  yield takeLatest(REGISTER_USER, registerUser);
}

export default registerSaga;
