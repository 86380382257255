import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { logoutUser } from "../../store/actions";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import iconLogout from "../../assets/images/icons/logout.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import withRouter from "../../Components/Common/withRouter";

const Logout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(logoutUser());
    }, [dispatch]);


    document.title = t('page.authentication.logout.document-title');

    return (
        <React.Fragment>
            <ParticlesAuth>
                <Col lg={6}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className="p-lg-5 p-4 text-center">
                            <img src={iconLogout} alt="" height="70" />

                            <div className="mt-3 pt-2">
                                <h5>{t('page.authentication.logout.title')}</h5>
                                <p className="text-muted">{t('page.authentication.logout.subtitle')}</p>
                                <div className="mt-4">
                                    <Link to="/login" className="btn btn-secondary w-100">{t('page.authentication.logout.btn.sign-in')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </ParticlesAuth>
        </React.Fragment>
    );
};


export default withRouter(Logout);