import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ModalTermsAndPrivacy from "../Components/Common/ModalTermsAndPrivacy";

const LegalLink = ({ to, label }) => (
  <Link
    to={to}
    className="link-primary text-decoration-underline fs-12 my-1"
    style={{
      display: "inline-block",
    }}
  >
    {label}
  </Link>
);

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row
            className="justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Col>
              <Row className="justify-content-center align-items-center">
                <Col sm={12} className="text-center">
                  {new Date().getFullYear()} © BRANDMONITOR SERVICOS ONLINE LTDA
                </Col>
                <Row sm={12} className="justify-content-center">
                  <div className="footer-legal d-flex justify-content-center">
                    <LegalLink
                      to="/privacy-policy"
                      label="Políticas de Privacidade"
                    />
                    <span className="mx-2"></span>
                    <LegalLink to="/terms-of-use" label="Termos de Uso" />
                  </div>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
