import {
  FETCH_ANALYTICAL_KPIS_FAILURE,
  FETCH_ANALYTICAL_KPIS_REQUEST,
  FETCH_ANALYTICAL_KPIS_SUCCESS,
  FETCH_BRAND_SCORES_FAILURE,
  FETCH_BRAND_SCORES_REQUEST,
  FETCH_BRAND_SCORES_SUCCESS,
  FETCH_DAILY_SCORES_FAILURE,
  FETCH_DAILY_SCORES_REQUEST,
  FETCH_DAILY_SCORES_SUCCESS,
  FETCH_DAILY_TOTAL_AGRESSORS_FAILURE,
  FETCH_DAILY_TOTAL_AGRESSORS_REQUEST,
  FETCH_DAILY_TOTAL_AGRESSORS_SUCCESS,
  FETCH_OCCURRENCES_TREND_FAILURE,
  FETCH_OCCURRENCES_TREND_REQUEST,
  FETCH_OCCURRENCES_TREND_SUCCESS,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_FAILURE,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_SUCCESS,
  SET_ANALYTICAL_END_DATE,
  SET_ANALYTICAL_START_DATE,
} from "./actionsTypes";

export const setAnalyticalStartDate = (date) => {
  return {
    type: SET_ANALYTICAL_START_DATE,
    payload: date,
  };
};

export const setAnalyticalEndDate = (date) => {
  return {
    type: SET_ANALYTICAL_END_DATE,
    payload: date,
  };
};

export const fetchDailyScoresRequest = (campaignId, startDate, endDate) => {
  return {
    type: FETCH_DAILY_SCORES_REQUEST,
    payload: {
      campaignId,
      startDate,
      endDate,
    },
  };
};

export const fetchDailyScoresSuccess = (data) => {
  return {
    type: FETCH_DAILY_SCORES_SUCCESS,
    payload: data,
  };
};

export const fetchDailyScoresFailure = (error) => {
  return {
    type: FETCH_DAILY_SCORES_FAILURE,
    payload: error,
  };
};

export const fetchDailyTotalAggressorsRequest = (
  campaignId,
  startDate,
  endDate
) => {
  return {
    type: FETCH_DAILY_TOTAL_AGRESSORS_REQUEST,
    payload: {
      campaignId,
      startDate,
      endDate,
    },
  };
};

export const fetchDailyTotalAggressorsSuccess = (data) => {
  return {
    type: FETCH_DAILY_TOTAL_AGRESSORS_SUCCESS,
    payload: data,
  };
};

export const fetchDailyTotalAggressorsFailure = (error) => {
  return {
    type: FETCH_DAILY_TOTAL_AGRESSORS_FAILURE,
    payload: error,
  };
};

export const fetchAnalyticalKpisRequest = (
  campaignId,
  startDate,
  endDate,
  type,
  domain,
  pageFound,
  position,
  words
) => {
  return {
    type: FETCH_ANALYTICAL_KPIS_REQUEST,
    payload: {
      campaignId,
      startDate,
      endDate,
      type,
      domain,
      pageFound,
      position,
      words,
    },
  };
};

export const fetchAnalyticalKpisSuccess = (data) => {
  return {
    type: FETCH_ANALYTICAL_KPIS_SUCCESS,
    payload: data,
  };
};

export const fetchAnalyticalKpisFailure = (error) => {
  return {
    type: FETCH_ANALYTICAL_KPIS_FAILURE,
    payload: error,
  };
};

export const fetchOffendersGroupByDomainRequest = (
  campaignId,
  startDate,
  endDate,
  type,
  pageFound,
  position,
  words,
  keywordType
) => {
  return {
    type: FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST,
    payload: {
      campaignId,
      startDate,
      endDate,
      type,
      pageFound,
      position,
      words,
      keywordType,
    },
  };
};

export const fetchOffendersGroupByDomainSuccess = (data) => {
  return {
    type: FETCH_OFFENDERS_GROUP_BY_DOMAIN_SUCCESS,
    payload: data,
  };
};

export const fetchOffendersGroupByDomainFailure = (error) => {
  return {
    type: FETCH_OFFENDERS_GROUP_BY_DOMAIN_FAILURE,
    payload: error,
  };
};

export const fetchOccurrencesTrendRequest = (
  campaignId,
  startDate,
  endDate
) => {
  return {
    type: FETCH_OCCURRENCES_TREND_REQUEST,
    payload: {
      campaignId,
      startDate,
      endDate,
    },
  };
};

export const fetchOccurrencesTrendSuccess = (data) => {
  return {
    type: FETCH_OCCURRENCES_TREND_SUCCESS,
    payload: data,
  };
};

export const fetchOccurrencesTrendFailure = (error) => {
  return {
    type: FETCH_OCCURRENCES_TREND_FAILURE,
    payload: error,
  };
};

export const fetchBrandScoresRequest = (campaignId, startDate, endDate) => {
  return {
    type: FETCH_BRAND_SCORES_REQUEST,
    payload: {
      campaignId,
      startDate,
      endDate,
    },
  };
};

export const fetchBrandScoresSuccess = (data) => {
  return {
    type: FETCH_BRAND_SCORES_SUCCESS,
    payload: data,
  };
};

export const fetchBrandScoresFailure = (error) => {
  return {
    type: FETCH_BRAND_SCORES_FAILURE,
    payload: error,
  };
};
