import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loginError, socialLogin } from '../../store/actions';
import withRouter from '../../Components/Common/withRouter';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';


const GoogleOAuthSuccessRedirect = (props) => {

    let { accessToken} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {

        if (accessToken && accessToken !== "error") {
            dispatch(socialLogin( accessToken , props.router.navigate))
        } else {
            dispatch(loginError())
            navigate('/login');
        }
    }, [accessToken, dispatch, navigate, props.router.navigate])


    return (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
          <h1 className='mt-4 text-center'>
          <Spinner className='me-2 text-center'/>
          {t("global.redirecting")}
          </h1>
        </div>
    )
}

export default withRouter(GoogleOAuthSuccessRedirect)