import {
  GET_BRANDSCORE,
  GET_LINE_AGGRESSORS,
  GET_TOP_AGGRESSORS,
  GET_TOTAL_AGGRESSOR_ELIMINATED,
  GET_TOTAL_AGGRESSOR_FOUND,
  GET_TOTAL_AGGRESSOR_MEDIATION,
  GET_TOTAL_AGGRESSOR_NEW,
  GET_TOTAL_AGGRESSOR_NOTIFIED,
  GET_TOTAL_AGGRESSOR_REAPPEARED,
  GET_TOTAL_ELIMINATED,
  LINE_API_ERROR,
  REMOVE_PANEL_LOADING,
  RESET_PANEL,
  SET_BRANDSCORE,
  SET_BRANDSCORE_ERROR,
  SET_BRANDSCORE_LOADING,
  SET_COUNTER_SUCCESS,
  SET_LINE_AGGRESSORS_SUCCESS,
  SET_LINE_LOADING,
  SET_PANEL_CAMPAIGNID,
  SET_PANEL_LOADING,
  SET_TOP_AGGRESSORS_ERROR,
  SET_TOP_AGGRESSORS_LOADING,
  SET_TOP_AGGRESSORS_SUCCESS,
  SET_TOTAL_ELIMINATED,
  SET_TOTAL_ELIMINATED_ERROR,
  SET_TOTAL_ELIMINATED_LOADING,
  SET_WIDGET_LOADING,
  WIDGET_API_ERROR,
} from "./actionTypes";

export const setPanelCampaignId = (campaignId) => {
  return {
    type: SET_PANEL_CAMPAIGNID,
    payload: { campaignId },
  };
};

export const getAgressorsFound = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_AGGRESSOR_FOUND,
    payload: { campaignId, startDate, endDate },
  };
};

export const getAgressorsNew = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_AGGRESSOR_NEW,
    payload: { campaignId, startDate, endDate },
  };
};

export const getAgressorsNotified = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_AGGRESSOR_NOTIFIED,
    payload: { campaignId, startDate, endDate },
  };
};

export const getAgressorsMediation = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_AGGRESSOR_MEDIATION,
    payload: { campaignId, startDate, endDate },
  };
};

export const getAgressorsEliminated = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_AGGRESSOR_ELIMINATED,
    payload: { campaignId, startDate, endDate },
  };
};

export const getAgressorsReappeared = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_AGGRESSOR_REAPPEARED,
    payload: { campaignId, startDate, endDate },
  };
};

export const setWidgetLoading = (name) => {
  return {
    type: SET_WIDGET_LOADING,
    payload: { name },
  };
};

export const setPanelLoading = () => {
  return {
    type: SET_PANEL_LOADING,
  };
};

export const removePanelLoading = () => {
  return {
    type: REMOVE_PANEL_LOADING,
  };
};

export const setCounterSuccess = (name, value) => {
  return {
    type: SET_COUNTER_SUCCESS,
    payload: { name, value },
  };
};

export const widgetApiError = (name) => {
  return {
    type: WIDGET_API_ERROR,
    payload: { name },
  };
};

export const getLineAgressors = (
  campaignId,
  startDate,
  endDate,
  type,
  domain,
  pageFound,
  position,
  words
) => {
  return {
    type: GET_LINE_AGGRESSORS,
    payload: {
      campaignId,
      startDate,
      endDate,
      type,
      domain,
      pageFound,
      position,
      words,
    },
  };
};

export const setLineLoading = () => {
  return {
    type: SET_LINE_LOADING,
  };
};

export const setLineAgressorsSuccess = (line) => {
  return {
    type: SET_LINE_AGGRESSORS_SUCCESS,
    payload: { line },
  };
};

export const lineApiError = () => {
  return {
    type: LINE_API_ERROR,
  };
};

export const getTopAggressors = (
  campaignId,
  startDate,
  endDate,
  limit,
  page,
  type
) => {
  return {
    type: GET_TOP_AGGRESSORS,
    payload: { campaignId, startDate, endDate, limit, page, type },
  };
};

export const setTopAggressorsSuccess = (topAggressors) => {
  return {
    type: SET_TOP_AGGRESSORS_SUCCESS,
    payload: { topAggressors },
  };
};

export const setTopAggressorsLoading = () => {
  return {
    type: SET_TOP_AGGRESSORS_LOADING,
  };
};

export const setTopAggressorsError = () => {
  return {
    type: SET_TOP_AGGRESSORS_ERROR,
  };
};

export const getBrandScore = (campaignId) => {
  return {
    type: GET_BRANDSCORE,
    payload: { campaignId },
  };
};

export const setBrandScore = (brandScore) => {
  return {
    type: SET_BRANDSCORE,
    payload: { brandScore },
  };
};

export const setBrandScoreLoading = () => {
  return {
    type: SET_BRANDSCORE_LOADING,
  };
};

export const setBrandScoreError = () => {
  return {
    type: SET_BRANDSCORE_ERROR,
  };
};

export const getTotalEliminated = (campaignId, startDate, endDate) => {
  return {
    type: GET_TOTAL_ELIMINATED,
    payload: { campaignId, startDate, endDate },
  };
};

export const setTotalEliminated = (totalEliminated) => {
  return {
    type: SET_TOTAL_ELIMINATED,
    payload: { totalEliminated },
  };
};

export const setTotalEliminatedLoading = () => {
  return {
    type: SET_TOTAL_ELIMINATED_LOADING,
  };
};

export const setTotalEliminatedError = () => {
  return {
    type: SET_TOTAL_ELIMINATED_ERROR,
  };
};

export const resetPanel = () => {
  return {
    type: RESET_PANEL,
  };
};
