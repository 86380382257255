import React, { useState } from "react";
import { Row, Col, Input, Label, Card, CardBody, CardHeader } from "reactstrap";
import Select from "react-select";
import { CHANNEL_OPTIONS, ACTINGS_OPTIONS, PAGE, STATUS_OPTIONS } from "./optionsValues";

const selectStyle = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
  }),
};

const FiltersFields = ({
  handleSelectedChannelFilterChange,
  handleSelectedStatusFilterChange,
  selectedChannelFilter,
  selectedStatusFilter,
  selectedDomainFilter,
  handleSelectedDomainFilterChange,
  selectedCampaign,
  handleSelectedKeywordFilterChange,
  selectedKeywordFilter,
  handleSelectedPageFilterChange,
  selectedPageFilter,
  selectedPositionFilter,
  handleSelectedPositionFilterChange,
  positionsFilterOptions,
  panelLoading,
  totalAggressorsReport,
  hideStatusFilter,
  handleSelectedActingFilterChange,
  selectedActingFilter,
}) => {
// Atualiza o valor do input, sem acionar o filtro e add Timeout
const [searchTimeout, setSearchTimeout] = useState(null);
const [domainSearchValue, setDomainSearchValue] = useState(selectedDomainFilter);

const handleDomainFilterChange = (value) => {

  setDomainSearchValue(value);

  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  const timeout = setTimeout(() => {

    handleSelectedDomainFilterChange(value);
  }, 1000);

  setSearchTimeout(timeout);
};

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  function handleCleanFilter() {
    handleSelectedChannelFilterChange("");
    handleSelectedDomainFilterChange("");
    handleSelectedKeywordFilterChange("");
    handleSelectedPageFilterChange("");
    handleSelectedPositionFilterChange("");
    handleSelectedStatusFilterChange("");
  }

  return (
    <>
      <Row className="px-3 pt-3">
        <Row>
          <Col md={4} lg={4} className="mb-3">
            <Label className="mb-1 form-label">Busque pelo agressor</Label>
            <div className="form-icon">
              <Input
                type="text"
                className="form-control form-control-icon"
                id="searchAgressors"
                placeholder="Pesquise"
                value={domainSearchValue}
                onChange={(event) =>
                  handleDomainFilterChange(event.target.value)
                }
              />
              <i className="ri-search-line"></i>
            </div>
          </Col>

          <Col md={4} lg={3} className="mb-3">
            <Label className="mb-1 form-label">Tipo de canal</Label>
            <Select
              isLoading={panelLoading}
              options={removeDuplicates(
                CHANNEL_OPTIONS.concat(
                  totalAggressorsReport?.data
                    ?.map((report) =>
                      report?.channel?.map((channel) => ({
                        value: channel,
                        label: channel,
                      }))
                    )
                    .flat() || []
                ),
                "value"
              )}
              value={selectedChannelFilter}
              styles={selectStyle}
              onChange={(value) => handleSelectedChannelFilterChange(value)}
            />
          </Col>

          <Col md={4} lg={3} className="mb-3">
            <Label className="mb-1 form-label">Fase Atual</Label>
            <Select
              isLoading={panelLoading}
              options={STATUS_OPTIONS}
              value={selectedStatusFilter}
              styles={selectStyle}
              onChange={(value) => handleSelectedStatusFilterChange(value)}
            />
          </Col>

          <Col md={4} lg={2} className="mb-3">
            <Label className="mb-1 form-label">Notificar</Label>
            <Select
              isLoading={panelLoading}
              options={ACTINGS_OPTIONS}
              value={selectedActingFilter}
              styles={selectStyle}
              onChange={(value) => handleSelectedActingFilterChange(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="mb-3">
            <Label className="mb-1 form-label">Palavra-chave</Label>
            <Select
              isLoading={panelLoading}
              placeholder="Selecione"
              value={selectedKeywordFilter}
              isMulti={true}
              onChange={(value) => handleSelectedKeywordFilterChange(value)}
              options={selectedCampaign?.keywords?.map((keyword) => ({
                value: keyword,
                label: keyword,
              }))}
              styles={selectStyle}
            />
          </Col>

          <Col md={5} lg={4} className="mb-3">
            <Label className="mb-1 form-label">Página</Label>
            <Select
              isLoading={panelLoading}
              placeholder="Selecione"
              options={PAGE.concat(
                selectedCampaign?.pages?.map((page) => ({
                  value: page.toString(),
                  label: `Página ${page.toString()}`,
                }))
              )}
              value={selectedPageFilter}
              styles={selectStyle}
              onChange={(value) => handleSelectedPageFilterChange(value)}
            />
          </Col>

          <Col md={4} lg={4} className="mb-3">
            <Label className="mb-1 form-label">Posição</Label>
            <Select
              isLoading={panelLoading}
              placeholder="Selecione"
              isMulti={true}
              options={positionsFilterOptions}
              value={selectedPositionFilter}
              styles={selectStyle}
              onChange={(value) => handleSelectedPositionFilterChange(value)}
            />
          </Col>
        </Row>

        <div className="btn-report-filter">
          <button
            className="btn btn-primary"
            type="button"
            onClick={hideStatusFilter}
          >
            Pesquisar
          </button>
          <button
            className="btn btn-light"
            type="button"
            onClick={handleCleanFilter}
          >
            Limpar
          </button>
        </div>
      </Row>
    </>
  );
};

const ReportFilter = ({
  statusFilter,
  hideStatusFilter,
  selectedChannelFilter,
  handleSelectedChannelFilterChange,
  selectedDomainFilter,
  handleSelectedDomainFilterChange,
  selectedCampaign,
  handleSelectedKeywordFilterChange,
  selectedKeywordFilter,
  handleSelectedPageFilterChange,
  selectedPageFilter,
  handleSelectedPositionFilterChange,
  selectedPositionFilter,
  positionsFilterOptions,
  panelLoading,
  handleSelectedStatusFilterChange,
  selectedStatusFilter,
  pageSize,
  totalAggressorsReport,
  handleSelectedActingFilterChange,
  selectedActingFilter,
}) => {
  return (
    <>
      <div className="report-filter">
        <div className="report-filter__desktop">
          <FiltersFields
            handleSelectedChannelFilterChange={
              handleSelectedChannelFilterChange
            }
            totalAggressorsReport={totalAggressorsReport}
            pageSize={pageSize}
            handleSelectedStatusFilterChange={handleSelectedStatusFilterChange}
            selectedStatusFilter={selectedStatusFilter}
            selectedChannelFilter={selectedChannelFilter}
            selectedDomainFilter={selectedDomainFilter}
            handleSelectedDomainFilterChange={handleSelectedDomainFilterChange}
            selectedCampaign={selectedCampaign}
            handleSelectedKeywordFilterChange={
              handleSelectedKeywordFilterChange
            }
            selectedKeywordFilter={selectedKeywordFilter}
            handleSelectedPageFilterChange={handleSelectedPageFilterChange}
            selectedPageFilter={selectedPageFilter}
            handleSelectedPositionFilterChange={
              handleSelectedPositionFilterChange
            }
            selectedPositionFilter={selectedPositionFilter}
            positionsFilterOptions={positionsFilterOptions}
            handleSelectedActingFilterChange={handleSelectedActingFilterChange}
            selectedActingFilter={selectedActingFilter}
            panelLoading={panelLoading}
          />
        </div>

        <div className="report-filter__mobile">
          <div
            className={
              statusFilter
                ? "col-auto layout-rightside-col d-block w-100"
                : "col-auto layout-rightside-col d-none"
            }
            id="layout-rightside-coll"
          >
            <div className="layout-rightside w-100 me-0">
              <Card className="h-100 rounded-0 card-border-effect-none">
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0">Filtrar</h5>
                  <button
                    type="button"
                    className="p-0 btn"
                    onClick={hideStatusFilter}
                  >
                    <i className="bx bx-x fs-22"></i>
                  </button>
                </CardHeader>

                <CardBody className="p-0 overflow-hidden">
                  <FiltersFields
                    handleSelectedChannelFilterChange={
                      handleSelectedChannelFilterChange
                    }
                    totalAggressorsReport={totalAggressorsReport}
                    pageSize={pageSize}
                    handleSelectedStatusFilterChange={
                      handleSelectedStatusFilterChange
                    }
                    selectedStatusFilter={selectedStatusFilter}
                    selectedChannelFilter={selectedChannelFilter}
                    handleSelectedDomainFilterChange={
                      handleSelectedDomainFilterChange
                    }
                    selectedCampaign={selectedCampaign}
                    handleSelectedKeywordFilterChange={
                      handleSelectedKeywordFilterChange
                    }
                    selectedKeywordFilter={selectedKeywordFilter}
                    handleSelectedPageFilterChange={
                      handleSelectedPageFilterChange
                    }
                    selectedPageFilter={selectedPageFilter}
                    handleSelectedPositionFilterChange={
                      handleSelectedPositionFilterChange
                    }
                    selectedPositionFilter={selectedPositionFilter}
                    positionsFilterOptions={positionsFilterOptions}
                    panelLoading={panelLoading}
                    hideStatusFilter={hideStatusFilter}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportFilter;
