const colors = {
    "Gray": {
        "0": "#ffffff",
        "50": "#fcfcfc",
        "100": "#f7f7f7",
        "200": "#e6e6e6",
        "300": "#cccccc",
        "400": "#b3b3b3",
        "500": "#999999",
        "600": "#818181",
        "700": "#666666",
        "800": "#4d4d4d",
        "900": "#1f1f1f",
        "1000": "#000000"
    },
    "Cyan": {
        "0": "#effaff",
        "100": "#def3ff",
        "200": "#b6eaff",
        "300": "#75dbff",
        "400": "#29c9ff",
        "500": "#00aeef",
        "600": "#229bd4",
        "700": "#1b7cab",
        "800": "#17678d",
        "900": "#135474",
        "1000": "#0c364d"
    },
    "Red": {
        "0": "#fff0f0",
        "100": "#ffdddd",
        "200": "#ffc0c0",
        "300": "#ff9494",
        "400": "#ff2929",
        "500": "#ff2929",
        "600": "#ff2929",
        "700": "#d72222",
        "800": "#b11c1c",
        "900": "#921717",
        "1000": "#500d0d"
    },
    "Bg": {
        "ghostwhite": "#f3f3f9"
    },
    "Blue_raspberry": {
        "0": "#ECFFFF",
        "100": "#D0FCFD",
        "200": "#A6F8FB",
        "300": "#69F1F7",
        "400": "#25E0EB",
        "500": "#0ACFDE",
        "600": "#0B9BAF",
        "700": "#107C8E",
        "800": "#176473",
        "900": "#175362",
        "1000": "#093743"
    },
    "Blue": {
        "0": "#ebf3ff",
        "100": "#dde3f0",
        "200": "#a8c0f0",
        "300": "#7d9ef0",
        "400": "#516ee8",
        "500": "#485af0",
        "600": "#4849f0",
        "700": "#4848f0",
        "800": "#393bbd",
        "900": "#2c3194",
        "1000": "#191a54"
    },
    "Green": {
        "0": "#f0fdf6",
        "100": "#ddfbec",
        "200": "#bcf6da",
        "300": "#88edbe",
        "400": "#4ddb99",
        "500": "#28d384",
        "600": "#18a162",
        "700": "#177e4f",
        "800": "#176441",
        "900": "#155237",
        "1000": "#072d1d"
    },
    "Orange": {
        "0": "#fffcea",
        "100": "#fff5c5",
        "200": "#ffeb85",
        "300": "#f2cf43",
        "400": "#f2c027",
        "500": "#f2aa27",
        "600": "#e28c24",
        "700": "#bb641e",
        "800": "#984c18",
        "900": "#7c3b14",
        "1000": "#48210c"
    },
    "Yellow": {
        "0": "#fbffe7",
        "100": "#f4ffc1",
        "200": "#eeff86",
        "300": "#ecff41",
        "400": "#f3ff29",
        "500": "#ffff29",
        "600": "#d1c221",
        "700": "#a68f1b",
        "800": "#896f16",
        "900": "#745913",
        "1000": "#44320b"
    },
    "PastelBlue": {
        "0": "#eefaff",
        "100": "#dcf4ff",
        "200": "#b2ebff",
        "300": "#6dddff",
        "400": "#20cdff",
        "500": "#00b7ff",
        "600": "#0093df",
        "700": "#0074b4",
        "800": "#006395",
        "900": "#00517a",
        "1000": "#001d2e"
    },
    "DarkTurquoise": {
        "0": "#effbfc",
        "100": "#d7f4f6",
        "200": "#b4e8ed",
        "300": "#80d7e0",
        "400": "#4bbecd",
        "500": "#299fb1",
        "600": "#258195",
        "700": "#24687a",
        "800": "#255665",
        "900": "#234956",
        "1000": "#12303a"
    },
    "BlueRaspberry": {
        "0": "#ecffff",
        "100": "#d0fcfd",
        "200": "#a6f8fb",
        "300": "#69f1f7",
        "400": "#25e0eb",
        "500": "#0acfde",
        "600": "#0b9baf",
        "700": "#107c8e",
        "800": "#176473",
        "900": "#175362",
        "1000": "#093743"
    },
    "AzureRadiance": {
        "0": "#eff6ff",
        "100": "#dce9fd",
        "200": "#c0d9fd",
        "300": "#95c2fb",
        "400": "#64a1f6",
        "500": "#3577f1",
        "600": "#2a60e6",
        "700": "#214bd4",
        "800": "#213eac",
        "900": "#203888",
        "1000": "#182453"
    },
    "Chambray": {
        "0": "#f3f6fb",
        "100": "#e4ebf5",
        "200": "#ceddef",
        "300": "#adc6e3",
        "400": "#85a9d5",
        "500": "#688dc9",
        "600": "#5575bb",
        "700": "#4a64ab",
        "800": "#405189",
        "900": "#384770",
        "1000": "#262d45"
    },
    "BlueStone": {
        "0": "#eef9ff",
        "100": "#dcf2ff",
        "200": "#b2e8ff",
        "300": "#6dd7ff",
        "400": "#20c3ff",
        "500": "#00abff",
        "600": "#0089df",
        "700": "#006cb4",
        "800": "#005c95",
        "900": "#004b7a",
        "1000": "#001f35"
    },
    "Astronaut": {
        "0": "#f1f6fd",
        "100": "#e0ebf9",
        "200": "#c8dbf5",
        "300": "#a3c5ed",
        "400": "#77a5e3",
        "500": "#5787da",
        "600": "#426bce",
        "700": "#3959bc",
        "800": "#344999",
        "900": "#30437f",
        "1000": "#20294b"
    },
    "Manatee": {
        "0": "#f6f6f7",
        "100": "#eef0f1",
        "200": "#e0e2e5",
        "300": "#cccfd5",
        "400": "#b6bac3",
        "500": "#a2a7b2",
        "600": "#878a99",
        "700": "#797c89",
        "800": "#636570",
        "900": "#53555c",
        "1000": "#303036"
    },
    "Whitesmoke": {
        50: "#F3F6F9"
    },
    "Nitrogen": {
        50: "#F6F6F7"
    }
}

const primiteveNumbers = {
    "0": 0,
    "4": 4,
    "8": 8,
    "12": 12,
    "16": 16,
    "20": 20,
    "24": 24,
    "28": 28,
    "32": 32,
    "36": 36,
    "40": 40,
    "44": 44,
    "48": 48,
    "52": 52,
    "56": 56,
    "60": 60,
    "64": 64,
    "68": 68,
    "72": 72,
    "76": 76,
    "80": 80,
    "84": 84,
    "88": 88,
    "92": 92,
    "96": 96,
    "100": 100,
    "104": 104,
    "108": 108,
    "112": 112,
    "116": 116,
    "120": 120,
    "124": 124,
    "128": 128,
    "132": 132,
    "136": 136,
    "140": 140
}

const spacing = {
    "None": "0px",
    "4": "4px",
    "8": "8px",
    "12": "12px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "28": "28px",
    "32": "32px",
    "36": "36px",
    "40": "40px",
    "44": "44px",
    "48": "48px",
    "52": "52px",
    "56": "56px",
    "60": "60px",
    "64": "64px",
    "68": "68px",
    "72": "72px",
    "76": "76px",
    "80": "80px",
    "84": "84px",
    "88": "88px",
    "92": "92px",
    "96": "96px",
    "100": "100px"
}

const cornerRadius = {
    "4": "4px",
    "8": "8px",
    "16": "16px",
    "24": "24px",
    "32": "32px",
    "40": "40px",
    "48": "48px",
    "56": "56px",
    "64": "64px",
    "72": "72px",
    "80": "80px",
    "88": "88px",
    "96": "96px",
    "104": "104px",
    "112": "112px",
    "120": "120px",
    "128": "128px",
    "136": "136px",
    "None": 0
}

const shadows = {
    Center: {
        Blur4: `box-shadow: 0px 0px 4px 0px ${colors.Gray[200]};`,
        Blur16: `box-shadow: 0px 0px 16px 0px ${colors.Gray[200]};`,
    },
    Down: {
        Blur2: `box-shadow: 0px 1px 2px 0px ${colors.Gray[200]};`,
        Blur8: `box-shadow: 0px 8px 8px 0px ${colors.Gray[200]};`,
    },
    Inner: {
        Center: `box-shadow: inset 0px 0px 16px 0px ${colors.Gray[200]}`
    }
}

const blurVariables = {
    "0": 0,
    "4": 4,
    "8": 8,
    "12": 12,
    "16": 16,
    "20": 20,
    "24": 24,
    "28": 28,
    "32": 32
}

const fontFamilies = {
    Archivo: 'Archivo',
    Inter: 'Inter'
}

const texts = {
    h1: {
        medium: {
            fontSize: '60px',
            fontWeight: 500,
            lineHeight: '40px',
            fontFamily: fontFamilies.Archivo,
            
        },
        regular: {
            fontSize: '60px',
            fontWeight: 400,
            lineHeight: '40px',
            fontFamily: fontFamilies.Archivo,
            
        },
    },
    h2: {
        medium: {
            fontSize: '48px',
            fontWeight: 500,
            lineHeight: '36px',
            fontFamily: fontFamilies.Archivo,
            
        },
        regular: {
            fontSize: '48px',
            fontWeight: 400,
            lineHeight: '36px',
            fontFamily: fontFamilies.Archivo,
            
        },
    },
    h3: {
        medium: {
            fontSize: '36px',
            fontWeight: 500,
            lineHeight: '32px',
            fontFamily: fontFamilies.Archivo,
            
        },
        regular: {
            fontSize: '36px',
            fontWeight: 400,
            lineHeight: '32px',
            fontFamily: fontFamilies.Archivo,
        },
    },
    h4: {
        medium: {
            fontSize: '30px',
            fontWeight: 500,
            lineHeight: '28px',
            fontFamily: fontFamilies.Archivo,
            
        },
        regular: {
            fontSize: '30px',
            fontWeight: 400,
            lineHeight: '28px',
            fontFamily: fontFamilies.Archivo,
            
        },
    },
    sub5: {
        medium: {
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '24px',
            fontFamily: fontFamilies.Archivo,
        },
        regular: {
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '24px',
            fontFamily: fontFamilies.Archivo,
            color: '#555555',
        },

    },
    sub6: {
        medium: {

            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '20px',
            fontFamily: fontFamilies.Archivo,
        },
        regular: {
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '20px',
            fontFamily: fontFamilies.Archivo,
        },
    },
    body: {
        regular: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            fontFamily: fontFamilies.Inter,
            
        },
        medium: {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            fontFamily: fontFamilies.Inter,
        },
    },
    label: {
        medium: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            fontFamily: fontFamilies.Inter,
            
        },
        regular: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16px',
            fontFamily: fontFamilies.Inter,
            
        }
    },
    caption: {
        medium: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
            fontFamily: fontFamilies.Inter,
            
        },
        regular: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            fontFamily: fontFamilies.Inter,
            
        }
    },
};

export const dsVariables = {
    primiteveNumbers,
    colors,
    spacing,
    cornerRadius,
    blur: blurVariables,
    shadows,
    texts,
    fontFamilies
}