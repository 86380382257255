import { format } from "date-fns";
import * as moment from "moment-timezone";

import "moment/locale/pt-br";

export const formatDate = (dateString, formatString = "dd MMM, yyyy") => {
  const [day, month, year] = dateString.split("/");
  const formattedDate = `${year}-${month}-${day}`;

  return format(new Date(formattedDate), formatString);
};

export const formatDateWithMoment = (dateString) => {
  const formats = ["DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.SSS[Z]"];
  for (let format of formats) {
    if (moment(dateString, format, true).isValid()) {
      return moment(dateString, format).locale("pt-br").format("DD MMM, YYYY");
    }
  }
  return dateString?.toIsoString();
};

export const formatBarDate = (dateString) => {
  const formats = ["YYYY-MM", "YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ss.SSS[Z]"];
  for (let format of formats) {
    if (moment(dateString, format, true).isValid()) {
      if (format === "YYYY-MM") {
        return moment(dateString, format).locale("pt-br").format("MM/YYYY");
      } else {
        return moment(dateString, format).locale("pt-br").format("DD/MM/YYYY");
      }
    }
  }
  return dateString;
};
