import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";

const ValidateTwoFactor = ({ firstCheck = false, secretCode = false }) => {
  const navigate = useNavigate();

  const selectProfileState = (state) => state.Profile;
  const { user } = useSelector(selectProfileState);


  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);

  const api = new APIClient();

  const tokenRefs = useRef([]);

  const handleValidateToken = async () => {
    setLoading(true);
    const tokenCode = tokenRefs.current.map((input) => input.value).join("");

    const secretToken = secretCode ? secretCode : localStorage.getItem("token");

    try {
      const response = await api.create("2fa/verify", {
        secret: secretToken,
        token: tokenCode,
      });

      if (firstCheck && response.isValid) {
        await api.put("user/activate", {
          id: user._id,
          secret: secretToken,
        });
      }

      if (response.isValid) {
        localStorage.removeItem("token");
        setValidate(false);
        setVerify(true);
        if (!firstCheck) {
          navigate("/panel");
        }
      } else {
        setValidate(true);
        tokenRefs.current.forEach((input) => (input.value = ""));
        tokenRefs.current[0].focus();
      }




    } catch (error) {
      console.error("Error generating 2FA:", error);
    }
    setLoading(false);
  };

  const handleClean = () => {
    tokenRefs.current.forEach((input) => (input.value = ""));
    tokenRefs.current[0].focus();
    setValidate(false);
  };

  const moveToNext = (e, index) => {
    if (e.target.value.length >= 1 && index < tokenRefs.current.length - 1) {
      tokenRefs.current[index + 1].focus();
    }
  };


  return (
    <div className={`${firstCheck ? '' : 'modal2fa'}`}>
        <Container>
          <Row>
            {verify ? (
            <Col className="mb-0 w-100 card pr-0 d-flex card-body">
              {firstCheck ? (
                <div className = "validation-container alert alert-success"><strong>Token ativado com sucesso!</strong></div>
              ) : (
                <div className = "validation-container alert alert-success"><strong>Perfil validado com sucesso.<br />Você será redirecionado em instantes...</strong></div>
              )}
              </Col>
            ) : (
              <Col className="mb-0 w-100 card pr-0 d-flex card-body">
                <div className="validation-container">
                {firstCheck ? (
                <h2 className="mb-4">
                Ativar 2FA
                </h2>
              ) : (
                <h2 className="mb-4">
                Autenticação por 2 fatores
                </h2>
              )}

                    <p>Insira o token abaixo</p>
                  <div className="token-group">
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        ref={(el) => (tokenRefs.current[index] = el)}
                        className="form-control token-input"
                        maxLength="1"
                        onInput={(e) => moveToNext(e, index)}
                      />
                    ))}
                  </div>
                  <button
                    className="btn btn-primary"
                    disabled={loading}
                    onClick={handleValidateToken}
                  >
                    {loading ? "Validando" : "Validar Token"}
                  </button>{" "}
                  <button className="btn btn-danger" onClick={handleClean}>
                    Limpar
                  </button>
                  {validate && (
                    <p>
                      <hr />
                      Token inválido
                    </p>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
  );
};

export default ValidateTwoFactor;
