export function hasOneElementAtLeast(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    if (array2.includes(array1[i])) {
      return true;
    }
  }
  return false;
}

function convertStringToDate(dateString) {
  const [day, month, year] = dateString.split("/");
  return new Date(`${year}-${month}-${day}`);
}

export function orderArrayByScoreAndDomain(a, b) {
  const createdAtDateFirst = convertStringToDate(a.createdAt);
  const createdAtDateSecond = convertStringToDate(b.createdAt);

  const updatedAtDateFirst = convertStringToDate(a.updatedAt);
  const updatedAtDateSecond = convertStringToDate(b.updatedAt);

  if (a.aggressivenessIndex !== b.aggressivenessIndex) {
    return b.aggressivenessIndex - a.aggressivenessIndex;
  }

  if (createdAtDateFirst.getTime() !== createdAtDateSecond.getTime()) {
    return createdAtDateFirst.getTime() - createdAtDateSecond.getTime();
  }

  if (updatedAtDateFirst.getTime() !== updatedAtDateSecond.getTime()) {
    return updatedAtDateFirst.getTime() - updatedAtDateSecond.getTime();
  }

  return a.domain.localeCompare(b.domain);
}
