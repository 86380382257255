export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const PATCH_EVENT_MEDIATION_REQUEST = "PATCH_EVENT_MEDIATION_REQUEST";
export const PATCH_EVENT_MEDIATION_SUCCESS = "PATCH_EVENT_MEDIATION_SUCCESS";
export const PATCH_EVENT_MEDIATION_FAILURE = "PATCH_EVENT_MEDIATION_FAILURE";

export const PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST =
  "PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST";
export const PATCH_EVENT_EVIDENCE_UPLOAD_SUCCESS =
  "PATCH_EVENT_EVIDENCE_UPLOAD_SUCCESS";
export const PATCH_EVENT_EVIDENCE_UPLOAD_FAILURE =
  "PATCH_EVENT_EVIDENCE_UPLOAD_FAILURE";

export const GET_EVENT_ADS_REQUEST = "GET_EVENT_ADS_REQUEST";
export const GET_EVENT_ADS_SUCCESS = "GET_EVENT_ADS_SUCCESS";
export const GET_EVENT_ADS_FAILURE = "GET_EVENT_ADS_FAILURE";

export const DELETE_EVENT_EVIDENCE_REQUEST = "DELETE_EVENT_EVIDENCE_REQUEST";
export const DELETE_EVENT_EVIDENCE_SUCCESS = "DELETE_EVENT_EVIDENCE_SUCCESS";
export const DELETE_EVENT_EVIDENCE_FAILURE = "DELETE_EVENT_EVIDENCE_FAILURE";
