import {
  PROFILE_ERROR,
  RESET_PROFILE_FLAG,
  SET_SELECTED_COMPANY,
  SET_LOADING,
  SET_SELECTED_CAMPAIGN,
  GET_USER_CAMPAIGNS,
  SET_USER_CAMPAIGNS,
  SET_USER_LOGGED_IN,
  SET_USER_PROFILE,
  SET_EVENT,
  SET_PAGE_SIZE,
  SET_ACCESS_FORBIDDEN,
  RESET_ACCESS_FORBIDDEN,
  UPDATE_2FA_SECRET
} from "./actionTypes";

export const setUserLoggedIn = (user) => {
  return {
    type: SET_USER_LOGGED_IN,
    payload: user,
  };
};

export const update2FASecret = (secret) => {

  console.log("UPDATE_2FA_SECRET ", secret);

  return {
    type: UPDATE_2FA_SECRET,
    payload: secret,
  }
};


export const getUserCampaigns = (selectedCompany) => {
  return {
    type: GET_USER_CAMPAIGNS,
    payload: { selectedCompany },
  };
};

export const setUserProfile = () => {
  return {
    type: SET_USER_PROFILE,
  };
};

export const setSelectedCompany = (company) => {
  return {
    type: SET_SELECTED_COMPANY,
    payload: company,
  };
};

export const setUserCampaigns = (campaigns) => {
  return {
    type: SET_USER_CAMPAIGNS,
    payload: campaigns,
  };
};

export const setSelectedCampaign = (campaign) => {
  return {
    type: SET_SELECTED_CAMPAIGN,
    payload: campaign,
  };
};

export const profileError = () => {
  return {
    type: PROFILE_ERROR,
  };
};

export const resetProfileFlag = () => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const setAccessForbidden = () => {
  return {
    type: SET_ACCESS_FORBIDDEN,
  };
};

export const resetAccessForbidden = () => {
  return {
    type: RESET_ACCESS_FORBIDDEN,
  };
};

export const setEvent = (eventId) => ({
  type: SET_EVENT,
  payload: eventId,
});

export const setPageSizeTable = (pageSize) => ({
  type: SET_PAGE_SIZE,
  payload: pageSize,
});
