import ButtonPrimary from "./Button/ButtonPrimary"
import FraudCardImg from "../../assets/images/fraud-card-img.svg"
import styled from "styled-components"
import { dsVariables } from "../variables"
import Icon from "./Icon"
import { useLocation, useNavigate } from "react-router-dom"
import { InfoOutlined } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { IStore } from "../../interfaces/store.interface"
import { ThreatStateEnum } from "../../enums/threat-state.enum"
import Body from "./Text/Body"
import Caption from "./Text/Caption"
import H3 from "./Text/H3"

interface IProps {
    setShowModalTakedown: any
}

export default function FraudCard({ setShowModalTakedown }: IProps) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const tabActive = searchParams.get('tab')

    const { currentIds, threatsList } = useSelector((store: IStore) => store.Threats)

    function handleClick() {
        if (tabActive != '1') {
            searchParams.set('tab', '1');
            const newSearch = searchParams.toString();
            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        } else setShowModalTakedown(true)
    }

    return (
        <FraudCardStyled className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between gap-4">
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-1 align-items-center">
                        <Body color={dsVariables.colors.Blue[500]} style="medium">{threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length > 0
                            ? tabActive == '2'
                                ? 'Aprove takedowns em massa'
                                : <Body>Nenhuma ação pendente!</Body>
                            : tabActive == '2'
                                ? <Body>Pendências para aprovar em<br />"Aguardando aprovação</Body>
                                : <Body>Nenhuma ação pendente!</Body>
                        }</Body>
                        <Icon width={16} height={16} color={dsVariables.colors.Blue[500]} IconComponent={InfoOutlined} />
                    </div>
                    {(tabActive == '2' && threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length > 0) &&
                        <Caption color={dsVariables.colors.Astronaut[700]}>Nossos especialistas já pré-validaram as ameaças,<br />você só precisa revisar e aprovar.</Caption>
                    }

                    {(tabActive != '2' && threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length == 0 ||
                        tabActive == '2' && threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length == 0) &&
                        <Caption color={dsVariables.colors.Astronaut[700]}>Parabéns, até o momento, você não <br />tem Ameaças para aprovar takedown.</Caption>
                    }

                    {(tabActive != '2' && threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length > 0) &&
                        <H3 style="medium" color={dsVariables.colors.Astronaut[700]}>{threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length} ameaças</H3>
                    }
                </div>

                <img width={72} height={72} src={FraudCardImg} />
            </div>

            {
                threatsList.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length > 0 && tabActive == '2' ?
                    <ButtonPrimary state={currentIds.length > 0 ? "default" : "disabled"} label={`Aprovar selecionados (${Number(currentIds.length - 1 < 0 ? 0 : currentIds.length)})`} onClick={handleClick} />
                    : <div className="py-3"></div>
            }
        </FraudCardStyled>
    )
}

const FraudCardStyled = styled.div`
    border-radius: ${dsVariables.cornerRadius[4]};
    background: ${dsVariables.colors.Astronaut[0]};
    box-shadow: 0px 1px 2px 0px rgba(56, 65, 74, 0.15);
    padding: ${dsVariables.spacing[16]};
`