import { Container, Row } from "reactstrap";
import SectionTitle from "../../../DesignSystem/components/SectionTitle/SectionTitle";
import ButtonSecondary from "../../../DesignSystem/components/Button/ButtonSecondary";
import FilterSection from "../../../DesignSystem/components/FilterSection/FilterSection";
import { useCallback, useEffect, useState } from "react";
import FraudCard from "../../../DesignSystem/components/FraudCard";
import "./style.scss"
import Input from "../../../DesignSystem/components/Input/Input";
import Tabs, { ITabs } from "../../../DesignSystem/components/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { Tune, Search, WebAssetOff, Warning, AddRounded } from "@mui/icons-material";
import { dsVariables } from "../../../DesignSystem/variables";
import Modal from "../../../DesignSystem/components/Modal";
import { ThreatTypeEnum } from "../../../enums/threat-type.enum";
import { ThreatStateEnum } from "../../../enums/threat-state.enum";
import { ThreatOptionsCardEnum } from "../../../enums/threat-options-card.enum";
import ModalTakedown from "./Modals/ModalTakedown";
import ModalQuarantine from "./Modals/ModalQuarantine";
import ModalDiscard from "./Modals/ModalDiscard";
import TableThreats from "../../../DesignSystem/components/Table/TableThreats/Table";
import { IThreatsFilter } from "../../../interfaces/threats-filter.interface";
import { ThreatPlatformEnum } from "../../../enums/threat-platform.enum";
import ModalNewThreats from "./Modals/ModalNewThreats";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../interfaces/store.interface";
import { changeFilterPlatform, changeFilterStatus, changeFilterText, changeFilterType, changeThreatsCurrent, changeThreatsCurrentIds, changeThreatsList, changeThreatsTable, getFraudsByCompanyId, registerFraud, updateFraud } from "../../../store/threats/actions";
import { ThreatDaysEnum } from "../../../enums/threat-days.enum";
import Snackbar from "../../../DesignSystem/components/Snackbar";
import { IThreats } from "../../../interfaces/threats.interface";
import ModalResolved from "./Modals/ModalResolved";
import ModalApproval from "./Modals/ModalApproval";
import { ThreatStatusActiveEnum } from "../../../enums/threat-status-active.enum";
import Label from "../../../DesignSystem/components/Text/Label";

export default function ManageThreats() {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tabActive: number = Number(searchParams.get('tab'));

    const dispatch = useDispatch();

    const { threatsTableData, filter, currentIds, current, threatsList, selectedCompany } = useSelector((store: IStore) => ({
        threatsTableData: store.Threats.threatsTableData,
        filter: store.Threats.filter,
        currentIds: store.Threats.currentIds,
        current: store.Threats.current,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
    }))

    const [openFilter, setOpenFilter] = useState(false);
    const [showModalDetails, setShowModalDetails] = useState<boolean>(false);
    const [showModalTakedown, setShowModalTakedown] = useState<boolean>(false);
    const [showModalDiscard, setShowModalDiscard] = useState<boolean>(false);
    const [showModalQuarantine, setShowModalQuarantine] = useState<boolean>(false);
    const [showModalResolved, setShowModalResolved] = useState<boolean>(false);
    const [showModalApproval, setShowModalApproval] = useState<boolean>(false);
    const [showModalNewThreats, setShowModalNewThreats] = useState<boolean>(false);

    const [lastList, setLastList] = useState<IThreats[]>(threatsList!);

    const [showSnackQuarantine, setShowSnackQuarantine] = useState(false);
    const [showSnackDiscarded, setShowSnackDiscarded] = useState(false);
    const [showSnackTakedown, setShowSnackTakedown] = useState(false);
    const [showSnackNewThreat, setShowSnackNewThreat] = useState(false);
    const [showSnackResolved, setShowSnackResolved] = useState(false);
    const [showSnackApproval, setShowSnackApproval] = useState(false);

    const tabs: ITabs[] = [
        { label: "Novas", onClick: () => tabClick(0), iconRight: "badge", iconRightLabel: threatsList?.filter(el => el.currentState == ThreatStateEnum.NEW).length },
        { label: "Quarentena", onClick: () => tabClick(1), iconRight: "badge", iconRightLabel: threatsList?.filter(el => el.currentState == ThreatStateEnum.QUARANTINE).length },
        { label: "Aguardando aprovação", onClick: () => tabClick(2), iconLeft: 'branddi', iconRight: "badge", iconRightLabel: threatsList?.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length },
        { label: "Descartadas", onClick: () => tabClick(3), iconRight: "badge", iconRightLabel: threatsList?.filter(el => el.currentState == ThreatStateEnum.DISCARDED).length },
        { label: "Em tratativa", onClick: () => tabClick(4), iconRight: "badge", iconRightLabel: threatsList?.filter(el => el.currentState == ThreatStateEnum.UNDER_DISCUSSION).length },
        { label: "Resolvidas", onClick: () => tabClick(5), iconRight: "badge", iconRightLabel: threatsList?.filter(el => el.currentState == ThreatStateEnum.RESOLVED).length },
    ]

    function tabClick(index: number) {
        searchParams.set('tab', index.toString());
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function showDetails(id: string) {
        dispatch(changeThreatsCurrent(threatsList?.find(el => el._id == id)!))
        setShowModalDetails(true)
    }

    function showOptions(id: string, option: ThreatOptionsCardEnum) {
        dispatch(changeThreatsCurrent(threatsList?.find(el => el._id == id)!))
        switch (option) {
            case ThreatOptionsCardEnum.TAKEDOWN: return setShowModalTakedown(true)
            case ThreatOptionsCardEnum.DISCARD: return setShowModalDiscard(true)
            case ThreatOptionsCardEnum.QUARANTINE: return setShowModalQuarantine(true)
            case ThreatOptionsCardEnum.RESOLVED: return setShowModalResolved(true)
            case ThreatOptionsCardEnum.APPROVAL: return setShowModalApproval(true)
        }
    }

    function takedownThreat() {
        const updatedThreats = currentIds.length > 0
            ? threatsList?.map(el => {
                if (currentIds.find(current => current == el._id)) return { ...el, currentState: ThreatStateEnum.UNDER_DISCUSSION }

                return el
            })
            : threatsList?.map(el => {
                if (el._id == current?._id) return { ...el, currentState: ThreatStateEnum.UNDER_DISCUSSION }
                return el
            })

        dispatch(changeThreatsList(updatedThreats!))
        setShowModalTakedown(false)
        setShowModalDetails(false)
        dispatch(changeThreatsCurrentIds([]))
        tabClick(4)
    }

    function approvalThreat() {
        dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.AWAITING_APPROVAL }))
        closeModals()
        tabClick(2)
    }

    function discardThreat() {
        dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.DISCARDED }))
        closeModals()
        tabClick(3)
    }

    function quarantineThreat() {
        dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.QUARANTINE }))
        closeModals()
        tabClick(1)
    }

    function resolvedThreat() {
        dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.RESOLVED }))
        closeModals()
        tabClick(5)
    }

    function closeModals() {
        setShowModalApproval(false)
        setShowModalDiscard(false)
        setShowModalQuarantine(false)
        setShowModalResolved(false)
        setShowModalDetails(false)
        dispatch(changeThreatsCurrentIds([]))
    }

    function filterTableData(currentState: ThreatStateEnum, filter: IThreatsFilter) {
        const calculateDaysAgoDate = (days: number): Date => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            today.setDate(today.getDate() - days);
            return today;
        };

        const isWithinLastDays = (date: Date, daysEnum: ThreatDaysEnum): boolean => {
            switch (daysEnum) {
                case ThreatDaysEnum.DAYS_7: return date >= calculateDaysAgoDate(7);
                case ThreatDaysEnum.DAYS_14: return date >= calculateDaysAgoDate(14);
                case ThreatDaysEnum.DAYS_28: return date >= calculateDaysAgoDate(28);
                case ThreatDaysEnum.ALL_DAYS:
                default:
                    return true;
            }
        };

        return threatsList!.filter((threat) => {
            const matchesState = threat.currentState === currentState;
            const matchesText = filter.text
                ? threat.domain.toLowerCase().includes(filter.text.toLowerCase())
                : true;

            const matchesPlatform = filter.platform !== ThreatPlatformEnum.ALL
                ? threat.platform === filter.platform
                : true;
            const matchesType = filter.type !== ThreatTypeEnum.ALL
                ? threat.type === filter.type
                : true;

            const matchesDateRangeStartDate = filter.dateRange?.startDate
                ? new Date(threat.createdAt) >= filter.dateRange.startDate
                : true

            const matchesDateRangeEndDate = filter.dateRange?.endDate
                ? new Date(threat.createdAt) <= new Date(new Date(filter.dateRange.endDate).setDate(new Date(filter.dateRange.endDate).getDate() + 1))
                : true

            const matchesDays = (filter.days && filter.days !== ThreatDaysEnum.ALL_DAYS)
                ? isWithinLastDays(new Date(threat.createdAt), filter.days)
                : true;

            const matchesStatus = searchParams.get('status')
                ? filter.status == threat.isActive
                : true

            return matchesState && matchesText && matchesPlatform && matchesType && matchesDateRangeStartDate && matchesDateRangeEndDate && matchesDays && matchesStatus;
        }).map(el => ({ ...el, checked: false }));
    }

    const changeTable = useCallback(() => {
        if (!threatsList) return
        setLastList(threatsList)
        switch (tabActive) {
            case 0: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.NEW, filter)))
            case 1: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.QUARANTINE, filter)))
            case 2: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.AWAITING_APPROVAL, filter)))
            case 3: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.DISCARDED, filter)))
            case 4: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.UNDER_DISCUSSION, filter)))
            case 5: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.RESOLVED, filter)))
            default: return dispatch(changeThreatsTable(threatsTableData!))
        }
    }, [tabActive, filter, threatsList])

    function handleSnackbars() {
        if (!threatsList || threatsList.length == 0 || lastList.length == 0) return

        if (threatsList.length > lastList.length) setShowSnackNewThreat(true)
        else if (JSON.stringify(threatsList) != JSON.stringify(lastList)) {
            const [threatDifferent] = threatsList.filter(threat => !lastList.some(last => JSON.stringify(threat) == JSON.stringify(last)))

            const threatLastList = lastList.find(el => el._id == threatDifferent._id)!

            // @ts-ignore
            const attributeChanged = Object.entries(threatLastList).find(el => el[1] != threatDifferent[el[0]])[0]

            if (attributeChanged) {
                const currentState: ThreatStateEnum = threatDifferent['currentState']

                switch (currentState) {
                    case ThreatStateEnum.AWAITING_APPROVAL: return setShowSnackApproval(true)
                    case ThreatStateEnum.QUARANTINE: return setShowSnackQuarantine(true)
                    case ThreatStateEnum.UNDER_DISCUSSION: return setShowSnackTakedown(true)
                    case ThreatStateEnum.RESOLVED: return setShowSnackResolved(true)
                    case ThreatStateEnum.DISCARDED: return setShowSnackDiscarded(true)
                }
            }
        }
    }

    useEffect(() => {
        changeTable()
    }, [changeTable]);

    useEffect(() => {
        handleSnackbars()
    }, [threatsList]);

    useEffect(() => {
        selectedCompany?.value && dispatch(getFraudsByCompanyId(selectedCompany!.value))
    }, [selectedCompany]);


    useEffect(() => {
        if (!searchParams.get('type')) searchParams.set('type', filter.type);
        else dispatch(changeFilterType(searchParams.get('type') as ThreatTypeEnum))

        if (!searchParams.get('platform')) searchParams.set('platform', filter.platform);
        else dispatch(changeFilterPlatform(searchParams.get('platform') as ThreatPlatformEnum))

        if (!searchParams.get('status')) dispatch(changeFilterStatus(true))
        else dispatch(changeFilterStatus(searchParams.get('status') == ThreatStatusActiveEnum.ACTIVE))

        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, [location]);

    return (
        <div className="page-content">
            <Container fluid>

                <Row className="d-flex flex-column gap-4">
                    <SectionTitle title="Gerenciar Ameaças" />

                    <div className="d-flex flex-column box-content">
                        <div className="d-flex flex-wrap row-gap-4 justify-content-between align-items-end px-3">
                            <FraudCard setShowModalTakedown={setShowModalTakedown} />
                            <div className="d-flex gap-3 flex-wrap">
                                <Input width={276} value={filter.text} onChange={(e: any) => dispatch(changeFilterText(e.target.value))} iconLeft={Search} placeholder="Busque pela URL da ameaça" />
                                <div className="position-relative">
                                    <ButtonSecondary onClick={() => setOpenFilter(!openFilter)} type="normal" iconLeft={Tune} label="Filtros" iconLeftColor={dsVariables.colors.Astronaut[900]} />
                                    {openFilter && <FilterSection />}
                                </div>
                                <ButtonSecondary onClick={() => setShowModalNewThreats(!showModalNewThreats)} type="normal" iconLeft={AddRounded} label="Incluir ameaça" iconLeftColor={dsVariables.colors.Astronaut[900]} />
                            </div>
                        </div>

                        <div className="d-flex flex-column h-100">
                            <Tabs tabs={tabs} indexActive={tabActive} />

                            <TableThreats showDetails={showDetails} showOptions={showOptions} />
                        </div>
                    </div>
                </Row>
            </Container>

            <Modal
                title="Detalhes da ameaça" subtitle="deadpooltagarela.com.br" isOpen={showModalDetails} onClose={() => setShowModalDetails(false)}
                width={980}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: quarantineThreat, label: 'Quarentena', iconLeft: Warning, iconLeftColor: dsVariables.colors.Astronaut[900] },
                    { buttonStyle: 'primary', onClick: takedownThreat, label: 'Solicitar takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' },
                ]}
            >
                <p>Conteúdo temporário</p>
            </Modal>

            <ModalTakedown onConfirm={takedownThreat} isOpen={showModalTakedown} onClose={() => setShowModalTakedown(false)} />
            <ModalApproval onConfirm={approvalThreat} isOpen={showModalApproval} onClose={() => setShowModalApproval(false)} />
            <ModalDiscard onConfirm={discardThreat} isOpen={showModalDiscard} onClose={() => setShowModalDiscard(false)} />
            <ModalQuarantine onConfirm={quarantineThreat} isOpen={showModalQuarantine} onClose={() => setShowModalQuarantine(false)} />
            <ModalResolved onConfirm={resolvedThreat} isOpen={showModalResolved} onClose={() => setShowModalResolved(false)} />
            <ModalNewThreats isOpen={showModalNewThreats} onClose={() => setShowModalNewThreats(false)} />

            <Snackbar setVisible={setShowSnackNewThreat} visible={showSnackNewThreat} state="success">
                <Label style="medium">Ameaça criada com sucesso!</Label> - Foi incluída manualmente na tabela a ameaça {current?.domain}.
            </Snackbar>
            <Snackbar setVisible={setShowSnackTakedown} visible={showSnackTakedown} state="success">
                <Label style="medium">Takedown solicitado com sucesso!</Label> - Foi solicitado takedown para a ameaça {current?.domain}.
            </Snackbar>
            <Snackbar setVisible={setShowSnackDiscarded} visible={showSnackDiscarded} state="info">
                <Label style="medium">Descartado com sucesso!</Label> - A ameaça {current?.domain} foi descartada de sua lista.
            </Snackbar>
            <Snackbar setVisible={setShowSnackQuarantine} visible={showSnackQuarantine} state="info">
                <Label style="medium">Movido para Quarentena!</Label> - A ameaça {current?.domain} foi movida para "Quarentena".
            </Snackbar>
            <Snackbar setVisible={setShowSnackResolved} visible={showSnackResolved} state="info">
                <Label style="medium">Ameaça resolvida com sucesso!</Label> - A ameaça {current?.domain} foi movida para "Resolvida".
            </Snackbar>
            <Snackbar setVisible={setShowSnackApproval} visible={showSnackApproval} state="info">
                <Label style="medium">Aprovação solicitada com sucesso!</Label> - A ameaça {current?.domain} foi movida para "Aguardando aprovação".
            </Snackbar>
        </div>
    )
}