import Cell from "./Cell"
import Column from "./Column"
import styled from "styled-components"
import { dsVariables } from "../../../variables"
import { useSearchParams } from "react-router-dom"
import { InfoOutlined, MoreVertRounded } from "@mui/icons-material"
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum"
import EmptyState from "./EmptyState"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../../interfaces/store.interface"
import { changeThreatsCurrent, changeThreatsCurrentIds, changeThreatsTable } from "../../../../store/threats/actions"
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum"
import { getThreatTypeText } from "../../../utils/get-threat-type-text.util"

interface IProps {
    showDetails: any
    showOptions: any
}

export default function TableThreats({ showDetails, showOptions }: IProps) {

    const searchParams = useSearchParams()[0]
    const isTabOne = searchParams.get('tab') == '2'

    const { threatsTableData, currentIds, threatsList } = useSelector((store: IStore) => store.Threats)

    const dispatch = useDispatch()

    function handleCheckboxColumn(checked: boolean) {
        if (checked) {
            dispatch(changeThreatsCurrentIds(threatsTableData.map((el: any) => el._id)))
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => ({ ...threat, checked: true }))))
        }
        else {
            dispatch(changeThreatsCurrentIds([]))
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => ({ ...threat, checked: false }))))
        }
    }

    function handleCheckboxRow(checked: boolean, id: string) {
        if (checked) {
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => {
                if (threat._id == id) return { ...threat, checked: true }
                else return threat
            })))
            dispatch(changeThreatsCurrentIds([...currentIds, id]))
            dispatch(changeThreatsCurrent(threatsList.find(el => el._id == id)!))
        } else {
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => {
                if (threat._id == id) return { ...threat, checked: false }
                else return threat
            })))
            const updatedArray = currentIds.filter(el => el != id)

            dispatch(changeThreatsCurrentIds(updatedArray))
        }
    }

    if (threatsTableData?.length == 0) return <EmptyState text="Nenhuma ameaça listada" />

    return (
        <Div className="table-responsive h-100">
            <table className="table">
                <thead>
                    <TR>
                        {isTabOne && <Column showCheckbox={true} onChangeCheckbox={(checked: boolean) => handleCheckboxColumn(checked)} />}
                        <Column label="URL" />
                        <Column label="Identificado" />
                        <Column label="Última análise" />
                        <Column label="Plataforma" />
                        <Column label="Tipos de Ameaça" />
                        <Column label="Status" />
                        <Column />
                    </TR>
                </thead>
                <Tbody isTabOne={isTabOne}>
                    {threatsTableData?.map((threat, index) =>
                        <tr role="button" key={index}>
                            {isTabOne && <Cell checked={threat.checked} showCheckbox={true} onChangeCheckbox={(checked: boolean) => handleCheckboxRow(checked, threat._id!)} />}
                            <Cell label={threat.domain} />
                            <Cell label={new Date(threat.createdAt).toLocaleDateString('pt-BR')} />
                            <Cell label={new Date(threat.updatedAt).toLocaleDateString('pt-BR')} />
                            <Cell isTag label={threat.platform} />
                            <Cell label={getThreatTypeText(threat.type)} />
                            <Cell tagType={threat.isActive ? "success" : "negative"} isTag  label={threat.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} />
                            <Cell Icons={[
                                { Icon: InfoOutlined, color: dsVariables.colors.Astronaut[900], onClick: () => showDetails(threat._id), tooltipText: 'Ver detalhes' },
                                { Icon: MoreVertRounded, color: dsVariables.colors.Astronaut[900], onClick: (option: ThreatOptionsCardEnum) => showOptions(threat._id, option), tooltipText: 'Mais opções' }
                            ]} />
                        </tr>
                    )}
                </Tbody>
            </table>
        </Div>

    )
}

const Div = styled.div`
    &::-webkit-scrollbar {
        display:none;
    }
`

const TR = styled.tr`
    background: ${dsVariables.colors.Whitesmoke[50]};   
    font-family: ${dsVariables.fontFamilies.Inter};
    font-size:12px;
`

const Tbody = styled.tbody<{ isTabOne: boolean }>`
    tr {
        background: ${dsVariables.colors.Gray[50]};
        font-family: ${dsVariables.fontFamilies.Inter};

        &:hover {
            background-color: ${dsVariables.colors.Gray[100]};
        }

        td:first-child {
            color: ${dsVariables.colors.Blue[400]} !important;
            
            label {
                text-decoration: underline;
            }
        }

        td:nth-child(2) {
            ${props => props.isTabOne &&
        `   
                    color: ${dsVariables.colors.Blue[400]} !important;
                `
    }
            
            label {
                ${props => props.isTabOne &&
        `   
                    text-decoration: underline;
                `
    }
            }
        }

        td {
            color: ${dsVariables.colors.Gray[800]};
        }
    }
`