import { LINE_API_ERROR, REMOVE_PANEL_LOADING, RESET_PANEL, SET_BRANDSCORE, SET_BRANDSCORE_ERROR, SET_BRANDSCORE_LOADING, SET_COUNTER_SUCCESS, SET_LINE_AGGRESSORS_SUCCESS, SET_LINE_LOADING, SET_PANEL_CAMPAIGNID, SET_PANEL_LOADING, SET_TOP_AGGRESSORS_ERROR, SET_TOP_AGGRESSORS_LOADING, SET_TOP_AGGRESSORS_SUCCESS, SET_TOTAL_ELIMINATED, SET_TOTAL_ELIMINATED_ERROR, SET_TOTAL_ELIMINATED_LOADING, SET_WIDGET_LOADING, WIDGET_API_ERROR } from "./actionTypes";


const initialState = {
  panelCampaignId: null,
  widgets: {
    foundAgressors: { counter: null, loading: false, error: false },
    newAgressors: { counter: null, loading: false, error: false },
    reappearedAgressors: { counter: null, loading: false, error: false },
    notifiedAgressors: { counter: null, loading: false, error: false },
    inMediationAgressors: { counter: null, loading: false, error: false },
    eliminatedAgressors: { counter: null, loading: false, error: false },
  },
  line: {
    period: null,
    newDomainsCount: [],
    uniqueDomainsCount: [],
  },
  lineLoading: false,
  lineError: false,
  panelLoading: false,
  topAggressors: {
    total: null,
    domains: null,
    progressBar: null
  },
  topAggressorsLoading: false,
  topAggressorsError: false,
  brandScore: null,
  brandScoreLoading: false,
  brandScoreError: false,
  totalEliminated: null,
  totalEliminatedLoading: false,
  totalEliminatedError: false,
};

const panel = (state = initialState, action) => {
  switch (action.type) {

    case SET_PANEL_CAMPAIGNID: {
      state = {
        ...state,
        panelCampaignId: action.payload.campaignId
      }
      break;
    }

    case SET_PANEL_LOADING: {
      state = {
        ...state,
        panelLoading: true
      }
      break;
    }

    case REMOVE_PANEL_LOADING: {
      state = {
        ...state,
        panelLoading: false
      }
      break;
    }

    case SET_WIDGET_LOADING: {
      state = { ...state }
      const widgets = { ...state.widgets };
      widgets[action.payload.name].loading = true
      widgets[action.payload.name].error = false
      break;
    }

    case SET_COUNTER_SUCCESS: {
      state = { ...state }
      const widgets = { ...state.widgets };
      widgets[action.payload.name].counter = action.payload.value
      widgets[action.payload.name].loading = false
      widgets[action.payload.name].error = false
      break;
    }

    case WIDGET_API_ERROR: {
      state = { ...state }
      const widgets = { ...state.widgets };
      widgets[action.payload.name].error = true
      break;
    }

    case SET_LINE_AGGRESSORS_SUCCESS: {
      state = {
        ...state,
        ...action.payload,
        lineLoading: false,
        lineError: false,
        }
      break;
    }

    case SET_LINE_LOADING: {
      state = {
        ...state,
        lineLoading: true
        }
      break;
    }

    case LINE_API_ERROR: {
      state = {
        ...state,
        lineError: true
        }
      break;
    }

    case SET_TOP_AGGRESSORS_SUCCESS: {
      state = {
        ...state,
        ...action.payload,
        topAggressorsLoading: false,
        topAggressorsError: false,
        }
      break;
    }

    case SET_TOP_AGGRESSORS_LOADING: {
      state = {
        ...state,
        topAggressorsLoading: true
        }
      break;
    }

    case SET_TOP_AGGRESSORS_ERROR: {
      state = {
        ...state,
        topAggressorsError: true,
        topAggressorsLoading: false
        }
      break;
    }

    case SET_BRANDSCORE: {
      state = {
        ...state,
        ...action.payload,
        brandScoreLoading: false,
        brandScoreError: false,
        }
      break;
    }

    case SET_BRANDSCORE_LOADING: {
      state = {
        ...state,
        brandScoreLoading: true,
        }
      break;
    }

    case SET_BRANDSCORE_ERROR: {
      state = {
        ...state,
        brandScore: null,
        brandScoreLoading: false,
        brandScoreError: true
        }
      break;
    }

    case SET_TOTAL_ELIMINATED: {
      state = {
        ...state,
        ...action.payload,
        totalEliminatedLoading: false,
        totalEliminatedError: false,
        }
      break;
    }

    case SET_TOTAL_ELIMINATED_LOADING: {
      state = {
        ...state,
        totalEliminatedLoading: true,
        }
      break;
    }

    case SET_TOTAL_ELIMINATED_ERROR: {
      state = {
        ...state,
        brandScore: null,
        totalEliminatedLoading: false,
        totalEliminatedError: true
        }
      break;
    }

    case RESET_PANEL: {
      return state = {
        panelCampaignId: null,
        widgets: {
          foundAgressors: { counter: null, loading: false, error: false },
          newAgressors: { counter: null, loading: false, error: false },
          reappearedAgressors: { counter: null, loading: false, error: false },
          notifiedAgressors: { counter: null, loading: false, error: false },
          inMediationAgressors: { counter: null, loading: false, error: false },
          eliminatedAgressors: { counter: null, loading: false, error: false },
        },
        line: {
          period: null,
          newDomainsCount: [],
          uniqueDomainsCount: [],
        },
        lineLoading: false,
        lineError: false,
        panelLoading: false,
        topAggressors: {
          total: null,
          domains: null,
          progressBar: null
        },
        topAggressorsLoading: false,
        topAggressorsError: false,
        brandScore: null,
        brandScoreLoading: false,
        brandScoreError: false,
        totalEliminated: null,
        totalEliminatedLoading: false,
        totalEliminatedError: false,
      };
      }


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default panel;
