import { ThreatDaysEnum } from "../../enums/threat-days.enum"
import { ThreatPlatformEnum } from "../../enums/threat-platform.enum"
import { ThreatStatusActiveEnum } from "../../enums/threat-status-active.enum"
import { ThreatStateEnum } from "../../enums/threat-state.enum"
import { ThreatTypeEnum } from "../../enums/threat-type.enum"
import { IThreatForm } from "../../interfaces/threat-form.interface"
import { IThreatsFilter } from "../../interfaces/threats-filter.interface"
import { IThreatsTable } from "../../interfaces/threats-table.interface"
import { IThreats } from "../../interfaces/threats.interface"

export interface IThreatInitialState {
    id: string
    filter: IThreatsFilter
    threatsList: IThreats[]
    threatsTableData: IThreatsTable[]
    forms: IThreatForm
    currentIds: string[]
    current?: IThreats
}

export const ThreatInitialState: IThreatInitialState = {
    id: '',
    filter: {
        ipf: '',
        platform: ThreatPlatformEnum.ALL,
        text: '',
        type: ThreatTypeEnum.ALL,
        days: ThreatDaysEnum.ALL_DAYS,
        status: true
    },
    threatsList: [],
    threatsTableData: [],
    forms: {
        adsForm: {
            type: ThreatTypeEnum.FAKE_AD,
            url: '',
            id: '',
            searchDate: new Date(),
            startDate: new Date(),
            status: ThreatStatusActiveEnum.ACTIVE,
            platform: ThreatPlatformEnum.ALL,
            advertiserName: '',
            advertiserId: '',
            instagramProfile: '',
            evidence: [],
            evidenceLink: '',
            isActive: true
        },
        profileForm: {
            type: ThreatTypeEnum.FAKE_PROFILE,
            url: '',
            searchDate: new Date(),
            status: ThreatStatusActiveEnum.ACTIVE,
            platform: ThreatPlatformEnum.ALL,
            profile: '',
            evidence: [],
            evidenceLink: '',
            isActive: true
        },
        contentForm: {
            type: ThreatTypeEnum.FAKE_PROFILE,
            url: '',
            status: ThreatStatusActiveEnum.ACTIVE,
            platform: ThreatPlatformEnum.ALL,
            profile: '',
            evidence: [],
            evidenceLink: '',
            isActive: true
        },
        siteForm: {
            type: ThreatTypeEnum.FAKE_SITE,
            url: '',
            searchDate: new Date(),
            status: ThreatStatusActiveEnum.ACTIVE,
            evidence: [],
            evidenceLink: '',
            isActive: true
        }
    },
    currentIds: []
}