import { useEffect, useState } from "react"
import { styled } from "styled-components"
import { dsVariables } from "../variables"
import { useLocation } from "react-router-dom"
import Caption from "./Text/Caption"
import { SvgIconComponent } from "@mui/icons-material"
import Icon from "./Icon"
import BranddiIcon from "../assets/icons/brand-icon.svg"
export interface ITabs {
    label?: string
    iconLeft?: SvgIconComponent | 'branddi'
    iconRight?: SvgIconComponent | 'badge'
    iconRightLabel?: string | number
    mode?: 'light' | 'dark'
    onClick: any
}

interface IProps {
    tabs: ITabs[]
    indexActive?: number
}

export default function Tabs({ tabs, indexActive }: IProps) {
    const [activeTab, setActiveTab] = useState(indexActive);

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    useEffect(() => {
        const tabIndex = Number(searchParams.get('tab'))
        if (tabIndex != activeTab) setActiveTab(tabIndex)
    }, [location.search]);

    function handleClick(onClick: any, index: number) {
        onClick()
        setActiveTab(index)
    }

    return (
        <Div className="d-flex overflow-scroll pb-1">
            {tabs.map((tab, index) =>
                <TabButton onClick={_ => handleClick(tab.onClick, index)} key={index} className="bg-transparent border-0 d-flex gap-2 align-items-center" isActive={activeTab == index} >
                    {tab.iconLeft ? tab.iconLeft != 'branddi'
                        ? <Icon IconComponent={tab.iconLeft} />
                        : <img src={BranddiIcon} width={16} height={16} alt="Branddi Icon" />
                        : null
                    }
                    {tab.label && <span>{tab.label}</span>}
                    {
                        tab.iconRight ? tab.iconRight != "badge"
                            ? <Icon IconComponent={tab.iconRight} />
                            : <Badge className="rounded-pill position-relative d-flex align-items-center justify-content-center">
                                <div className="position-absolute">
                                    <Caption >{tab.iconRightLabel}</Caption>
                                </div>
                            </Badge>
                            : null
                    }
                </TabButton>
            )}
        </Div>
    )
}

const Div = styled.div`
    &::-webkit-scrollbar {
        display:none;
    }
`

const TabButton = styled.button<{ isActive: boolean }>`
    height: ${dsVariables.spacing[40]};
    padding: ${dsVariables.spacing[8]} ${dsVariables.spacing[16]};
    gap: ${dsVariables.spacing[4]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px 0px 0px 0px;
    font-family: Inter;

    ${({ isActive }) =>
        isActive &&
        `
        color: ${dsVariables.colors.Astronaut[900]};
        line-height: 120%;
        letter-spacing: 0.336px;
        position: relative;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: ${dsVariables.colors.Astronaut[900]};
            bottom: 0;
            left: 0;
            position: absolute;
        }
  `}
`

const Badge = styled.div`
    background-color: ${dsVariables.colors.Gray[200]};
    padding: ${dsVariables.spacing[12]}
`