import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes";

export const userForgetPassword = (user) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user },
  };
};

export const userForgetPasswordSuccess = () => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
  };
};

export const userForgetPasswordError = () => {
  return {
    type: FORGET_PASSWORD_ERROR,
  };
};
