import { Container } from "reactstrap";

export default function FraudPanel() {

    document.title = "Branddi.com - Painel de Fraude";
    document.description = "Combata o uso indevido da sua marca e maximize seus resultados";

    return (
        <div className="page-content">
            <Container fluid>
                <h1>Painel de Fraude</h1>
            </Container>
        </div>
    )
}