import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import logoSm from "../../../assets/images/logo-branddi-sm.svg";
import { max } from "date-fns";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";

const AnalyticalReportBranddiScore = ({ brandScoresData }) => {
  var chartColor = getChartColorsArray('["--vz-primary"]');

  const outputBrandScore = brandScoresData
    ? brandScoresData.data.reduce((acc, obj) => {
        const date = new Date(obj.processedDate).toDateString();
        if (!acc[date]) {
          acc[date] = {
            x: new Date(obj.processedDate),
            y: obj.avgBrandScore,
          };
        }
        return acc;
      }, {})
    : [];

  const result = Object.values(outputBrandScore);

  const series = [
    {
      name: "BranddiScore",
      data: result,
    },
  ];

  const highestPoint = result.reduce(
    (max, obj) => (max.y > obj.y ? max : obj),
    result[0]
  );

  var options = {
    chart: {
      height: 350,
      type: "line",
      id: "areachart-2",
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      decimalsInFloat: 0,
    },
    annotations: {
      yaxis: [
        {
          y: 62,
          borderColor: "#f7cc53",
          label: {
            borderColor: "#f7cc53",
            style: {
              fontSize: "10px",
              color: "#000",
              background: "#f7cc53",
            },
            text: "Média de outros clientes",
          },
        },
      ],
      points:
        highestPoint?.y >= 90
          ? [
              {
                x: highestPoint?.x.getTime(),
                y: highestPoint?.y,
                marker: {
                  size: 0,
                },
                image: {
                  path: logoSm,
                  width: 40,
                  height: 40,
                },
              },
            ]
          : [],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: chartColor,
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    xaxis: {
      type: "datetime",
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title">Branddi Score</h4>
          </div>
        </CardHeader>

        <CardBody className="mt-3">
          <div className="chart-container">
            <div className="chart-container__content w-100">
              {brandScoresData?.loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "30vh" }}
                >
                  <BouncingDotsLoader size={"24px"} />
                </div>
              ) : (
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={series}
                  height={350}
                  className="apex-charts"
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AnalyticalReportBranddiScore;
