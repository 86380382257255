import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationPtBr from "./locales/pt-br.json";
import translationEN from "./locales/en.json";


// the translations
const resources = {
  pt: {
    translation: translationPtBr,
  },
  en: {
    translation: translationEN,
  },
};

const language = localStorage.getItem("I18N_LANGUAGE");

if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "pt");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "pt",
    fallbackLng: "pt", // use pt if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
