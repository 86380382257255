import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import Select from "react-select";

const CHANNEL_OPTIONS = [{ value: "", label: "Todos" }];
const PAGE = [{ value: "", label: "Todas" }];
const POSITION = [];
const DOMAIN_OPTIONS = [{ value: "", label: "Todos" }];

const selectStyle = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
  }),
};

const AnalyticalReportFilter = ({
  setSelectedChannelFilter,
  selectedChannelFilter,
  selectedPageFilter,
  selectedDomainFilter,
  setSelectedDomainFilter,
  selectedKeywordFilter,
  setSelectedKeywordFilter,
  selectedPositionFilter,
  setSelectedPageFilter,
  setSelectedPositionFilter,
  selectedCampaign,
  dailyScoreData,
}) => {
  const [positions, setPositions] = useState([]);
  const [domains, setDomains] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Estado para armazenar dados filtrados

  useEffect(() => {
    let positions = [];
    let domains = new Set(); // Usando Set para armazenar domínios únicos
    dailyScoreData?.data.forEach((item) => {
      positions = [
        ...positions,
        ...(item.firstPage || []),
        ...(item.secondPage || []),
      ];

      if (item.domain) {
        domains.add(item.domain); // Adicionando o domínio ao Set
      }
    });
    setPositions(positions);
    setDomains([...domains]); // Convertendo o Set de volta para um array
  }, [dailyScoreData]);

  const removeDuplicates = useCallback((originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }, []);

  const isAnyFilterActive = useCallback(() => {
    return (
      selectedChannelFilter.value !== "" ||
      selectedDomainFilter !== "" ||
      selectedKeywordFilter.length > 0 ||
      selectedPageFilter.value !== "" ||
      selectedPositionFilter.length > 0
    );
  }, [
    selectedChannelFilter,
    selectedDomainFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
  ]);

  const clearFilters = useCallback(() => {
    setSelectedChannelFilter({ value: "", label: "Todos" });
    setSelectedDomainFilter("");
    setSelectedKeywordFilter([]);
    setSelectedPageFilter({ value: "", label: "Todas" });
    setSelectedPositionFilter([]);
  }, [
    setSelectedChannelFilter,
    setSelectedDomainFilter,
    setSelectedKeywordFilter,
    setSelectedPageFilter,
    setSelectedPositionFilter,
  ]);

  useEffect(() => {
    clearFilters();
  }, [selectedCampaign.value, clearFilters]);

  const handleFilterChange = (filterType, value) => {
    switch (filterType) {
      case "channel":
        setSelectedChannelFilter(value);
        break;
      case "domain":
        setSelectedDomainFilter(value);
        break;
      case "keyword":
        setSelectedKeywordFilter(value);
        break;
      case "page":
        setSelectedPageFilter(value);
        break;
      case "position":
        setSelectedPositionFilter(value);
        break;
      default:
        break;
    }
  };

  // useEffect para aplicar filtros sempre que um dos filtros ou os dados mudarem
  useEffect(() => {
    const applyFilters = () => {
      let data = dailyScoreData?.data || []; // Obtém os dados
      // console.log("Initial data:", data); // Log dos dados iniciais

      // Filtra por canal
      if (selectedChannelFilter.value) {
        data = data.filter((item) =>
          item.channels.includes(selectedChannelFilter.value)
        );
        // console.log("After channel filter:", data); // Log após filtrar por canal
      }

      // Filtra por domínio
      if (selectedDomainFilter.value) {
        data = data.filter(
          (item) => item.domain === selectedDomainFilter.value
        );
        // console.log("After domain filter:", data); // Log após filtrar por domínio
      }

      // Filtra por palavra-chave
      if (selectedKeywordFilter.length > 0) {
        data = data.filter((item) =>
          selectedKeywordFilter.some((keyword) =>
            item.keywords.includes(keyword)
          )
        );
        // console.log("After keyword filter:", data); // Log após filtrar por palavra-chave
      }

      // Filtra por página
      if (selectedPageFilter.value) {
        data = data.filter((item) => {
          return (
            item.firstPage?.includes(Number(selectedPageFilter.value)) ||
            item.secondPage?.includes(Number(selectedPageFilter.value))
          );
        });
        // console.log("After page filter:", data); // Log após filtrar por página
      }

      // Filtra por posição
      if (selectedPositionFilter.length > 0) {
        data = data.filter((item) => {
          return (
            item.firstPage.some((pos) =>
              selectedPositionFilter.includes(pos)
            ) ||
            item.secondPage.some((pos) => selectedPositionFilter.includes(pos))
          );
        });
        // console.log("After position filter:", data); // Log após filtrar por posição
      }

      setFilteredData(data); // Atualiza o estado com os dados filtrados
    };

    applyFilters();
  }, [
    selectedChannelFilter,
    selectedDomainFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
    dailyScoreData,
  ]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title">Filtros</h4>
            {isAnyFilterActive() && (
              <button
                type="button"
                className="btn btn-link btn-sm waves-effect text-decoration-underline"
                onClick={clearFilters}
              >
                Limpar Filtros
              </button>
            )}
          </div>
        </CardHeader>

        <CardBody>
          <Row>
            <Col md={3} className="mb-3">
              <Label className="mb-1 form-label">Busque pelo agressor</Label>

              <Select
                styles={selectStyle}
                id="searchAgressors"
                placeholder="Pesquise"
                isDisabled={false}
                isLoading={false}
                options={DOMAIN_OPTIONS.concat(
                  domains.map((domain) => ({
                    value: domain,
                    label: domain,
                  }))
                )}
                value={selectedDomainFilter}
                onChange={(selectedOption) =>
                  handleFilterChange("domain", selectedOption)
                }
              />
            </Col>

            <Col md={2} className="mb-3">
              <Label className="mb-1 form-label">Tipo de canal</Label>
              <Select
                placeholder="Selecione"
                styles={selectStyle}
                value={
                  selectedChannelFilter.value ? selectedChannelFilter : null
                }
                onChange={(e) => {
                  handleFilterChange("channel", e);
                }}
                options={removeDuplicates(
                  CHANNEL_OPTIONS.concat(
                    dailyScoreData?.data
                      ?.map((report) =>
                        report?.channels?.map((channel) => ({
                          value: channel,
                          label:
                            channel.charAt(0).toUpperCase() + channel.slice(1),
                        }))
                      )
                      .flat() || []
                  ),
                  "value"
                )}
              />
            </Col>

            <Col md={3} className="mb-3">
              <Label className="mb-1 form-label">Palavra-chave</Label>
              <Select
                placeholder="Selecione"
                value={selectedKeywordFilter.map((keyword) => ({
                  value: keyword,
                  label: keyword,
                }))}
                isMulti={true}
                options={selectedCampaign?.keywords?.map((keyword) => ({
                  value: keyword,
                  label: keyword,
                }))}
                onChange={(e) => {
                  const selectedValues = e
                    ? e.map((option) => option.value)
                    : [];
                  handleFilterChange("keyword", selectedValues);
                }}
                styles={selectStyle}
              />
            </Col>

            <Col md={2} className="mb-3">
              <Label className="mb-1 form-label">Página</Label>
              <Select
                placeholder="Selecione"
                styles={selectStyle}
                options={PAGE.concat(
                  selectedCampaign?.pages
                    ? selectedCampaign.pages?.map((page) => ({
                        value: page.toString(),
                        label: `Página ${page.toString()}`,
                      }))
                    : []
                )}
                value={selectedPageFilter.value ? selectedPageFilter : null}
                onChange={(e) => {
                  handleFilterChange("page", e);
                }}
              />
            </Col>

            <Col md={2} className="mb-3">
              <Label className="mb-1 form-label">Posição</Label>
              <Select
                placeholder="Selecione"
                isMulti={true}
                value={selectedPositionFilter.map((position) => ({
                  value: position,
                  label: "Posição " + position,
                }))}
                options={removeDuplicates(
                  POSITION.concat(
                    positions.map((position) => ({
                      value: position,
                      label: "Posição " + position,
                    }))
                  ),
                  "value"
                )}
                onChange={(e) => {
                  const selectedValues = e
                    ? e.map((option) => Number(option.value))
                    : [];
                  handleFilterChange("position", selectedValues);
                }}
                styles={selectStyle}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AnalyticalReportFilter;
