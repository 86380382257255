import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";
import {
  postForgetPwd,
} from "../../../helpers/backend_helper";

function* forgetUser({ payload: { user } }) {
  try {
    yield call(postForgetPwd, {email: user.email,});
    yield put(userForgetPasswordSuccess());
  } catch (error) {
    yield put(userForgetPasswordError());
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
