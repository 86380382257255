import { SvgIconComponent } from "@mui/icons-material"

interface IProps {
    IconComponent: SvgIconComponent
    color?: string
    onClick?:any
    width?:number | string
    height?:number | string
}

export default function Icon({ IconComponent, color='black',onClick, height, width }: IProps) {
    return <IconComponent onClick={onClick} style={{color, width, height}} />
}