import { takeEvery, fork, put, all, call, take, takeLatest } from "redux-saga/effects";
import { RESET_PASSWORD } from "./actionTypes";

import {
  postResetPassword,
} from "../../../helpers/backend_helper";
import { resetPasswordFailed, resetPasswordSuccessful } from "./actions";
import { localStorageKeys } from "../../../config/localStorageKeys";
import {  setSelectedCampaign, setUserLoggedIn, setUserProfile } from "../profile/actions";
import { loginSuccess } from "../login/actions";


function* resetPassword({ payload: { user, history } }) {
  try {
    const response = yield call(postResetPassword, user);
    localStorage.setItem(localStorageKeys.AUTH_USER, response.access_token);

    yield put(setUserProfile())
    yield take(setUserLoggedIn)

    yield put(resetPasswordSuccessful());
    yield put(loginSuccess());
    history('/panel');
  } catch (error) {
    yield put(resetPasswordFailed());
  }
}

export function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

export default resetPasswordSaga;
