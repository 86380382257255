import styled from "styled-components"
import { dsVariables } from "../../variables";
import { IButtonProps, TButtonStates, TButtonTypes } from "./ButtonPrimary";
import Icon from "../Icon";

export default function ButtonTertiary({ type = "normal", state = "default", label, iconLeft, iconRight, mode = "light", onClick, iconLeftColor, iconRightColor }: IButtonProps) {
    return (
        <Button state={state} type={type} onClick={onClick} className={`bg-transparent d-flex gap-2 align-items-center justify-content-center`}>
            {iconLeft && <Icon width={16} height={16} IconComponent={iconLeft} color={iconLeftColor} />}
            {label && <span>{label}</span>}
            {iconRight && <Icon width={16} height={16} IconComponent={iconRight} color={iconRightColor} />}
        </Button>
    )
}

const Button = styled.button<{ type: TButtonTypes, state: TButtonStates }>`
    border-radius: ${dsVariables.spacing[4]};
    border: none;
    height:36px;

    ${props => {
        if (props.state == "disabled") return `
            color: ${dsVariables.colors.Gray[300]};
            pointer-events: none;

            svg {
                color: ${dsVariables.colors.Gray[300]} !important;
            }
         
        `
        switch (props.type) {
            case 'destructive':
                return `
                    color: ${dsVariables.colors.Red[700]};
                `
            case 'warning':
                return `
                    color: ${dsVariables.colors.Orange[700]};
                `
            case 'normal':
            default:
                return `
                    color: ${dsVariables.colors.Astronaut[900]};
                `
        }
    }
    }
`