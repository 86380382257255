import { useCallback, useState } from "react";
import Modal from "../../../../DesignSystem/components/Modal";
import AdsForm from "../Forms/AdsForm";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import SiteForm from "../Forms/SiteForm";
import ProfileForm from "../Forms/ProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { cleanForms, registerFraud } from "../../../../store/threats/actions";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import { useLocation, useNavigate } from "react-router-dom";
import ContentForm from "../Forms/ContentForm";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
interface IProps {
    isOpen: boolean
    onClose: any
}

export default function ModalNewThreats({ isOpen, onClose, }: IProps) {
    const dispatch = useDispatch()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const tab1Active = location.search.includes('tab=1')

    const [typeForm, setTypeForm] = useState<ThreatTypeTextEnum>(ThreatTypeTextEnum.FAKE_AD);

    const { adsForm, profileForm, siteForm, contentForm, selectedCompany } = useSelector((store: IStore) => ({
        adsForm: store.Threats.forms.adsForm,
        profileForm: store.Threats.forms.profileForm,
        siteForm: store.Threats.forms.siteForm,
        contentForm: store.Threats.forms.contentForm,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
    }))

    const isButtonDisabled = () => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return !Object.entries(adsForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(adsForm.evidence!.length > 0 || Boolean(adsForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_PROFILE: return !Object.entries(profileForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(profileForm.evidence!.length > 0 || Boolean(profileForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_SITE: return !Object.entries(siteForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(siteForm.evidence!.length > 0 || Boolean(siteForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_CONTENT: return !Object.entries(contentForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(contentForm.evidence!.length > 0 || Boolean(contentForm.evidenceLink))
        }
    }

    function changeTypeForm(threatTypeText: ThreatTypeTextEnum) {
        dispatch(cleanForms())
        setTypeForm(threatTypeText)
    }

    const renderForm = useCallback(() => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return <AdsForm setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_PROFILE: return <ProfileForm setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_SITE: return <SiteForm setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_CONTENT: return <ContentForm setTypeForm={changeTypeForm} />
        }
    }, [typeForm])

    function navigateToTabNew() {
        if (!tab1Active) {
            searchParams.set('tab', '0');
            const newSearch = searchParams.toString();
            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }
    }

    function registerThreat() {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:
                dispatch(registerFraud({
                    platform: adsForm.platform, type: ThreatTypeEnum.FAKE_AD,
                    domain: adsForm.url,
                    isActive: adsForm.isActive, evidence: adsForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: adsForm.evidenceLink
                }))
                onClose()
                return navigateToTabNew()
            case ThreatTypeTextEnum.FAKE_PROFILE:
                dispatch(registerFraud({
                    platform: profileForm.platform, type: ThreatTypeEnum.FAKE_PROFILE,
                    domain: profileForm.url,
                    isActive: profileForm.isActive, evidence: profileForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: profileForm.evidenceLink
                }))
                onClose()
                return navigateToTabNew()
            case ThreatTypeTextEnum.FAKE_SITE:
                dispatch(registerFraud({
                    platform: ThreatPlatformEnum.OUTROS, type: ThreatTypeEnum.FAKE_SITE,
                    domain: siteForm.url,
                    isActive: siteForm.isActive, evidence: siteForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: siteForm.evidenceLink
                }))
                onClose()
                return navigateToTabNew()
            case ThreatTypeTextEnum.FAKE_CONTENT:
                dispatch(registerFraud({
                    platform: contentForm.platform, type: ThreatTypeEnum.FAKE_CONTENT,
                    domain: contentForm.url,
                    isActive: contentForm.isActive, evidence: contentForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: contentForm.evidenceLink
                }))
                onClose()
                return navigateToTabNew()
        }
    }

    return (
        <Modal
            width={728}
            title="Incluir ameaça"
            subtitle="Preencha abaixo as informações solicitadas."
            isOpen={isOpen}
            onClose={onClose}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: registerThreat, label: 'Inserir', state: isButtonDisabled() ? 'disabled' : 'default' },
            ]}
        >
            {renderForm()}
        </Modal>
    );
}
