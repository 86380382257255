import {
  SET_CAMPAIGN_ELIMINATED_REPORT,
  SET_CAMPAIGN_ELIMINATED_REPORT_ERROR,
  SET_CAMPAIGN_ELIMINATED_REPORT_LOADING,
  SET_CAMPAIGN_MEDIATION_REPORT,
  SET_CAMPAIGN_MEDIATION_REPORT_ERROR,
  SET_CAMPAIGN_MEDIATION_REPORT_LOADING,
  SET_CAMPAIGN_NEW_REPORT,
  SET_CAMPAIGN_NEW_REPORT_ERROR,
  SET_CAMPAIGN_NEW_REPORT_LOADING,
  SET_CAMPAIGN_NOTIFIED_REPORT,
  SET_CAMPAIGN_NOTIFIED_REPORT_ERROR,
  SET_CAMPAIGN_NOTIFIED_REPORT_LOADING,
  SET_CAMPAIGN_REAPPEARED_REPORT,
  SET_CAMPAIGN_REAPPEARED_REPORT_ERROR,
  SET_CAMPAIGN_REAPPEARED_REPORT_LOADING,
  SET_CAMPAIGN_REPORT_ERROR,
  SET_CAMPAIGN_TOTAL_REPORT_LOADING,
  SET_POSITIONS_FILTER,
  SET_RANGE_DATE,
  SET_REPORT_CAMPAIGNID,
  SET_REPORT_END_DATE,
  SET_REPORT_START_DATE,
  SET_TAB,
  SET_TOTAL_CAMPAIGN_NEW_REPORT,
  SET_TOTAL_CAMPAIGN_REPORT,
} from "./actionTypes";

const initialState = {
  reportStartDate: null,
  reportEndDate: null,
  reportCampaignId: null,
  positionsFilterOptions: null,
  totalAggressorsReport: {
    data: null,
    loading: false,
    error: false,
  },
  newAggressorsReport: {
    data: null,
    loading: false,
    error: false,
  },
  notifiedAggressorsReport: {
    data: null,
    loading: false,
    error: false,
  },
  mediationAggressorsReport: {
    data: null,
    loading: false,
    error: false,
  },
  reappearedAggressorsReport: {
    data: null,
    loading: false,
    error: false,
  },
  eliminatedAggressorsReport: {
    data: null,
    loading: false,
    error: false,
  },
  selectedTab: "1",
  selectedRangeDate: "14",
};

const Report = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_CAMPAIGNID:
      state = {
        ...state,
        reportCampaignId: action.payload.campaignId,
      };
      break;

    case SET_POSITIONS_FILTER:
      state = {
        ...state,
        positionsFilterOptions: action.payload.positions,
      };
      break;

    case SET_REPORT_START_DATE:
      state = {
        ...state,
        reportStartDate: action.payload,
      };
      break;

    case SET_REPORT_END_DATE:
      state = {
        ...state,
        reportEndDate: action.payload,
      };
      break;

    case SET_TOTAL_CAMPAIGN_REPORT: {
      state = { ...state };
      state.totalAggressorsReport.data = action.payload.data;
      state.totalAggressorsReport.loading = false;
      state.totalAggressorsReport.error = false;
      break;
    }

    case SET_CAMPAIGN_TOTAL_REPORT_LOADING: {
      state = { ...state };
      state.totalAggressorsReport.loading = true;
      break;
    }

    case SET_CAMPAIGN_REPORT_ERROR: {
      state = { ...state };
      state.totalAggressorsReport.error = true;
      break;
    }

    case SET_CAMPAIGN_NEW_REPORT: {
      state = { ...state };
      state.newAggressorsReport.data = action.payload.data;
      state.newAggressorsReport.loading = false;
      state.newAggressorsReport.error = false;
      break;
    }

    case SET_CAMPAIGN_NEW_REPORT_LOADING: {
      state = { ...state };
      state.newAggressorsReport.loading = true;
      break;
    }

    case SET_CAMPAIGN_NEW_REPORT_ERROR: {
      state = { ...state };
      state.newAggressorsReport.error = true;
      break;
    }

    case SET_CAMPAIGN_REAPPEARED_REPORT: {
      state = { ...state };
      state.reappearedAggressorsReport.data = action.payload.data;
      state.reappearedAggressorsReport.loading = false;
      state.reappearedAggressorsReport.error = false;
      break;
    }

    case SET_CAMPAIGN_REAPPEARED_REPORT_LOADING: {
      state = { ...state };
      state.reappearedAggressorsReport.loading = true;
      break;
    }

    case SET_CAMPAIGN_REAPPEARED_REPORT_ERROR: {
      state = { ...state };
      state.reappearedAggressorsReport.error = true;
      break;
    }

    case SET_CAMPAIGN_NOTIFIED_REPORT: {
      state = { ...state };
      state.notifiedAggressorsReport.data = action.payload.data;
      state.notifiedAggressorsReport.loading = false;
      state.notifiedAggressorsReport.error = false;
      break;
    }

    case SET_CAMPAIGN_NOTIFIED_REPORT_LOADING: {
      state = { ...state };
      state.notifiedAggressorsReport.loading = true;
      break;
    }

    case SET_CAMPAIGN_NOTIFIED_REPORT_ERROR: {
      state = { ...state };
      state.notifiedAggressorsReport.error = true;
      break;
    }

    case SET_CAMPAIGN_MEDIATION_REPORT: {
      state = { ...state };
      state.mediationAggressorsReport.data = action.payload.data;
      state.mediationAggressorsReport.loading = false;
      state.mediationAggressorsReport.error = false;
      break;
    }

    case SET_CAMPAIGN_MEDIATION_REPORT_LOADING: {
      state = { ...state };
      state.mediationAggressorsReport.loading = true;
      break;
    }

    case SET_CAMPAIGN_MEDIATION_REPORT_ERROR: {
      state = { ...state };
      state.mediationAggressorsReport.error = true;
      break;
    }

    case SET_CAMPAIGN_ELIMINATED_REPORT: {
      state = { ...state };
      state.eliminatedAggressorsReport.data = action.payload.data;
      state.eliminatedAggressorsReport.loading = false;
      state.eliminatedAggressorsReport.error = false;
      break;
    }

    case SET_CAMPAIGN_ELIMINATED_REPORT_LOADING: {
      state = { ...state };
      state.eliminatedAggressorsReport.loading = true;
      break;
    }

    case SET_CAMPAIGN_ELIMINATED_REPORT_ERROR: {
      state = { ...state };
      state.eliminatedAggressorsReport.error = true;
      break;
    }
    case SET_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_RANGE_DATE:
      return {
        ...state,
        selectedRangeDate: action.payload,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Report;
