import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Container, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import ValidateTwoFactor from "./ValidateTwoFactor";

const TwoFactorAuthentication = () => {
  const selectProfileState = (state) => state.Profile;
  const { user } = useSelector(selectProfileState);

  const api = new APIClient();

  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
  const [qrcode, setQrcode] = useState("");
  const [tokenSecret, setTokenSecret] = useState();

  const handleToggleTwoFactor = async () => {
    setIsTwoFactorEnabled(!isTwoFactorEnabled);
    if (!isTwoFactorEnabled) {
      try {
        const response = await api.get(`2fa/generate?email=${user.email}`);

        setQrcode(response.qrCode);

        localStorage.setItem("2fa", "true");
        setTokenSecret(response.secret);
      } catch (error) {
        console.error("Error generating 2FA:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="mb-0 w-100 card pr-0 d-flex card-body">
              <h2>Autenticação por 2 fatores.</h2>

              {!localStorage.getItem("2fa") && (
                <Form>
                  <FormGroup>
                    <Input
                      type="checkbox"
                      id="twoFactorSwitch"
                      checked={isTwoFactorEnabled}
                      onChange={handleToggleTwoFactor}
                    />{" "}
                    <Label for="twoFactorSwitch"> Ativar 2FA</Label>
                  </FormGroup>
                </Form>
              )}

              <>
                {localStorage.getItem("2fa") && (
                  <p>
                    <strong className="btn btn-success">Ativado</strong>
                  </p>
                )}
                {isTwoFactorEnabled && qrcode && (
                  <Row>
                    <Col>
                      <div>
                        <p>
                          Agora que você ativou a autenticação em 2 fatores para
                          sua conta. Leia o QRCode em seu aplicativo de
                          autenticação, recomendamos o uso do{" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=pt_BR&pli=1"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Microsoft Authenticator
                          </a>{" "}
                          ou do{" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Google Authenticator
                          </a>
                          .
                        </p>
                        <p>
                          Lembre-se que só terá efeito definitivo uma vez que
                          você confirmar o código ao lado.
                        </p>
                        <p>
                          <strong>
                            Não o perca ou você não poderá mais acessar sua
                            conta.
                          </strong>
                        </p>
                        <img src={qrcode} title="qrCode" alt="qrCode" />
                      </div>
                    </Col>
                    <Col>
                      <ValidateTwoFactor
                        firstCheck={true}
                        secretCode={tokenSecret}
                      />
                    </Col>
                  </Row>
                )}
              </>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TwoFactorAuthentication;
