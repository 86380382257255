import { REHYDRATE } from "redux-persist/lib/constants";
import {
  PROFILE_ERROR,
  RESET_PROFILE_FLAG,
  SET_SELECTED_COMPANY,
  SET_SELECTED_CAMPAIGN,
  SET_USER_CAMPAIGNS,
  GET_USER_CAMPAIGNS,
  SET_USER_LOGGED_IN,
  SET_EVENT,
  SET_PAGE_SIZE,
  SET_ACCESS_FORBIDDEN,
  RESET_ACCESS_FORBIDDEN,
  UPDATE_2FA_SECRET,
  SET_2FA_VALIDATED,
} from "./actionTypes";

const initialState = {
  profileError: false,
  accessForbidden: false,
  profileLoading: false,
  user: {},
  userLoggedIn: false,
  selectedCompany: "",
  selectedCampaign: "",
  selectedEvent: "",
  pageSize: 10,
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload?.Profile,
      };
    case SET_USER_LOGGED_IN:
      return {
        ...state,
        profileError: false,
        user: action.payload,
        userLoggedIn: true,
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case GET_USER_CAMPAIGNS:
      return {
        ...state,
      };
    case SET_USER_CAMPAIGNS:
      return {
        ...state,
        user: {
          ...state.user,
          campaigns: action.payload,
          showAllCompanies: false,
        },
      };
    case SET_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    case SET_EVENT:
      return {
        ...state,
        selectedEvent: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_ACCESS_FORBIDDEN:
      return {
        ...state,
        accessForbidden: true,
      };
    case RESET_ACCESS_FORBIDDEN:
      return {
        ...state,
        accessForbidden: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        profileError: true,
        userLoggedIn: false,
        selectedCompany: "",
        selectedCampaign: "",
      };
    /*case UPDATE_2FA_SECRET:
      return {
        ...state,
        user: {
          ...state.user,
          secret_2fa: action.payload,
          validated_2fa: false,
        },
      };
    case SET_2FA_VALIDATED:
      return {
        ...state,
        user: {
          ...state.user,
          validated_2fa: action.payload,
        },
      };*/
    case RESET_PROFILE_FLAG:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default Profile;
