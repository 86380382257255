import { ThreatTypeTextEnum } from "../../enums/threat-type-text.enum";
import { ThreatTypeEnum } from "../../enums/threat-type.enum";

export function getThreatTypeText(threatType: ThreatTypeEnum){
    switch(threatType){
        case ThreatTypeEnum.ALL: return ThreatTypeTextEnum.ALL
        case ThreatTypeEnum.FAKE_SITE: return ThreatTypeTextEnum.FAKE_SITE
        case ThreatTypeEnum.FAKE_PROFILE: return ThreatTypeTextEnum.FAKE_PROFILE
        case ThreatTypeEnum.FAKE_AD: return ThreatTypeTextEnum.FAKE_AD
        case ThreatTypeEnum.FAKE_CONTENT: return ThreatTypeTextEnum.FAKE_CONTENT
    }
}