const CHANNEL_OPTIONS = [{ value: "", label: "Todos" }];

const STATUS_OPTIONS = [
    { value: "", label: "Todos" },
    { value: "approval", label: "Aprovação" },
    { value: "notification", label: "Notificação" },
    { value: "mediation", label: "Mediação" },
    { value: "eliminated", label: "Eliminado" },
    { value: "lawyer", label: "Jurídico" },
];

const ACTINGS_OPTIONS = [
    { value: "", label: "Todos" },
    { value: "notify", label: "Sim" },
    { value: "whitelist", label: "Não" },
    { value: "standby", label: "Stand-by" }
];

const PAGE = [{ value: "", label: "Todas" }];

module.exports = { CHANNEL_OPTIONS, STATUS_OPTIONS, ACTINGS_OPTIONS, PAGE }