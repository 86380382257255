import {
  GET_CAMPAIGN_ELIMINATED_REPORT,
  GET_CAMPAIGN_MEDIATION_REPORT,
  GET_CAMPAIGN_NEW_REPORT,
  GET_CAMPAIGN_NOTIFIED_REPORT,
  GET_CAMPAIGN_REAPPEARED_REPORT,
  GET_CAMPAIGN_TOTAL_REPORT,
  SET_CAMPAIGN_ELIMINATED_REPORT,
  SET_CAMPAIGN_ELIMINATED_REPORT_ERROR,
  SET_CAMPAIGN_ELIMINATED_REPORT_LOADING,
  SET_CAMPAIGN_MEDIATION_REPORT,
  SET_CAMPAIGN_MEDIATION_REPORT_ERROR,
  SET_CAMPAIGN_MEDIATION_REPORT_LOADING,
  SET_CAMPAIGN_NEW_REPORT,
  SET_CAMPAIGN_NEW_REPORT_ERROR,
  SET_CAMPAIGN_NEW_REPORT_LOADING,
  SET_CAMPAIGN_NOTIFIED_REPORT,
  SET_CAMPAIGN_NOTIFIED_REPORT_ERROR,
  SET_CAMPAIGN_NOTIFIED_REPORT_LOADING,
  SET_CAMPAIGN_REAPPEARED_REPORT,
  SET_CAMPAIGN_REAPPEARED_REPORT_ERROR,
  SET_CAMPAIGN_REAPPEARED_REPORT_LOADING,
  SET_CAMPAIGN_REPORT_ERROR,
  SET_CAMPAIGN_TOTAL_REPORT_LOADING,
  SET_POSITIONS_FILTER,
  SET_RANGE_DATE,
  SET_REPORT_CAMPAIGNID,
  SET_REPORT_END_DATE,
  SET_REPORT_START_DATE,
  SET_TAB,
  SET_TOTAL_CAMPAIGN_REPORT,
} from "./actionTypes";

export const setReportCampaignId = (campaignId) => {
  return {
    type: SET_REPORT_CAMPAIGNID,
    payload: { campaignId },
  };
};

export const setPositionsFilter = (positions) => {
  return {
    type: SET_POSITIONS_FILTER,
    payload: { positions },
  };
};

export const setReportStartDate = (date) => {
  return {
    type: SET_REPORT_START_DATE,
    payload: date,
  };
};

export const setReportEndDate = (date) => {
  return {
    type: SET_REPORT_END_DATE,
    payload: date,
  };
};

export const getCampaignReport = (campaignId, startDate, endDate, page) => {
  return {
    type: GET_CAMPAIGN_TOTAL_REPORT,
    payload: { campaignId, startDate, endDate, page },
  };
};

export const setCampaignReport = (data) => {
  return {
    type: SET_TOTAL_CAMPAIGN_REPORT,
    payload: { data },
  };
};

export const setCampaignReappearedReportLoading = () => {
  return {
    type: SET_CAMPAIGN_REAPPEARED_REPORT_LOADING,
  };
};

export const setCampaignReappearedReportError = () => {
  return {
    type: SET_CAMPAIGN_REAPPEARED_REPORT_ERROR,
  };
};

export const getCampaignReappearedReport = (
  campaignId,
  startDate,
  endDate,
  page
) => {
  return {
    type: GET_CAMPAIGN_REAPPEARED_REPORT,
    payload: { campaignId, startDate, endDate, page },
  };
};

export const setCampaignReappearedReport = (data) => {
  return {
    type: SET_CAMPAIGN_REAPPEARED_REPORT,
    payload: { data },
  };
};

export const setCampaignReportLoading = () => {
  return {
    type: SET_CAMPAIGN_TOTAL_REPORT_LOADING,
  };
};

export const setCampaignReportError = () => {
  return {
    type: SET_CAMPAIGN_REPORT_ERROR,
  };
};

export const getCampaignNewReport = (campaignId, startDate, endDate, page) => {
  return {
    type: GET_CAMPAIGN_NEW_REPORT,
    payload: { campaignId, startDate, endDate, page },
  };
};

export const setCampaignNewReport = (data) => {
  return {
    type: SET_CAMPAIGN_NEW_REPORT,
    payload: { data },
  };
};

export const setCampaignNewReportLoading = () => {
  return {
    type: SET_CAMPAIGN_NEW_REPORT_LOADING,
  };
};

export const setCampaignNewReportError = () => {
  return {
    type: SET_CAMPAIGN_NEW_REPORT_ERROR,
  };
};

export const getCampaignNotifiedReport = (
  campaignId,
  startDate,
  endDate,
  page
) => {
  return {
    type: GET_CAMPAIGN_NOTIFIED_REPORT,
    payload: { campaignId, startDate, endDate, page },
  };
};

export const setCampaignNotifiedReport = (data) => {
  return {
    type: SET_CAMPAIGN_NOTIFIED_REPORT,
    payload: { data },
  };
};

export const setCampaignNotifiedReportLoading = () => {
  return {
    type: SET_CAMPAIGN_NOTIFIED_REPORT_LOADING,
  };
};

export const setCampaignNotifiedReportError = () => {
  return {
    type: SET_CAMPAIGN_NOTIFIED_REPORT_ERROR,
  };
};

export const getCampaignMediationReport = (
  campaignId,
  startDate,
  endDate,
  page
) => {
  return {
    type: GET_CAMPAIGN_MEDIATION_REPORT,
    payload: { campaignId, startDate, endDate, page },
  };
};

export const setCampaignMediationReport = (data) => {
  return {
    type: SET_CAMPAIGN_MEDIATION_REPORT,
    payload: { data },
  };
};

export const setCampaignMediationReportLoading = () => {
  return {
    type: SET_CAMPAIGN_MEDIATION_REPORT_LOADING,
  };
};

export const setCampaignMediationReportError = () => {
  return {
    type: SET_CAMPAIGN_MEDIATION_REPORT_ERROR,
  };
};

export const getCampaignEliminatedReport = (
  campaignId,
  startDate,
  endDate,
  page
) => {
  return {
    type: GET_CAMPAIGN_ELIMINATED_REPORT,
    payload: { campaignId, startDate, endDate, page },
  };
};

export const setCampaignEliminatedReport = (data) => {
  return {
    type: SET_CAMPAIGN_ELIMINATED_REPORT,
    payload: { data },
  };
};

export const setCampaignEliminatedReportLoading = () => {
  return {
    type: SET_CAMPAIGN_ELIMINATED_REPORT_LOADING,
  };
};

export const setCampaignEliminatedReportError = () => {
  return {
    type: SET_CAMPAIGN_ELIMINATED_REPORT_ERROR,
  };
};

export const setTab = (id) => ({
  type: SET_TAB,
  payload: id,
});

export const setRangeDate = (range) => ({
  type: SET_RANGE_DATE,
  payload: range,
});
