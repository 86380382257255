import  { useState, useEffect } from 'react';

const useDotsLoader = () => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev === '...') {
          return '.';
        } else {
          return prev + '.';
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return dots
};

export default useDotsLoader;