import React from 'react';
import ReactDOM from 'react-dom/client';
import {  persistor, sagaMiddleware, store } from "./store";
import App from './App';

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import rootSaga from './store/sagas';
import { PersistGate } from 'redux-persist/integration/react';


sagaMiddleware.run(rootSaga);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
