import styled from "styled-components"
import { dsVariables } from "../../../variables"
import { AccessTime, Check, DeleteOutline, WebAssetOff } from "@mui/icons-material"
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum"
import { useSearchParams } from "react-router-dom";

interface IProps {
    onClick: any
}

export default function OptionsCard({ onClick }: IProps) {

    const searchParams = useSearchParams()[0]

    const tab = searchParams.get('tab')

    return (
        <Div className="d-flex flex-column gap-2 position-absolute">
            {tab != '4' && <button onClick={() => onClick(tab == '0' || !tab ? ThreatOptionsCardEnum.APPROVAL : ThreatOptionsCardEnum.TAKEDOWN)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <WebAssetOff />
                <span>{tab == '0' || !tab ? 'Solicitar aprovação' : 'Aprovar takedown'}</span>
            </button>}
            {tab != '3' && <button onClick={() => onClick(ThreatOptionsCardEnum.DISCARD)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <DeleteOutline />
                <span>Descartar</span>
            </button>}
            {tab != '1' && <button onClick={() => onClick(ThreatOptionsCardEnum.QUARANTINE)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <AccessTime />
                <span>Quarentena</span>
            </button>}
            {tab != '5' && <button onClick={() => onClick(ThreatOptionsCardEnum.RESOLVED)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <Check />
                <span>Resolvida</span>
            </button>}
        </Div>
    )
}

const Div = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    top: 30px;
    border-radius:${dsVariables.cornerRadius[4]};
    padding:${dsVariables.spacing[8]};
    right: 0;
    box-shadow: 0px 0px 8px 0px #00000029;
    white-space:nowrap;
    z-index:2;

    button{
        color: ${dsVariables.colors.Gray[700]};
    
        &:hover {
            color: ${dsVariables.colors.Astronaut[900]};
        }
    }
`