import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";

const AnalyticalReportWidgets = ({ analyticalKpisData, loading }) => {
  const widgets = [
    {
      title: "AGRESSORES IDENTIFICADOS",
      bgIcon: "bg-danger-subtle",
      iconClass: "bx bxs-mask text-danger",
      start: 0,
      end: analyticalKpisData?.identifiedAggressorsTotal,
    },
    {
      title: "INATIVOS",
      bgIcon: "bg-success-subtle",
      iconClass: "bx bx-trophy text-success",
      start: 0,
      end: analyticalKpisData?.inactiveAggressorsTotal,
    },
    {
      title: "OCORRÊNCIAS",
      bgIcon: "bg-warning-subtle",
      iconClass: "bx bx-mail-send text-warning",
      start: 0,
      end: analyticalKpisData?.occurrencesTotal,
    },
    {
      title: "NOTIFICADOS",
      bgIcon: "bg-warning-subtle",
      iconClass: "bx bx-mail-send text-warning",
      start: 0,
      end: analyticalKpisData?.notifiedAggressorsTotal,
    },
    {
      title: "ELIMINADOS",
      bgIcon: "bg-success-subtle",
      iconClass: "bx bx-trophy text-success",
      start: 0,
      end: analyticalKpisData?.eliminatedAggressorsTotal,
    },
    {
      title: "NOTIFICAÇÕES ENVIADAS",
      bgIcon: "bg-warning-subtle",
      iconClass: "bx bx-mail-send text-warning",
      start: 0,
      end: analyticalKpisData?.notificationsSentTotal,
    },
  ];

  return (
    <React.Fragment>
      <Row className="g-2 mb-4">
        {widgets.map((item, index) => (
          <Col
            xs={6}
            lg={4}
            className="d-flex align-self-stretch flex-fill"
            key={index}
          >
            <Card className="mb-0 w-100">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-muted mb-0 widget-card-title">
                      {item.title}
                    </p>
                    <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                      <span className="counter-value" data-target="3">
                        {loading ? (
                          <Spinner color="primary" />
                        ) : (
                          <CountUp
                            start={item.start}
                            end={item.end}
                            duration={4}
                          />
                        )}
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span
                        className={`${item.bgIcon} avatar-title rounded fs-3`}
                      >
                        <i className={item.iconClass}></i>
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default AnalyticalReportWidgets;
