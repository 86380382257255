import React from 'react';
import { Fade } from 'reactstrap';


const BouncingDotsLoader = ({ size }) => {
  return (
    <Fade>
      <div className="bouncing-loader">
        <div style={{ width: size, height: size }}></div>
        <div style={{ width: size, height: size }}></div>
        <div style={{ width: size, height: size }}></div>
      </div>
    </Fade>
  );
};

export default BouncingDotsLoader;

