import {
  GET_EVENT_REQUEST,
  PATCH_EVENT_MEDIATION_REQUEST,
  PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST,
  GET_EVENT_ADS_REQUEST,
  DELETE_EVENT_EVIDENCE_REQUEST,
} from "./actionTypes";

export const getEvent = (eventId) => ({
  type: GET_EVENT_REQUEST,
  payload: { eventId },
});

export const patchEventMediation = (eventId, data) => ({
  type: PATCH_EVENT_MEDIATION_REQUEST,
  payload: { eventId, data },
});

export const patchEventEvidenceUpload = (eventId, data, file) => ({
  type: PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST,
  payload: { eventId, data, file },
});

export const getEventAds = (campaignId, domain) => ({
  type: GET_EVENT_ADS_REQUEST,
  payload: { campaignId, domain },
});

export const deleteEventEvidence = (eventId, config) => ({
  type: DELETE_EVENT_EVIDENCE_REQUEST,
  payload: { eventId, config },
});
