export const setAggressorColorByScore = (aggressivenessIndex) => {
  const aggresivenessIndexArray = [
    { color: "text-danger", min: 9, max: 10 },
    { color: "text-warning", min: 6, max: 8.99 },
    { color: "text-info", min: 3, max: 5.99 },
    { color: "text-gray-500", min: 0, max: 2.99 },
  ];

  let color;
  aggresivenessIndexArray.forEach((indexColor) => {
    if (
      aggressivenessIndex <= indexColor.max &&
      aggressivenessIndex >= indexColor.min
    ) {
      color = indexColor.color;
    }
  });
  return color;
};

export const setStatusColor = (status) => {
  const statusAndColors = [
    { status: "Aprovação", color: "danger" },
    { status: "Notificação", color: "warning" },
    { status: "Eliminado", color: "success" },
    { status: "Jurídico", color: "gray-500" },
    { status: "Mediação", color: "info" },
  ];
  let color = "";

  statusAndColors.forEach((statusAndColor) => {
    if (status === statusAndColor.status) {
      return (color = statusAndColor.color);
    }
  });
  return color;
};
