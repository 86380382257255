import {
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  PATCH_EVENT_MEDIATION_REQUEST,
  PATCH_EVENT_MEDIATION_SUCCESS,
  PATCH_EVENT_MEDIATION_FAILURE,
  PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST,
  PATCH_EVENT_EVIDENCE_UPLOAD_SUCCESS,
  PATCH_EVENT_EVIDENCE_UPLOAD_FAILURE,
  GET_EVENT_ADS_REQUEST,
  GET_EVENT_ADS_SUCCESS,
  GET_EVENT_ADS_FAILURE,
  DELETE_EVENT_EVIDENCE_REQUEST,
  DELETE_EVENT_EVIDENCE_SUCCESS,
  DELETE_EVENT_EVIDENCE_FAILURE,
} from "./actionTypes";

const initialState = {
  event: {
    _id: null,
    domain: null,
    campaignId: null,
    status: null,
    situation: null,
    aggressivenessIndex: null,
    channel: null,
    createdAt: null,
    updatedAt: null,
    lastOccursAt: null,
    campaign: {
      name: null,
      keywords: [],
      pages: [],
      position: [],
    },
    evidence: {
      attachments: {
        url: null,
        type: null,
      },
    },
    firstPage: [null],
    secondPage: [null],
  },
  ads: [
    {
      _id: {
        match: null,
        idx: null,
        captures: [null],
      },
      domain: null,
      createdAt: null,
      geoLocation: null,
      device: null,
      channel: null,
      keyword: null,
      page: null,
      position: null,
      htmlDataUrl: null,
    },
  ],
  getEvent: { loading: false, error: null },
  getEventAds: { loading: false, error: null },
  patchEventMediation: { loading: false, error: null },
  patchEventEvidenceUpload: { loading: false, error: null },
  deleteEventEvidence: { loading: false, error: null },
};

const Mediation = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_REQUEST:
      return {
        ...state,
        getEvent: { loading: true, error: null },
      };

    case GET_EVENT_ADS_REQUEST:
      return {
        ...state,
        getEventAds: { loading: true, error: null },
      };

    case PATCH_EVENT_MEDIATION_REQUEST:
      return {
        ...state,
        patchEventMediation: { loading: true, error: null },
      };

    case PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST:
      return {
        ...state,
        patchEventEvidenceUpload: { loading: true, error: null },
      };

    case GET_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload,
        getEvent: { loading: false, error: null },
      };

    case GET_EVENT_ADS_SUCCESS:
      return {
        ...state,
        ads: action.payload,
        getEventAds: { loading: false, error: null },
      };

    case PATCH_EVENT_MEDIATION_SUCCESS:
      return {
        ...state,
        patchEventMediation: { loading: false, error: null },
      };

    case PATCH_EVENT_EVIDENCE_UPLOAD_SUCCESS:
      return {
        ...state,
        patchEventEvidenceUpload: { loading: false, error: null },
      };

    case GET_EVENT_FAILURE:
      return {
        ...state,
        getEvent: { loading: false, error: action.error || "Error" },
      };

    case GET_EVENT_ADS_FAILURE:
      return {
        ...state,
        getEventAds: { loading: false, error: action.error || "Error" },
      };

    case PATCH_EVENT_MEDIATION_FAILURE:
      return {
        ...state,
        patchEventMediation: { loading: false, error: action.error || "Error" },
      };

    case PATCH_EVENT_EVIDENCE_UPLOAD_FAILURE:
      return {
        ...state,
        patchEventEvidenceUpload: {
          loading: false,
          error: action.error || "Error",
        },
      };

    case DELETE_EVENT_EVIDENCE_REQUEST:
      return {
        ...state,
        deleteEventEvidence: { loading: true, error: null },
      };

    case DELETE_EVENT_EVIDENCE_SUCCESS:
      return {
        ...state,
        deleteEventEvidence: { loading: false, error: null },
      };

    case DELETE_EVENT_EVIDENCE_FAILURE:
      return {
        ...state,
        deleteEventEvidence: { loading: false, error: action.error || "Error" },
      };

    default:
      return state;
  }
};

export default Mediation;
