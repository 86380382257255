import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESFUL,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_FLAG
} from "./actionTypes"


const initialState = {
  resetLoading: false,
  resetSuccess: false,
  resetError: false,
  resetIsPasswordCreated: false
};

const ResetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        resetLoading: true,
      };
      break;
    case RESET_PASSWORD_SUCCESFUL:
      state = {
        ...state,
        resetLoading: false,
        resetSuccess: true,
        resetIsPasswordCreated: true
      };
      break;
    case RESET_PASSWORD_FAILED:
      state = {
        ...state,
        resetLoading: false,
        resetError: true,
        resetIsPasswordCreated: false
      };
      break;
    case RESET_PASSWORD_FLAG:
      state = {
        ...state,
        resetSuccess: false,
        resetError: false,
        resetIsPasswordCreated: false
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ResetPassword;
