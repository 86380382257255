/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Label, Collapse } from "reactstrap";
import Select from "react-select";
import { Line } from "./MixedCharts";
import { Widgets } from "./Widgets";
import { TopReferrals } from "./TopReferrals";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgressorsEliminated,
  getAgressorsFound,
  getAgressorsMediation,
  getAgressorsNew,
  getAgressorsNotified,
  getAgressorsReappeared,
  getBrandScore,
  getLineAgressors,
  getTopAggressors,
  getTotalEliminated,
  getUserCampaigns,
  setSelectedCampaign,
} from "../../../store/actions";
import { useTranslation } from "react-i18next";
import CampaignSelector from "../../../Components/Common/CampaignSelector";
import * as moment from "moment-timezone";

const Starter = () => {
  document.title = "Branddi.com - Painel de BrandBidding";
  document.description =
    "Combata o uso indevido da sua marca e maximize seus resultados";

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const firstRenderOfTopAggressorDate = useRef(true);
  const firstRenderOfLineDate = useRef(true);
  const firstRenderOfKpis = useRef(true);
  const firstRenderOfTopAggressors = useRef(true);
  const firstRenderOfLineAggressors = useRef(true);
  const firstRenderOfBrandScore = useRef(true);
  const firstRenderOfTotalEliminated = useRef(true);

  const selectProfileState = (state) => state.Profile;
  const { user, selectedCampaign, selectedCompany } =
    useSelector(selectProfileState);

  const selectPanelState = (state) => state.Panel;
  const {
    widgets,
    panelLoading,
    line,
    lineLoading,
    lineError,
    topAggressors,
    topAggressorsLoading,
    topAggressorsError,
    brandScore,
    brandScoreLoading,
    totalEliminated,
    brandScoreError,
    totalEliminatedLoading,
    panelCampaignId,
  } = useSelector(selectPanelState);

  const [lineSelectedChannel, setLineSelectedChannel] = useState("search");
  const [lineSelectedPeriod, setLineSelectedPeriod] = useState(14);
  const [topAggressorsSelectedPeriod, setTopAggressorsSelectedPeriod] =
    useState(14);

  const [todayMinusOne, setTodayMinusOne] = useState(() => {
    const endDate = moment();
    endDate.subtract(1, "days").endOf("day").tz("America/Sao_Paulo");
    return endDate.format();
  });

  const [widgetsStartDate, setWidgetsStartDate] = useState(() => {
    const startDate = moment();
    startDate.subtract(14, "days").startOf("day").tz("America/Sao_Paulo");
    return startDate.format();
  });

  const [lineStartDate, setLineStartDate] = useState(() => {
    const startDate = moment();
    startDate
      .subtract(lineSelectedPeriod || 14, "days")
      .startOf("day")
      .tz("America/Sao_Paulo");
    return startDate.format();
  });

  const [topAggressorsStartDate, setTopAggressorsStartDate] = useState(() => {
    const startDate = moment();
    startDate
      .subtract(topAggressorsSelectedPeriod || 14, "days")
      .startOf("day")
      .tz("America/Sao_Paulo");
    return startDate.format();
  });

  const handleLineSelectedChannelChange = (value) => {
    setLineSelectedChannel(value);
  };

  const handleLineSelectedPeriodChange = (value) => {
    setLineSelectedPeriod(value);
  };

  const handleTopAggressorsSelectedPeriod = (value) => {
    setTopAggressorsSelectedPeriod(value);
  };

  useEffect(() => {
    if (firstRenderOfTopAggressorDate.current) {
      firstRenderOfTopAggressorDate.current = false;
    } else {
      setTopAggressorsStartDate(() => {
        let startDate = moment();
        const campaignCreation = moment(
          moment(selectedCampaign.createdAt).tz("America/Sao_Paulo").format()
        );
        startDate.subtract(topAggressorsSelectedPeriod || 14, "days");

        if (
          (topAggressorsSelectedPeriod === 0) |
          startDate.isBefore(campaignCreation)
        ) {
          startDate = campaignCreation;
        }

        if (topAggressorsSelectedPeriod !== 14) {
          startDate.startOf("month").tz("America/Sao_Paulo");
        }

        startDate.startOf("day").tz("America/Sao_Paulo");

        return startDate.format();
      });
    }
  }, [topAggressorsSelectedPeriod, todayMinusOne]);

  useEffect(() => {
    if (firstRenderOfLineDate.current) {
      firstRenderOfLineDate.current = false;
    } else {
      setLineStartDate(() => {
        let startDate = moment();
        const campaignCreation = moment(
          moment(selectedCampaign.createdAt).tz("America/Sao_Paulo").format()
        );
        startDate.subtract(lineSelectedPeriod || 14, "days");

        if (lineSelectedPeriod === 0 || startDate.isBefore(campaignCreation)) {
          startDate = campaignCreation;
        }

        if (lineSelectedPeriod !== 14 && lineSelectedPeriod !== 28) {
          startDate.startOf("month").tz("America/Sao_Paulo");
        }

        startDate.startOf("day").tz("America/Sao_Paulo");
        return startDate.format();
      });
    }
  }, [lineSelectedPeriod, todayMinusOne]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfLineAggressors.current) {
      (line.period === null || panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getLineAgressors(
            selectedCampaign.value,
            lineStartDate,
            todayMinusOne,
            lineSelectedChannel
          )
        );
      firstRenderOfLineAggressors.current = false;
    } else {
      dispatch(
        getLineAgressors(
          selectedCampaign.value,
          lineStartDate,
          todayMinusOne,
          lineSelectedChannel
        )
      );
    }
  }, [
    todayMinusOne,
    dispatch,
    lineStartDate,
    selectedCampaign,
    lineSelectedChannel,
  ]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfBrandScore.current) {
      (brandScore === null || panelCampaignId !== selectedCampaign.value) &&
        dispatch(getBrandScore(selectedCampaign.value));
      firstRenderOfBrandScore.current = false;
    } else {
      dispatch(getBrandScore(selectedCampaign.value));
    }
  }, [todayMinusOne, dispatch, selectedCampaign]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfTotalEliminated.current) {
      (totalEliminated === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getTotalEliminated(
            selectedCampaign.value,
            moment(selectedCampaign.createdAt).tz("America/Sao_Paulo").format(),
            todayMinusOne
          )
        );
      firstRenderOfTotalEliminated.current = false;
    } else {
      dispatch(
        getTotalEliminated(
          selectedCampaign.value,
          moment(selectedCampaign.createdAt).tz("America/Sao_Paulo").format(),
          todayMinusOne
        )
      );
    }
  }, [todayMinusOne, dispatch, selectedCampaign]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfTopAggressors.current) {
      (topAggressors.total === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getTopAggressors(
            selectedCampaign.value,
            topAggressorsStartDate,
            todayMinusOne,
            10,
            1,
            "search"
          )
        );
      firstRenderOfTopAggressors.current = false;
    } else {
      dispatch(
        getTopAggressors(
          selectedCampaign.value,
          topAggressorsStartDate,
          todayMinusOne,
          10,
          1,
          "search"
        )
      );
    }
  }, [dispatch, selectedCampaign, todayMinusOne, topAggressorsStartDate]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfKpis.current) {
      (widgets.foundAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsFound(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.newAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsNew(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.notifiedAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsNotified(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.inMediationAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsMediation(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.eliminatedAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsEliminated(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.reappearedAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsReappeared(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      firstRenderOfKpis.current = false;
    } else {
      dispatch(
        getAgressorsFound(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsNew(selectedCampaign.value, widgetsStartDate, todayMinusOne)
      );
      dispatch(
        getAgressorsNotified(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsMediation(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsEliminated(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsReappeared(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
    }
  }, [selectedCampaign, dispatch, widgetsStartDate, todayMinusOne]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CampaignSelector
            panelLoading={panelLoading}
            user={user}
            selectedCampaign={selectedCampaign}
            selectedCompany={selectedCompany}
          />
          <Row>
            <Col md={12} lg={7}>
              <Widgets
                selectedCampaign={selectedCampaign}
                widgets={widgets}
                panelLoading={panelLoading}
                brandScore={brandScore}
                brandScoreLoading={brandScoreLoading}
                totalEliminated={totalEliminated}
                totalEliminatedLoading={totalEliminatedLoading}
              />
            </Col>

            <Col md={12} lg={5} className="d-flex align-self-stretch flex-fill">
              <TopReferrals
                onSelectedPeriodChange={handleTopAggressorsSelectedPeriod}
                selectedPeriod={topAggressorsSelectedPeriod}
                topAggressors={topAggressors}
                topAggressorsLoading={topAggressorsLoading}
                panelLoading={panelLoading}
                topAggressorsError={topAggressorsError}
                brandScoreError={brandScoreError}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Line
                dataColors='["--vz-secondary", "--vz-danger"]'
                onSelectedChannelChange={handleLineSelectedChannelChange}
                onSelectedPeriodChange={handleLineSelectedPeriodChange}
                selectedChannel={lineSelectedChannel}
                selectedPeriod={lineSelectedPeriod}
                line={line}
                lineLoading={lineLoading}
                panelLoading={panelLoading}
                lineError={lineError}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Starter;
