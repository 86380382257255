import { useSelector } from "react-redux";
import Modal from "../../../../DesignSystem/components/Modal";
import { IStore } from "../../../../interfaces/store.interface";
import Body from "../../../../DesignSystem/components/Text/Body";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalTakedown({ isOpen, onClose, onConfirm }: IProps) {

    const { current, currentIds } = useSelector((store: IStore) => store.Threats)

    return (
        <Modal
            title="Solicitar takedown" isOpen={isOpen} onClose={onClose} width={480}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Confirmar' },
            ]}
        >
            <div className="d-flex flex-column gap-2 mb-4">
                <Body style="medium">Tem certeza que deseja aprovar takedown?</Body>
                {
                    currentIds.length == 0 && !current
                        ? null
                        : currentIds.length > 1
                            ? <span>Foram selecionadas {currentIds.length} ameaças. Elas estarão em tratativa. Esta ação não poderá ser desfeita.</span>
                            : <span>Foi selecionado a ameaça: {current?.domain} e estará em tratativa. Esta ação não poderá ser desfeita.</span>
                }
            </div>
        </Modal>
    )
}