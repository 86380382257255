import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { changeContentFormEvidenceFiles, changeContentFormEvidenceLink, changeContentFormIsActive, changeContentFormPlatform, changeContentFormProfile, changeContentFormUrl, } from "../../../../store/threats/actions";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";

interface IProps {
    setTypeForm: any
}

export default function ContentForm({ setTypeForm }: IProps) {

    const dispatch = useDispatch()

    const { contentForm } = useSelector((store: IStore) => store.Threats.forms)

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex flex-column gap-4">
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label>Tipo de ameaça*</Label>
                        <Select width={221} selected={ThreatTypeTextEnum.FAKE_CONTENT} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                        <Label>URL do conteúdo*</Label>
                        <Input width={458} required placeholder="Insira a URL" value={contentForm.url} onChange={(e: any) => dispatch(changeContentFormUrl(e.target.value))} />
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label>Plataforma*</Label>
                        <Select width={221} required onChange={(e: any) => dispatch(changeContentFormPlatform(e.target.value))} options={Object.values(ThreatPlatformEnum)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label>Status*</Label>
                        <Select width={221} required onChange={(e: any) => dispatch(changeContentFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label>Perfil*</Label>
                        <Input width={221} placeholder="Ex: @wadewilson" required value={contentForm.profile} onChange={(e: any) => dispatch(changeContentFormProfile(e.target.value))} />
                    </div>
                </div>
                <InputEvidence
                    files={contentForm.evidence}
                    onChangeLink={(e: any) => dispatch(changeContentFormEvidenceLink(e.target.value))}
                    linkValue={contentForm.evidenceLink}
                    onDropFile={(e: any) => dispatch(changeContentFormEvidenceFiles([...contentForm.evidence!, e[0]]))}
                    onRemoveFile={(file: File) => dispatch(changeContentFormEvidenceFiles(contentForm.evidence!.filter(el => el != file)))}
                />
            </div>
        </form>
    )
}