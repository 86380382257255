import React from 'react';
import ReactApexChart from "react-apexcharts";
import useDotsLoader from '../../../hooks/useDotsLoader';

export const SemiCircularRadial = ({ brandScore, brandScoreLoading, brandScoreError, panelLoading }) => {

  const series = [Math.round(brandScore)  || 0];

  const dotsLoader = useDotsLoader()


  return (
    <>
      <ReactApexChart dir="ltr"
        className="apex-charts"
        series={(brandScoreLoading || panelLoading)  && !brandScoreError ? [dotsLoader] : series}
        options={{
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                dropShadow: {
                  enabled: false,
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -12,
                  fontSize: '48px',
                  fontWeight: '600',
                  formatter: function (val) {
                    return val
                  }
                },
              },
            }
          },
          grid: {
            padding: {
              top: 2
            }
          },
        }}
        type="radialBar"
        height={320}
      />

    </>
  );
};