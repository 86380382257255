import { ThreatTypeTextEnum } from "../../enums/threat-type-text.enum";
import { ThreatTypeEnum } from "../../enums/threat-type.enum";

export function getThreatType(threatTypeText: ThreatTypeTextEnum){
    switch(threatTypeText){
        case ThreatTypeTextEnum.ALL: return ThreatTypeEnum.ALL
        case ThreatTypeTextEnum.FAKE_SITE: return ThreatTypeEnum.FAKE_SITE
        case ThreatTypeTextEnum.FAKE_PROFILE: return ThreatTypeEnum.FAKE_PROFILE
        case ThreatTypeTextEnum.FAKE_AD: return ThreatTypeEnum.FAKE_AD
        case ThreatTypeTextEnum.FAKE_CONTENT: return ThreatTypeEnum.FAKE_CONTENT
    }
}