import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import PrivacyPolicy from "../../../../Components/Common/PrivacyPolicy";

const Starter = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Privacy Policy" pageTitle="Privacy Policy" />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">POLÍTICA DE PRIVACIDADE</CardTitle>
                  <PrivacyPolicy />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Starter;
