import styled from "styled-components"
import { dsVariables } from "../../variables"
import Icon from "../Icon";
import { SvgIconComponent } from "@mui/icons-material";

export type TButtonTypes = 'normal' | 'warning' | 'destructive'
export type TButtonStates = 'default' | 'disabled';

export interface IButtonProps {
    type?: TButtonTypes
    state?: TButtonStates
    label?: string
    iconLeft?: SvgIconComponent
    iconLeftColor?: string
    iconRight?: SvgIconComponent
    iconRightColor?: string
    mode?: 'light' | 'dark'
    onClick: any
}

export default function ButtonPrimary({ type = "normal", state = "default", label, iconLeft, iconRight, mode = "light", onClick,iconLeftColor,iconRightColor }: IButtonProps) {
    return (
        <Button type="button" typeButton={type} state={state} disabled={state == "disabled"} onClick={onClick} className={`border-0 d-flex gap-2 align-items-center justify-content-center`}>
            {iconLeft && <Icon width={16} color={iconLeftColor} IconComponent={iconLeft} />}
            {label && <span>{label}</span>}
            {iconRight && <Icon width={16} color={iconRightColor} IconComponent={iconRight} />}
        </Button>
    )
}

const Button = styled.button<{ typeButton: TButtonTypes, state: TButtonStates }>`
    padding: 8px 12px;
    border-radius: 4px;
    height:36px;
    color: ${dsVariables.colors.Gray[50]};

    ${props => {
        if (props.state == "disabled") return `
            color: ${dsVariables.colors.Gray[300]};
            border-color:${dsVariables.colors.Gray[300]}
            background-color: ${dsVariables.colors.Gray[200]}
        `
        switch (props.typeButton) {
            case 'destructive':
                return `
                    background-color: ${dsVariables.colors.Red[700]};
                    boder-color: ${dsVariables.colors.Red[700]};
                `
            case 'warning':
                return `
                    background-color: ${dsVariables.colors.Orange[600]};
                    boder-color: ${dsVariables.colors.Orange[600]};
                `
            case 'normal':
            default:
                return `
                    background-color: ${dsVariables.colors.Astronaut[900]};
                `
        }
    }
    }
`