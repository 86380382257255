import React from "react";
import { Link } from "react-router-dom";
import {  Container } from "reactstrap";

//images import

const DefaultError = () => {
  return (
    <React.Fragment>
        <div className="page-content">
          <Container>
                <div className="text-center mt-sm-5 pt-4">
                  <div className="mb-5">
                    <p className="display-6">
                     Ocorreu um erro
                    </p>
                    <p className="fs-4">Por favor, contate o suporte</p>
                    <Link className="h4" to={"/panel"}>Voltar ao painel</Link>
                  </div>
                </div>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default DefaultError;
