import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';



const LookerStudioReport = (props) => {

    const selectProfilState = createSelector(
        (state) => state.Profile.user,
        (user) => user
      );


    const user = useSelector(selectProfilState);


    const params = {
        "ds170.email": user.email
      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString)
      const urlWithParams = `https://lookerstudio.google.com/embed/reporting/3e7b865a-dad1-4043-a4d9-68dbf6d5be64/page/p_svlmzqh0xc?params=${encodedParams}`



    return (
        <div>
            <iframe title='lookerstudio' width="600" height="3954" src={urlWithParams} style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0, width: "80%", height: "100%", border: 0, margin: "0 auto", marginTop: "120px", padding: 0}} allowFullScreen></iframe>
            </div>
    )
}

export default LookerStudioReport