export const mapPhase = {
  approval: "Aprovação",
  mediation: "Mediação",
  notification: "Notificação",
  eliminated: "Eliminado",
  lawyer: "Jurídico",
};

export const mapCategory = {
  owned: "Site Próprio",
  partner: "Parceiro",
  competitor: "Concorrente",
  fraud: "Suspeita de Fraude",
};

export const mapActing = {
  notify: "Enviar notificação",
  whitelist: "Permitir veiculação",
  standby: "Stand-by",
};
