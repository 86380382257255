import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import imageExample from "../../../../assets/images/no-image.jpeg";

const TicketDetailsModalEvidence = (props) => {
  const handleToggle = () => props.toggle(false);

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={handleToggle} centered size="md">
        <ModalHeader className="text-center">Prova Negativação</ModalHeader>
        <ModalBody>
          <img src={props.evidence} className="img-fluid" alt="" width="500" />
        </ModalBody>
      </Modal>
    </>
  );
};

export default TicketDetailsModalEvidence;
