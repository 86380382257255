import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Input,
  Label,
  Button,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, redirect, useNavigate } from "react-router-dom";
import Dropzone, { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { AlertTriangle } from "feather-icons-react";
import { AlertCircle } from "feather-icons-react";
import {
  patchEventEvidenceUpload,
  patchEventMediation,
} from "../../../../store/actions";
import {
  mapActing,
  mapCategory,
  mapPhase,
} from "../../../../utils/mapsTranslate";
import BouncingDotsLoader from "../../../../Components/Common/BoucingDotsLoader";
import { AlertOctagon, X } from "feather-icons-react/build/IconComponents";
import { use } from "echarts";

const stepConfigs = {
  categorize: {
    step: 1,
    next: "notify",
    btnNext: "Continuar",
    tabStep: "Passo 1",
    tabLabel: "Categorizar",
    progress: 0,
  },
  notify: {
    step: 2,
    next: "evidence",
    previous: "categorize",
    btnNext: "Continuar",
    tabStep: "Passo 2",
    tabLabel: "Deseja notificar?",
    progress: 33,
  },
  evidence: {
    step: 3,
    next: "confirm",
    previous: "notify",
    btnNext: "Upload e continuar",
    tabStep: "Passo 3",
    tabLabel: "Prova de boa-fé",
    progress: 66,
  },
  confirm: {
    step: 4,
    next: "confirm",
    previous: "evidence",
    btnNext: "Aprovar",
    tabStep: "Passo 4",
    tabLabel: "Confirmar",
    progress: 100,
  },
};

const phases = ["approval", "notification", "mediation", "eliminated"];

const ProgressSteps = ({ phase, setPhase, updateLoading, setApproved }) => {
  const getButtonColor = (step, currentIndex) => {
    if (
      phase === "eliminated" ||
      phase === "lawyer" ||
      currentIndex < phases.indexOf(phase)
    ) {
      return "success";
    } else if (currentIndex === phases.indexOf(phase)) {
      return "primary";
    } else {
      return "light";
    }
  };

  const getProgressValue = (step) => {
    if (step === "approval") {
      return 0;
    } else if (step === "notification") {
      return 33;
    } else if (step === "mediation") {
      return 66;
    } else if (step === "eliminated" || step === "lawyer") {
      return 100;
    }
    return 0;
  };

  const renderStatusIcons = (step, currentIndex) => {
    if (getButtonColor(step, currentIndex) === "success") {
      return <i className="bx bx-check"></i>;
    } else if (getButtonColor(step, currentIndex) === "primary") {
      return <motion.i className="bx bx-loader-circle" />;
    }
    return (
      <span>
        Aguardando <br />
        agressor responder
      </span>
    );
  };

  const editApproval = () => {
    setPhase("approval");
    setApproved(false);
  };

  return (
    <Card>
      <CardBody className="d-flex justify-content-center align-items-center">
        <div className="progress-steps" style={{ width: "820px" }}>
          <h5>Fases</h5>
          <div className="progress-steps__numbers">
            <div className="position-relative">
              <div
                className="bg-light"
                style={{ height: "1px", width: "100%" }}
              >
                <motion.div
                  className="bg-success"
                  initial={{ width: "0%" }}
                  animate={{ width: `${getProgressValue(phase)}%` }}
                  transition={{ duration: 1.5, type: "tween" }}
                  style={{
                    height: "100%",
                  }}
                />
              </div>
              {phases.map((step, index) => (
                <Button
                  key={index}
                  size="sm"
                  color={getButtonColor(step, index)}
                  className={`position-absolute top-0 start-${getProgressValue(
                    step
                  )} translate-middle rounded-pill`}
                  style={{ width: "2rem", height: "2rem" }}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
          </div>

          <div className="progress-steps__status">
            {phases.map((step, index) => {
              return (
                <div key={index} className="d-flex">
                  <p
                    className={
                      getButtonColor(step, index) === "primary"
                        ? "text-primary"
                        : null
                    }
                  >
                    {index === phases.length - 1 && phase === "lawyer"
                      ? mapPhase["lawyer"]
                      : mapPhase[step]}
                  </p>
                  {!updateLoading && step === "approval" && (
                    <>
                      <p style={{
                        fontSize: "10px",
                        marginTop: "-1rem",
                        color: "#30437f",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                       onClick={editApproval}>Editar</p>
                    </>
                  )}
                  {renderStatusIcons(step, index)}
                </div>
              );
            })}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const RegisterAggressor = (props) => {
  const eventData = props.eventData;
  const [currentStep, setCurrentStep] = useState("categorize");

  const [categorizeItem, setCategorizeItem] = useState(eventData.category);
  const [actingItem, setActingItem] = useState(eventData.acting);

  const [selectedFile, setselectedFile] = useState([]);
  const [phase, setPhase] = useState(eventData.status || "");
  const phaseStep = phase === "approval" || phase === "" ? true : false;
  const [errorMessage, setErrorMessage] = useState(null);
  const [evidenceAlert, setEvidenceAlert] = useState(false);
  const [ownedConfirmAlert, setOwnedConfirmAlert] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    setApproved(eventData?.approvedAt ? true : false);
  }, [eventData]);

  const dispatch = useDispatch();

  const handleUpdateCategory = () => {
    if (phase === "" && categorizeItem !== "whitelist") {
      setPhase("approval");
    }
    if (categorizeItem === "owned") {
      setActingItem(null);
    }
    if (categorizeItem === "fraud") {
      setActingItem("notify");
    }
  };

  const handleUploadEvidence = () => {
    if (!selectedFile[0]) {
      return;
    }

    const formData = new FormData();
    formData.append("campaignId", eventData.campaignId);
    formData.append("aggressorId", eventData._id);
    formData.append("file", selectedFile[0]);
    dispatch(patchEventEvidenceUpload(eventData._id, formData));
  };

  const [shouldReload, setShouldReload] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.updateLoading && shouldReload && !props.errorUpdate) {
      if (categorizeItem === "owned") {
        window.location.replace("/reports");
      } else {
        window.location.reload();
      }
    }
  }, [shouldReload, categorizeItem, navigate, props]);

  const handleConfirmModal = () => {
    setOwnedConfirmAlert(!ownedConfirmAlert);
  };

  const handleConfirmApproval = async () => {
    dispatch(
      patchEventMediation(eventData._id, {
        category: categorizeItem,
        acting: actingItem,
      })
    );
    setShouldReload(true);
  };

  const stepHandlers = {
    confirm: handleConfirmApproval,
    evidence: handleUploadEvidence,
    categorize: handleUpdateCategory,
  };

  const updateCurrentStep = (step) => {
    const handler = stepHandlers[step];
    if (handler) {
      handler();
    } else {
      console.warn(`Passo desconhecido: ${step}`);
    }
    setCurrentStep(stepConfigs[step]?.next);
  };

  const openEvidenceAlert = () => {
    setEvidenceAlert(!evidenceAlert);
  };

  const handleStepsButtons = () => {
    if (
      evidenceAlert === false &&
      currentStep === "evidence" &&
      selectedFile.length === 0
    ) {
      openEvidenceAlert();
      return;
    }

    setEvidenceAlert(false);

    if (
      ["whitelist", "standby"].includes(actingItem) ||
      categorizeItem === "owned" ||
      categorizeItem === "fraud"
    ) {
      if (categorizeItem === "owned" && currentStep === "confirm") {
        handleConfirmModal();
      } else {
        const handler = stepHandlers[currentStep];
        if (handler) {
          handler();
        }
      }
      setCurrentStep("confirm");
    } else {
      updateCurrentStep(currentStep);
    }
  };

  const handleBackClick = () => {
    if (currentStep === "confirm") {
      if (categorizeItem === "owned" || categorizeItem === "fraud") {
        setCurrentStep("categorize");
      } else if (["whitelist", "standby"].includes(actingItem)) {
        setCurrentStep("notify");
      } else {
        setCurrentStep(stepConfigs[currentStep]?.previous);
      }
    } else {
      setCurrentStep(stepConfigs[currentStep]?.previous);
    }
  };

  const Tabs = () => {
    return (
      <>
        <Col className="step-buttons" md={4}>
          {Object.entries(stepConfigs).map(([key, item], index) => {
            return (
              <div
                className={classNames({
                  active: stepConfigs[currentStep]?.step === item.step,
                  none: stepConfigs[currentStep]?.step > item.step,
                  disabled: stepConfigs[currentStep]?.step < item.step,
                })}
                key={index}
              >
                <div>
                  <i
                    className={classNames(
                      {
                        "bxs-right-arrow-circle":
                          stepConfigs[currentStep]?.step === item.step,
                        "bxs-check-circle":
                          stepConfigs[currentStep]?.step > item.step,
                      },
                      "bx"
                    )}
                  ></i>
                  <strong>{item.tabStep}</strong>
                  {item.tabLabel}
                </div>
                {stepConfigs[currentStep]?.step > item.step && (
                  <span onClick={() => setCurrentStep(key)}>editar</span>
                )}
              </div>
            );
          })}
        </Col>

        <div className="step-buttons__mobile">
          <div className="position-relative m-4">
            <Progress
              value={stepConfigs[currentStep]?.progress}
              style={{ height: "1px" }}
            />

            <Button
              size="sm"
              color={stepConfigs[currentStep]?.step >= 1 ? "primary" : "light"}
              className="position-absolute top-0 start-0 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              1
            </Button>
            <Button
              size="sm"
              color={stepConfigs[currentStep]?.step >= 2 ? "primary" : "light"}
              className="position-absolute top-0 start-33 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              2
            </Button>
            <Button
              size="sm"
              color={stepConfigs[currentStep]?.step >= 3 ? "primary" : "light"}
              className="position-absolute top-0 start-66 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              3
            </Button>
            <Button
              size="sm"
              color={stepConfigs[currentStep]?.step >= 4 ? "primary" : "light"}
              className="position-absolute top-0 start-100 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              4
            </Button>
          </div>

          <div className="step-buttons__mobile-status">
            <div>
              <p>Classificar</p>
            </div>
            <div>
              <p>Notificar</p>
            </div>
            <div>
              <p>Evidenciar</p>
            </div>
            <div>
              <p>Confirmar</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Categorize = () => {
    return (
      <div className="step-content">
        <h5>Classificar agressor</h5>
        <span className="text-muted">
          Como você gostaria de classificar este agressor?
        </span>

        <div className="mt-3 d-flex flex-column gap-2">
          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="attacker"
              id="competitor"
              value="competitor"
              checked={categorizeItem === "competitor"}
              onChange={() => setCategorizeItem("competitor")}
            />
            <Label className="form-check-label text-dark" for="competitor">
              Concorrente
            </Label>
          </div>

          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="attacker"
              id="partner"
              value="partner"
              checked={categorizeItem === "partner"}
              onChange={() => setCategorizeItem("partner")}
            />
            <Label className="form-check-label text-dark" for="partner">
              Parceiro
            </Label>
          </div>

          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="attacker"
              id="site"
              value="owned"
              checked={categorizeItem === "owned"}
              onChange={() => setCategorizeItem("owned")}
            />
            <Label className="form-check-label text-dark" for="site">
              Site próprio (Não é um agressor)
            </Label>
          </div>

          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="attacker"
              id="suspectedFraud"
              value="fraud"
              checked={categorizeItem === "fraud"}
              onChange={() => setCategorizeItem("fraud")}
            />
            <Label className="form-check-label text-dark" for="suspectedFraud">
              Suspeita de fraude
            </Label>
          </div>
        </div>
      </div>
    );
  };

  const Notify = () => {
    return (
      <div className="step-content">
        <h5>Deseja iniciar notificações?</h5>
        <span className="text-muted">
          Como você gostaria que fosse feita nossa atuação?
        </span>

        <div className="mt-3 d-flex flex-column gap-2">
          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="sendNotifys"
              id="sendNotifys"
              value="notify"
              checked={actingItem === "notify"}
              onChange={() => setActingItem("notify")}
            />
            <Label className="form-check-label text-dark" for="sendNotifys">
              Enviar Notificações
            </Label>
          </div>

          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="sendnotifys"
              id="allowServing"
              value="whitelist"
              checked={actingItem === "whitelist"}
              onChange={() => setActingItem("whitelist")}
            />
            <Label className="form-check-label text-dark" for="allowServing">
              Permitir veiculação do anúncio
              <span className="text-muted fs-10">(Whitelist)</span>
            </Label>
          </div>

          <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="sendnotifys"
              id="standBy"
              value="standby"
              checked={actingItem === "standby"}
              onChange={() => setActingItem("standby")}
            />
            <Label className="form-check-label text-dark" for="standBy">
              Deixar em stand-by
            </Label>
          </div>
        </div>
      </div>
    );
  };

  const Evidence = () => {
    const onDrop = useCallback((acceptedFiles) => {
      handleAcceptedFiles(acceptedFiles);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleAcceptedFiles = (files) => {
      const imageFiles = files
        .filter(
          (file) => file.type.startsWith("image/") && file.size <= 500 * 1024
        ) // 500 KB
        .map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );

      if (files.some((file) => !file.type.startsWith("image/"))) {
        setErrorMessage(
          "São aceitos apenas arquivos de imagem (png, jpg ou jpeg)."
        );
      } else if (
        files.some(
          (file) => file.type.startsWith("image/") && file.size > 500 * 1024
        )
      ) {
        setErrorMessage(
          "Por favor, faça upload de imagens com tamanho até 500KB."
        );
      } else {
        setErrorMessage(null);
      }

      setselectedFile(imageFiles);
    };

    const handleRejectedFiles = (files) => {
      setErrorMessage("Por favor, faça upload apenas de imagens.");
    };

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    return (
      <div className="step-content">
        <h5>Registrar prova de boa-fé</h5>
        <span className="text-muted">
          Fazer o upload da screenshot demonstrando que você já negativou os
          termos do agressor na sua conta.{" "}
        </span>

        <div className="mt-3 d-flex flex-column gap-2">
          {actingItem === "notify" && selectedFile.length === 0 && (
            <div
              class="alert alert-warning d-flex align-items-center p-0 m-0"
              role="alert"
            >
              <div className="p-3">
                <AlertTriangle size={18} />
              </div>
              <div>
                A taxa de sucesso é <strong>58,2% maior</strong> nas tratativas
                com prova de boa fé.
              </div>
            </div>
          )}

          {errorMessage && (
            <div
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              {errorMessage}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setErrorMessage(null)}
              />
            </div>
          )}

          {selectedFile.length === 0 && (
            <Dropzone
              onDrop={handleAcceptedFiles}
              onDropRejected={handleRejectedFiles}
              accept="image/*"
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone dz-clickable dropzone-ticket">
                  <div
                    className="dz-message needsclick my-3"
                    {...getRootProps()}
                  >
                    <i className="display-6 text-muted ri-upload-cloud-2-fill" />
                    <div className="dropzone-ticket__description">
                      Arraste e solte o arquivo aqui <br /> ou clique para
                      selecionar
                    </div>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          )}

          <div className="list-unstyled mb-0" id="file-previews">
            {selectedFile.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div>
                    <Row className="align-items-center">
                      <Col className="col-auto pe-0">
                        <div
                          className="avatar-xl avatar-custom rounded img-thumbnail"
                          style={{ backgroundImage: `url( ${f.preview})` }}
                        ></div>
                      </Col>

                      <Col>
                        <div>
                          <span className="text-muted font-weight-bold">
                            {f.name}
                          </span>
                          <br />
                          <strong>{f.formattedSize}</strong>
                        </div>
                      </Col>
                    </Row>
                    <div
                      {...getRootProps()}
                      className="mt-3 cursor-pointer text-primary"
                    >
                      <input {...getInputProps()} />
                      <button type="button" class="btn btn-secondary btn-sm">
                        Editar imagem
                      </button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>

        <Modal
          isOpen={evidenceAlert}
          toggle={() => {
            openEvidenceAlert();
          }}
          centered
        >
          <ModalHeader className="d-flex justify-content-end ">
            <Button
              type="button"
              className="btn-close"
              onClick={() => {
                setEvidenceAlert(false);
              }}
              aria-label="Close"
            ></Button>
          </ModalHeader>
          <ModalBody className="text-center">
            <AlertCircle color="#F7B84B" size="90" />

            <h4 className="mt-4 mb-3">Tem certeza que deseja prosseguir?</h4>
            <p className="mb-15">
              Anexar provas de boa fé{" "}
              <strong>
                aumentam em 58,2% a <br /> taxa de sucesso
              </strong>{" "}
              das tratativas.
            </p>

            <div className="mx-auto mt-5 d-flex justify-content-around">
              <Button
                color="success"
                className="btn-label"
                onClick={() => {
                  setEvidenceAlert(false);
                }}
              >
                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                Voltar e anexar
              </Button>

              <Button
                color="warning"
                className="btn-label right"
                onClick={() => handleStepsButtons()}
              >
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Seguir mesmo assim
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  const Confirm = () => {
    return (
      <div className="step-content">
        <h5>Confirmação</h5>
        <span className="text-muted">
          Confirme as informações abaixo antes de prosseguir
        </span>

        <div className="mt-3 d-flex flex-column gap-3 text-muted">
          <span>
            Classificação do agressor <br />{" "}
            <strong>{mapCategory[categorizeItem]}</strong>
          </span>
          {actingItem && (
            <span>
              Tipo de notificação
              <br /> <strong>{mapActing[actingItem]}</strong>
            </span>
          )}
        </div>
        {props.errorUpload && (
          <div
            class="alert alert-danger d-flex align-items-center p-0 mt-4"
            role="alert"
          >
            <div className="p-3">
              <AlertOctagon size={18} />
            </div>
            <div>
              Houve um erro no upload da prova de boa fé. Por favor contate-nos.
            </div>
          </div>
        )}
        {props.errorUpdate && (
          <div
            class="alert alert-danger d-flex align-items-center p-0 mt-4"
            role="alert"
          >
            <div className="p-3">
              <AlertOctagon size={18} />
            </div>
            {props.errorUpdate === "Forbidden" ? (
              <div>
                Você não tem permissão para triar este ticket. Por favor
                contate-nos.
              </div>
            ) : (
              <div>
                Houve um erro no ao triar este ticket. Por favor contate-nos.
              </div>
            )}
          </div>
        )}

        <Modal
          isOpen={ownedConfirmAlert}
          toggle={() => {
            handleConfirmModal();
          }}
          centered
        >
          <ModalHeader className="d-flex justify-content-end ">
            <Button
              type="button"
              className="btn-close"
              onClick={() => {
                setOwnedConfirmAlert(false);
              }}
              aria-label="Close"
            ></Button>
          </ModalHeader>
          <ModalBody className="text-center">
            <AlertCircle color="#F7B84B" size="90" />

            <h4 className="mt-4 mb-3">Atenção!</h4>
            <p className="mb-15" style={{ fontSize: "13px" }}>
              Ao categorizar este agressor como <strong>Domínio Próprio</strong>
              , ele deixará de ser exibido na tela{" "}
              <strong>Gerenciar Agressores</strong>. Caso deseje reverter essa
              ação no futuro, entre em contato com a nossa equipe.
            </p>

            <div className="mx-auto mt-5 d-flex justify-content-around">
              <Button
                color="success"
                className="btn-label"
                onClick={() => {
                  setOwnedConfirmAlert(false);
                }}
              >
                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                Voltar e corrigir
              </Button>

              <Button
                color="warning"
                className="btn-label right"
                onClick={() => handleConfirmApproval()}
              >
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Entendi, confirmar
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  const isButtonDisabled =
    (currentStep === "categorize" && categorizeItem === null) ||
    (currentStep === "notify" && actingItem === null) ||
    (currentStep === "confirm" && props.evidenceUploadLoading) ||
    (currentStep === "confirm" && props.errorUpload);

  props.evidenceUploadLoading
    ? (stepConfigs.confirm.btnNext = "Aguarde...")
    : (stepConfigs.confirm.btnNext = "Confirmar");
  return (
    <React.Fragment>
      <Col xxl={8}>
        {phaseStep && !approved ? (
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0">
                Siga os passos para iniciar as tratativas deste agressor
              </h5>
            </CardHeader>

            <CardBody>
              <Row>
                <Tabs />

                <Col md={8}>
                  {(() => {
                    switch (currentStep) {
                      case "categorize":
                        return <Categorize />;
                      case "notify":
                        return <Notify />;
                      case "evidence":
                        return <Evidence />;
                      case "confirm":
                        return <Confirm />;
                      default:
                        <Categorize />;
                    }
                  })()}

                  <div className="d-flex justify-content-between mt-5">
                    <div>
                      {currentStep !== "categorize" && (
                        <Button
                          color="light"
                          className="btn-label"
                          onClick={() => handleBackClick()}
                        >
                          <i className="bx bx-left-arrow-alt label-icon align-middle fs-16 me-2"></i>
                          Voltar
                        </Button>
                      )}
                    </div>

                    <Button
                      color="success"
                      className="btn-label right"
                      onClick={() => handleStepsButtons()}
                      disabled={isButtonDisabled}
                    >
                      <i className="bx bx-right-arrow-alt label-icon align-middle fs-16 ms-2"></i>
                      {stepConfigs[currentStep]?.btnNext}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : (
          <ProgressSteps
            phase={phase}
            setPhase={setPhase}
            updateLoading={props.updateLoading}
            setApproved={setApproved}
          />
        )}
      </Col>
    </React.Fragment>
  );
};

export default RegisterAggressor;
