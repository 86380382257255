import EmptyStateThreatSvg from "../../../../DesignSystem/assets/empty-state-threat.svg"

interface IProps {
    text: string
}

export default function EmptyState({ text }: IProps) {
    return (
        <div className="d-flex gap-3 flex-column justify-content-center align-items-center w-100 my-5 py-5">
            <img width={186} src={EmptyStateThreatSvg} />
            <span>{text}</span>
        </div>
    )
}