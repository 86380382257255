import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const ProfileDropdown = () => {
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  const selectProfileState = (state) => state.Profile;

  const { user } = useSelector(selectProfileState);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <div
              className="rounded-circle header-profile-user bg-secondary d-flex justify-content-center align-items-center"
              alt="Header Avatar"
            >
              <span className="fs-3 text-center fw-medium">
                {user.firstName && user.firstName[0]}
              </span>
            </div>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user.firstName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {user.company}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <h6 className="dropdown-header">Welcome {userName}!</h6> */}
          {/* <DropdownItem href={process.env.PUBLIC_URL + "/profile"}><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                    <span className="align-middle">Profile</span></DropdownItem>
                <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}><i
                    className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                        className="align-middle">Messages</span></DropdownItem>
                <DropdownItem href="#"><i
                    className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                        className="align-middle">Taskboard</span></DropdownItem>
                <DropdownItem href={process.env.PUBLIC_URL + "/pages-faqs"}><i
                    className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                        className="align-middle">Help</span></DropdownItem>
                <div className="dropdown-divider"></div>
                <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile"}><i
                    className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                        className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem>
                <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile-settings"}><span
                    className="badge bg-success-subtle text-success mt-1 float-end">New</span><i
                        className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Settings</span></DropdownItem>
                <DropdownItem href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}><i
                    className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></DropdownItem> */}
          <DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
