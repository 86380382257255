import React from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import { formatDateWithMoment } from "../../../../utils/dateFormat";
import { mapPhase } from "../../../../utils/mapsTranslate";
import { setStatusColor } from "../../../../utils/setColors";

const ReportTicketDetailsSession = (props) => {
  const eventData = props.eventData;

  const handleActionModal = () => {
    props.handleToggle();
    props.handleAds(eventData.campaignId, eventData.domain);
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="mx-n4 card-border-effect-none mb-n5 border-bottom-0 border-start-0 rounded-0 bg-warning-subtle">
            <div>
              <CardBody className="pb-4 pt-4 mb-5">
                <Row className="p-0 m-0">
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md ps-4">
                        <div className="d-flex align-items-center mb-2 domain-status-div">
                          <h4 
                          className="fw-normal m-0 domain-title"
                          >{eventData.domain} </h4>
                          {eventData.status ? (
                            <Badge
                              className="domain-badge"
                              color={setStatusColor(mapPhase[eventData.status])}
                            >
                              {mapPhase[eventData.status]}
                            </Badge>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="hstack gap-3 flex-wrap">
                          <div className="text-muted">
                            <i className="ri-building-line align-bottom me-1"></i>
                            <span id="ticket-client">
                              Campanha: {eventData.campaign?.name}
                            </span>
                          </div>

                          <div className="vr"></div>

                          <div className="text-muted">
                            Criação:
                            <span className="fw-medium">
                              {" "}
                              {formatDateWithMoment(eventData.createdAt)}
                            </span>
                          </div>

                          <div className="vr"></div>

                          <div className="text-muted">
                            Atualização:
                            <span className="fw-medium">
                              {" "}
                              {formatDateWithMoment(eventData.updatedAt)}
                            </span>
                          </div>

                          <div className="vr"></div>

                          <div className="text-muted">
                            <span
                              className="link-secondary text-decoration-underline cursor-pointer"
                              onClick={handleActionModal}
                            >
                              <i className="bx bx-file-find fs-18 align-bottom me-1"></i>
                              <span id="ticket-client">Ver anúncios</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ReportTicketDetailsSession;
