import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Fade,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";
import { setAggressorColorByScore } from "../../../utils/setColors";
import { useDispatch } from "react-redux";
import { setTab } from "../../../store/pages/reports/actions";
import moment from "moment-timezone";

const DAYS_14 = 14;
const startDate = moment().subtract(DAYS_14, "days").startOf("day").toDate();
const endDate = moment()
  .subtract(1, "days")
  .endOf("day")
  .tz("America/Sao_Paulo")
  .toDate();

export const TopReferrals = ({
  onSelectedPeriodChange,
  selectedPeriod,
  topAggressors,
  topAggressorsLoading,
  panelLoading,
  topAggressorsError,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams();
  searchParams.set('startDate', startDate);
  searchParams.set('endDate', endDate);
  searchParams.set('days', DAYS_14);
  searchParams.set('agressor', '');
  searchParams.set('channel', '');
  searchParams.set('actualPhase', '');
  searchParams.set('notify', '');
  searchParams.set('keyword', '');
  searchParams.set('page', '');
  searchParams.set('position', '');
  searchParams.set('paginationIndex', '0');
  searchParams.set('tab', 'identificados')

  const reportsLink = `/reports/?${searchParams.toString()}`;

  return (
    <React.Fragment>
      <Card className="w-100">
        <CardHeader className="card-header-multiple-actions">
          <h4 className="card-title">Principais Agressores</h4>

          <div className="card-header-actions">
            <div className="d-flex gap-1">
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(0)}
                className={
                  selectedPeriod === 0
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                Todos
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(334)}
                className={
                  selectedPeriod === 334
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                1A
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(152)}
                className={
                  selectedPeriod === 152
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                6M
              </button>
              <button
                type="button"
                onClick={() => onSelectedPeriodChange(14)}
                className={
                  selectedPeriod === 14
                    ? "btn btn-soft-primary btn-sm"
                    : "btn btn-soft-secondary btn-sm"
                }
              >
                14D
              </button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {topAggressorsLoading || panelLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <BouncingDotsLoader />
            </div>
          ) : (
            <>
              <Row className="align-items-center">
                <Col xs={2} md={1} lg={2}>
                  <div className="text-center">
                    <i className="bx bx-search-alt text-gray-500 fs-48"></i>
                  </div>
                </Col>
                <Col xs={10} md={11} lg={10}>
                  <span className="d-block text-muted text-uppercase text-truncate fs-6">
                    total de OCORRÊNCIAS
                  </span>
                  <span className="fs-3 mb-0">{topAggressors.total}</span>
                </Col>
              </Row>
              {(!topAggressorsLoading && topAggressors.total === 0) ||
                topAggressorsError ? (
                <div className="d-flex justify-content-center align-items-center pb-5 h-100">
                  <Fade in={!topAggressorsLoading}>
                    <h2>{t("page.topReferrals.no-data")}</h2>
                  </Fade>
                </div>
              ) : (
                <Fade in={!topAggressorsLoading}>
                  <div className="mt-1 pt-2">
                    <div className="progress progress-lg rounded-pill">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${topAggressors.progressBar?.danger}%`,
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{
                          width: `${topAggressors.progressBar?.warning}%`,
                        }}
                        aria-valuenow="18"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: `${topAggressors.progressBar?.info}%` }}
                        aria-valuenow="22"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar bg-gray-600"
                        role="progressbar"
                        style={{
                          width: `${topAggressors.progressBar?.normal}%`,
                        }}
                        aria-valuenow="16"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="top-referrals-list">
                    <div className="d-flex mb-2">
                      <div className="flex-grow-1">
                        <p className="mb-0 fw-semibold">
                          Índice de agressividade
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0 fw-semibold">Share</p>
                      </div>
                    </div>

                    {topAggressors.domains?.map((item, index) => {
                    if (!item.domain) return null; // Verificação de null para item.domain, tratamento paleativo
                    if (item.domain === "doubleclick.net") return null; // Verificação de doubleclick para item.domain, tratamento paliativo

                      return (
                        <div className="top-referrals-list__item" key={index}>
                          <div>
                            <span className="me-2 text-nowrap">
                              {item.aggressivenessIndex.toFixed(2)}
                            </span>
                            <i
                              className={classnames(
                                setAggressorColorByScore(
                                  item.aggressivenessIndex.toFixed(2)
                                ),
                                "mdi mdi-circle align-middle me-2"
                              )}
                            ></i>
                            <span className="text-truncate">{item.domain}</span>
                          </div>
                          <span>{item.percentual}%</span>
                        </div>
                      );
                    })}
                  </div>

                  <div className="text-center mt-4">
                    <Link
                      to={reportsLink}
                      onClick={() => dispatch(setTab("1"))}
                      className="link-primary text-decoration-underline"
                    >
                      Ver todos os agressores
                    </Link>
                  </div>
                </Fade>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
