import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  forgetPasswordLoading: false,
  forgetPasswordMessageSent: false,
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetPasswordLoading: true,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetPasswordLoading: false,
        forgetPasswordMessageSent: true,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = {
         ...state,
         forgetPasswordLoading: false,
         forgetPasswordMessageSent: true,
        };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
