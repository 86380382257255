import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { localStorageKeys } from "../config/localStorageKeys";
import {
  loginError,
  resetAccessForbidden,
  resetProfileFlag,
} from "../store/actions";
import { useTranslation } from "react-i18next";
import { Fade } from "reactstrap";
import ValidateTwoFactor from "../pages/Authentication/ValidateTwoFactor";

const AuthProtected = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectProfileState = (state) => state.Profile;
  const { user, userLoggedIn, accessForbidden } =
    useSelector(selectProfileState);

  const checkUserToken = useCallback(() => {
    const accessToken = localStorage.getItem(localStorageKeys.AUTH_USER);

    if (accessToken === null || accessToken === undefined) {
      return null;
    }

    if (!accessToken) {
      return false;
    }

    return true;
  }, []);

  const saveUrlPath=()=>{
    const path = window.location.pathname + window.location.search
    localStorage.setItem("redirectToSuccessLogin", path)
  }

  useEffect(() => {
    const isTokenValid = checkUserToken();
    if (!isTokenValid || !userLoggedIn) {
      (isTokenValid || userLoggedIn) &&
        dispatch(loginError(t("page.authentication.login.session-expired")));
      localStorage.removeItem(localStorageKeys.AUTH_USER);
      saveUrlPath()
      navigate("/login");
      dispatch(resetProfileFlag());
      return
    }

    const redirectToSuccessLogin = localStorage.getItem("redirectToSuccessLogin")
    if(redirectToSuccessLogin){
      navigate(redirectToSuccessLogin)
      localStorage.removeItem("redirectToSuccessLogin")
    }
  }, [checkUserToken, dispatch, navigate, userLoggedIn, t, accessForbidden]);

  return (
    <>
      {userLoggedIn && (
        <>
          {localStorage.getItem("token") && (
            <ValidateTwoFactor />
          )}
           <Fade> {props.children} </Fade>
        </>
      )}
    </>
  );
};

export { AuthProtected };
