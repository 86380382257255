import { ReactNode } from 'react';
import styled from 'styled-components';
import ArrowTop from './top-tooltip-arrow.svg';
import ArrowBottom from './bottom-tooltip-arrow.svg';
import ArrowLeft from './left-tooltip-arrow.svg';
import ArrowRight from './right-tooltip-arrow.svg';
import { dsVariables } from "../../variables";

export type TTooltipPosition =
    | 'top_center'
    | 'top_left'
    | 'top_right'
    | 'middle_left'
    | 'middle_right'
    | 'bottom_center'
    | 'bottom_left'
    | 'bottom_right';

interface IProps {
    children: ReactNode;
    text: string;
    tooltipPosition?: TTooltipPosition;
    visible?:boolean
}

export default function Tooltip({ children, text, tooltipPosition = 'bottom_right', visible = true }: IProps) {

    function getArrowSrc() {
        if (tooltipPosition.includes('top')) return ArrowBottom;
        if (tooltipPosition.includes('bottom')) return ArrowTop;
        if (tooltipPosition.includes('left')) return ArrowLeft;
        return ArrowRight;
    }

    if(!visible) return children

    return (
        <TooltipContainer>
            {children}
            <TooltipText
                className="position-absolute bg-black text-white text-center"
                tooltipPosition={tooltipPosition}
            >
                {text}
                <Arrow src={getArrowSrc()} tooltipPosition={tooltipPosition} />
            </TooltipText>
        </TooltipContainer>
    );
}

// Styled Components
const TooltipContainer = styled.div`
  position: relative;
`;

const TooltipText = styled.span<{ tooltipPosition: TTooltipPosition }>`
  visibility: hidden;
  border-radius: ${dsVariables.cornerRadius[4]};
  padding: ${dsVariables.spacing[8]};
  z-index: 1;
  white-space: nowrap;
  position: absolute;

  ${({ tooltipPosition }) => {
        switch (tooltipPosition) {
            case 'top_center':
                return `
          bottom: 110%;
          left: 50%;
          transform: translateX(-50%);
        `;
            case 'top_left':
                return `
          bottom: 110%;
          left: 0;
        `;
            case 'top_right':
                return `
          bottom: 110%;
          right: 0;
        `;
            case 'bottom_center':
                return `
          top: 110%;
          left: 50%;
          transform: translateX(-50%);
        `;
            case 'bottom_left':
                return `
          top: 110%;
          left: 0;
        `;
            case 'bottom_right':
                return `
          top: 110%;
          right: 0;
        `;
            case 'middle_left':
                return `
          top: 50%;
          right: 100%;
          transform: translateY(-50%);
        `;
            case 'middle_right':
                return `
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
        `;
            default:
                return '';
        }
    }}

  ${TooltipContainer}:hover & {
    visibility: visible;
  }
`;

const Arrow = styled.img<{ tooltipPosition: TTooltipPosition }>`
  position: absolute;
  width: 12px;
  height: 12px;

  ${({ tooltipPosition }) => {
        switch (tooltipPosition) {
            case 'top_center':
                return `
                    bottom: -8px;
                    left: 50%;
                    transform: translateX(-50%);
                ` 
            case 'top_left':
                return `
                    bottom: -8px;
                    left: 8px;
                `
            case 'top_right':
                return `
                    bottom: -8px;
                    right: 8px;
                `
            case 'bottom_center':
                return `
                    top: -8px;
                    left: 50%;
                    transform: translateX(-50%);
                `       
            case 'bottom_left':
                return `
                    top: -8px;
                    left: 8px;
                `
            case 'bottom_right':
                return `
                    top: -8px;
                    right: 8px;
                `
            case 'middle_left':
                return `
                    top: 50%;
                    right: -8px;
                    transform: translateY(-50%);
                `
            case 'middle_right':
                return `
                    top: 50%;
                    left: -8px;
                    transform: translateY(-50%);
                `
        }
    }}
`;
