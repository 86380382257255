import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import { useTranslation } from "react-i18next";

const ModalLink = ({ buttonLabel, modalTitle, contentType }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const getContent = () => {
    switch (contentType) {
      case "terms":
        return <TermsOfUse />;
      case "privacy":
        return <PrivacyPolicy />;
      default:
        return <span>Conteúdo não disponível</span>;
    }
  };

  return (
    <>
      <Button
        color="link"
        onClick={toggle}
        style={{
          padding: 0,
          border: "none",
          background: "none",
          textDecoration: "underline",
        }}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{getContent()}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export const useTermsAcceptance = () => {
  const [agree, setAgree] = useState(false);
  return { agree, setAgree };
};

const TermsAndPrivacy = ({ agree, setAgree }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Input
        className="form-check-input"
        type="checkbox"
        id="terms-checkbox"
        checked={agree}
        onChange={() => setAgree(!agree)}
      />
      <Label className="form-check-label" htmlFor="terms-checkbox">
        Eu li e aceito os{" "}
        <ModalLink
          buttonLabel="Termos de Uso"
          modalTitle="Termos de Uso da Plataforma"
          contentType="terms"
        />{" "}
        e a{" "}
        <ModalLink
          buttonLabel="Política de Privacidade"
          modalTitle="Política de Privacidade"
          contentType="privacy"
        />
        .
      </Label>
    </div>
  );
};

export default TermsAndPrivacy;
